import {
  MaximumFractionDigits,
  PrecisionFloat,
  getPrecisionFloatValue,
  getTargetAmount,
} from '@bts-web/utils-formatting';
import { GLOBAL_MAX_TRADE_AMOUNT, TradeControllerVariants } from '../../utils';

export const getMaximumTradeAmount = ({
  fiatBalance,
  assetBalance,
  type,
}: {
  fiatBalance: PrecisionFloat;
  assetBalance: PrecisionFloat;
  type: TradeControllerVariants;
}) => {
  if (type === 'sell') {
    return {
      ASSET: {
        value: getPrecisionFloatValue(assetBalance),
        precision: MaximumFractionDigits.ASSET,
      },
      FIAT: {
        value: getPrecisionFloatValue(fiatBalance),
        precision: MaximumFractionDigits.FIAT,
      },
    };
  }

  return {
    ASSET: {
      value: GLOBAL_MAX_TRADE_AMOUNT,
      precision: MaximumFractionDigits.ASSET,
    },
    FIAT: {
      value: GLOBAL_MAX_TRADE_AMOUNT,
      precision: MaximumFractionDigits.FIAT,
    },
  };
};

export const getMinimumTradeAmount = ({
  type,
  price,
  minimumBuyAmount,
  minimumSellAmount,
}: {
  type: TradeControllerVariants;
  price: PrecisionFloat;
  minimumBuyAmount: PrecisionFloat;
  minimumSellAmount: PrecisionFloat;
}) => {
  const minSellAmountAsset = getTargetAmount({
    sourceAmount: minimumSellAmount,
    targetType: 'ASSET',
    price,
  });

  if (type === 'buy') {
    return {
      ASSET: minimumBuyAmount,
      FIAT: minimumBuyAmount,
    };
  }

  return {
    ASSET: minSellAmountAsset,
    FIAT: minimumSellAmount,
  };
};
