/**
 * @generated SignedSource<<89ec95341a74deb084fb29bc87cdf832>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AppropriatenessQuestionsViewQuery$variables = {
  first: number;
};
export type AppropriatenessQuestionsViewQuery$data = {
  readonly appropriatenessQuestions: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly __typename: string;
        readonly answers: ReadonlyArray<{
          readonly answer: string;
          readonly id: string;
          readonly order: number;
        }>;
        readonly id: string;
        readonly label: string;
        readonly order: number;
        readonly question: string;
      } | null;
    } | null> | null;
  } | null;
};
export type AppropriatenessQuestionsViewQuery = {
  response: AppropriatenessQuestionsViewQuery$data;
  variables: AppropriatenessQuestionsViewQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "order",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "first",
            "variableName": "first"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "AppropriatenessQuestionConnection",
    "kind": "LinkedField",
    "name": "appropriatenessQuestions",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AppropriatenessQuestionEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "question",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "label",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AppropriatenessAnswer",
                "kind": "LinkedField",
                "name": "answers",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "answer",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AppropriatenessQuestionsViewQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AppropriatenessQuestionsViewQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "6d4eaa8ed54cf896a6d7560a86907ab4",
    "id": null,
    "metadata": {},
    "name": "AppropriatenessQuestionsViewQuery",
    "operationKind": "query",
    "text": "query AppropriatenessQuestionsViewQuery(\n  $first: Int!\n) {\n  appropriatenessQuestions(input: {first: $first}) {\n    edges {\n      node {\n        id\n        question\n        order\n        label\n        answers {\n          id\n          answer\n          order\n        }\n        __typename\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8c2e50ff988770ef99ce4eed9ce2cf82";

export default node;
