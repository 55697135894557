'use client';

import { ReactElement, cloneElement, FC } from 'react';
import { useComposableModal } from '@bts-web/utils-context';
import { useClientUserInfo } from '../../../common';
import { ButtonComponentProps } from '../../types';
import { UserAccessModal } from './UserAccessModal';

interface UserAccessWithRoleCheckProps {
  children: ReactElement<ButtonComponentProps>;
}

export const UserAccessWithRoleCheck: FC<UserAccessWithRoleCheckProps> = ({
  children,
}) => {
  const userData = useClientUserInfo();

  const {
    role,
    userType,
    onboarded,
    businessApproved,
    tradingActive,
    withdrawalsActive,
  } = userData;

  const { showModal } = useComposableModal();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event?.preventDefault();

    if (
      (onboarded && !businessApproved) ||
      (onboarded && !businessApproved && !tradingActive && withdrawalsActive)
    ) {
      showModal(UserAccessModal, {
        type: 'warningTrade',
      });

      return;
    }

    showModal(UserAccessModal, {
      type: 'readOnly',
    });
  };

  if (
    (!businessApproved && onboarded && userType === 'CorporateUser') ||
    (role === 'READ_ONLY' && userType === 'CorporateUser')
  ) {
    return cloneElement(children, {
      onClick: handleClick,
    });
  }

  return children;
};
