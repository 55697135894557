import { css } from '@bts-web/utils-style-engine';

export const dividerStyles = css({
  mx: 'medium',
  width: 'fit-content',
  borderBottomWidth: '1px',
  borderBottomColor: 'neutrals.divider',
});

export const transparentDividerStyles = css({
  mx: 'medium',
  width: 'auto',
  borderBottomWidth: '1px',
  borderBottomColor: 'transparent',
});
