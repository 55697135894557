import { Icon } from '@bts-web/design-system/component/icon';
import { TradeOptionButton } from './TradeOptionButton';
import { DrawerContent } from '../../../common/components/DrawerContent/DrawerContent';

interface TradeOptionsDrawerContentProps {
  labels: {
    title: string;
    buy: string;
    sell: string;
  };
  isSellButtonVisible: boolean;
  onClickBuyOption: () => void;
  onClickSellOption: () => void;
}

const TradeOptionsDrawerContent = ({
  labels,
  isSellButtonVisible,
  onClickBuyOption,
  onClickSellOption,
}: TradeOptionsDrawerContentProps) => {
  return (
    <DrawerContent
      title={labels.title}
      ariaLabel="trade options drawer content"
    >
      <TradeOptionButton
        title={labels.buy}
        icon={<Icon icon="buy" size="16" />}
        onClick={onClickBuyOption}
      />

      {isSellButtonVisible && (
        <TradeOptionButton
          title={labels.sell}
          icon={<Icon icon="sell" size="16" />}
          onClick={onClickSellOption}
        />
      )}
    </DrawerContent>
  );
};

export { TradeOptionsDrawerContent };
