'use client';

import { Sheet } from '@bts-web/design-system/component/sheet';
import { css } from '@bts-web/utils-style-engine';

type BottomPopupSheet = {
  taxTitle?: string;
  taxInfoText1?: string;
  taxInfoText2?: string;
  isOpened: boolean;
  onOpenChange: () => void;
};

const contentStyles = css({
  borderTopRadius: 16,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  bg: 'neutrals.card_fill_primary',
  px: 'medium',
  paddingBottom: 'medium',
  paddingTop: 'extra_large_2',
  gap: 'extra_small',
  position: 'fixed',
  animation: 'slideUpContent 150ms cubic-bezier(0.16, 1, 0.3, 1)',
  bottom: 0,
  left: 0,
  zIndex: '10',
  '& > [data-element="sheet-title"]': {
    color: 'neutrals.text_primary',
    fontSize: 'headline.medium_medium',
    fontWeight: 'headline.medium_medium',
    lineHeight: 'headline.medium_medium',
    letterSpacing: 'headline.medium_medium',
    textAlign: 'left',
  },

  '& > [data-element="sheet-description"]': {
    display: 'flex',
    flexDirection: 'column',
    gap: 'medium',
    '& > p': {
      color: 'neutrals.text_primary',
      fontSize: 'body.medium',
      fontWeight: 'body.medium',
      letterSpacing: 'body.medium',
      lineHeight: 'body.medium',
      textAlign: 'left',
    },
  },
});

const overlayStyles = css({
  position: 'fixed',
  zIndex: '10',
  display: 'flex',
  inset: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.25)',
  flex: 'auto',
  width: '100%',
});

const BottomPopupSheet = ({
  taxInfoText1,
  taxInfoText2,
  taxTitle,
  isOpened,
  onOpenChange,
}: BottomPopupSheet) => {
  return (
    <Sheet
      id={`tax-info`}
      isOpened={isOpened}
      title={taxTitle}
      onOpenChange={onOpenChange}
      textLine1={taxInfoText1}
      textLine2={taxInfoText2}
      contentClassName={contentStyles}
      overlayClassName={overlayStyles}
    />
  );
};

export { BottomPopupSheet };
