/* eslint-disable @nx/enforce-module-boundaries */
import { css } from '@bts-web/utils-style-engine';
import { appHeaderWrapperStyles } from './DesktopTopHeaderBar';

export const DesktopTopHeaderBarSkeleton = () => {
  return (
    <header
      className={css({
        ...appHeaderWrapperStyles,
        height: '72px',
        backgroundColor: 'neutrals.stroke_secondary',
      })}
    ></header>
  );
};
