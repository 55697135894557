import { css } from '@bts-web/utils-style-engine';
import {
  ListCellsSelectionItem as DesignListCellsSelectionItem,
  ListCellsSelectionItemProps,
} from '@bts-web/design-system/component/list-cells-selection';
import {
  ListCellsSelectionItemBadge,
  ListCellsSelectionIndicator,
  ListCellsSelectionItemContainer,
} from './index';
import { type ListCellsSelectionIndicatorStylesVariant } from './ListCellsSelectionIndicator';

type ThemedListCellsSelectionItemProps = ListCellsSelectionItemProps &
  ListCellsSelectionIndicatorStylesVariant & {
    activeItemId: string;
    badge?: string;
    label: string;
    subLabel?: string;
    separator?: boolean;
    isDisabled?: boolean;
  };

const displayFlexWithCenteredItems = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const ThemedListCellsSelectionItem = ({
  activeItemId,
  id,
  badge,
  label,
  subLabel,
  value,
  visual = 'checkmark',
  separator = false,
  isDisabled = false,
  ...rest
}: ThemedListCellsSelectionItemProps) => (
  <ListCellsSelectionItemContainer
    activeItemId={activeItemId}
    label={label}
    subLabel={subLabel}
    itemId={id}
    isDisabled={isDisabled}
    {...(badge && {
      icon: (
        <ListCellsSelectionItemBadge
          value={badge}
          visual={activeItemId === id ? 'active' : 'default'}
        />
      ),
    })}
  >
    <DesignListCellsSelectionItem
      {...rest}
      className={css({
        ...displayFlexWithCenteredItems,
        width: '24px',
        height: '24px',
      })}
      disabled={isDisabled}
      id={id}
      value={value}
    >
      <ListCellsSelectionIndicator visual={visual} />
    </DesignListCellsSelectionItem>
  </ListCellsSelectionItemContainer>
);

export { ThemedListCellsSelectionItem as ListCellsSelectionItem };
