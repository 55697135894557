import { Chart } from 'highcharts/highstock';

export type DataSeriesEntry = [number, number];

export const CHART_DEFAULT_DATASET: DataSeriesEntry[] = [
  [0, 0],
  [1, 0],
];

export enum TimeAmounts {
  OneDay = 'oneDay',
  SevenDays = 'sevenDays',
  OneMonth = 'oneMonth',
  SixMonths = 'sixMonths',
  OneYear = 'oneYear',
  MaxYears = 'maxYears',
}

export type RangeSelectorButtonsDomIdsValues =
  | 'chart-range-selector__oneDay'
  | 'chart-range-selector__sevenDays'
  | 'chart-range-selector__oneMonth'
  | 'chart-range-selector__sixMonths'
  | 'chart-range-selector__oneYear'
  | 'chart-range-selector__maxYears';

export const RangeSelectorButtonsDomIds: Record<
  TimeAmounts,
  RangeSelectorButtonsDomIdsValues
> = {
  oneDay: 'chart-range-selector__oneDay',
  sevenDays: 'chart-range-selector__sevenDays',
  oneMonth: 'chart-range-selector__oneMonth',
  sixMonths: 'chart-range-selector__sixMonths',
  oneYear: 'chart-range-selector__oneYear',
  maxYears: 'chart-range-selector__maxYears',
};

export type RangeSelectorConfigItem = {
  timeAmountId: TimeAmounts;
  text: string;
};

export function registerRangeSelectorButtonsEvents(
  chart: Chart,
  rangeSelectorsConfig: RangeSelectorConfigItem[],
  setDatasetMinMaxPoints: () => void,
) {
  const ONE_DAY = 86400000;

  const ONE_MONTH = 2629800000;

  const timeAmounts: Record<TimeAmounts, number> = {
    [TimeAmounts.OneDay]: ONE_DAY,
    [TimeAmounts.SevenDays]: 7 * ONE_DAY,
    [TimeAmounts.OneMonth]: ONE_MONTH,
    [TimeAmounts.SixMonths]: 6 * ONE_MONTH,
    [TimeAmounts.OneYear]: 12 * ONE_MONTH,
    [TimeAmounts.MaxYears]: 60 * ONE_MONTH,
  };

  rangeSelectorsConfig.forEach(({ timeAmountId }) => {
    const button = document.getElementById(
      RangeSelectorButtonsDomIds[timeAmountId],
    );

    button?.addEventListener('click', () => {
      const chartPoints = chart.series[0].points;

      const xAxis = chart.xAxis[0];

      xAxis.setExtremes(
        chartPoints[chartPoints.length - 1].x - timeAmounts[timeAmountId],
        chartPoints[chartPoints.length - 1].x,
      );

      // the chart animates after the extremes are changed
      // below code waits for the animation to finish and then displays the min max labels
      setTimeout(() => setDatasetMinMaxPoints(), 500);
    });
  });
}

export function getMedian(dataSeries: DataSeriesEntry[]): number {
  const valuesOnly = dataSeries.map((e) => e[1]);

  const midOfDataSeries = Math.floor(dataSeries.length / 2);

  const sortedDataSeriesValues = valuesOnly.sort((a, b) => a - b);

  if (dataSeries.length % 2 === 0) {
    return (
      (sortedDataSeriesValues[midOfDataSeries - 1] +
        sortedDataSeriesValues[midOfDataSeries]) /
      2
    );
  } else {
    return sortedDataSeriesValues[midOfDataSeries];
  }
}
