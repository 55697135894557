import Image from 'next/image';
import { css } from '@bts-web/utils-style-engine';
import { Icon } from '@bts-web/design-system/component/icon';
import { type TTransfersNextStepStateWithActions } from '../../../crypto-transfers/utils/useTransfersNextStepState.client';

const transactionItemsStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 'small',
});

const arrowStyles = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const arrowStart = css({
  width: 4,
  height: 4,
  borderRadius: 50,
  backgroundColor: 'neutrals.fill_tertiary',
});

const arrowLine = css({
  width: 56,
  height: 1,
  backgroundColor: 'neutrals.fill_tertiary',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
});

const arrowHeadStyles = css({
  position: 'absolute',
  right: -4,
  color: 'neutrals.fill_tertiary',
});

const networkImageStyles = css({
  backgroundColor: 'screen_background.primary',
});

const receiverItemStyle = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 48,
  height: 48,
  borderRadius: '32px',
  color: 'neutrals.text_secondary',
  backgroundColor: 'neutrals.fill_quaternary',
  fontSize: 'title.medium_medium',
  fontWeight: 'title.medium_medium',
  lineHeight: 'title.medium_medium',
  letterSpacing: 'title.medium_medium',
});

const NetworkTransfer = ({
  networkData,
  assetData,
}: {
  assetData: TTransfersNextStepStateWithActions['selectedAssetData'];
  networkData: TTransfersNextStepStateWithActions['selectedNetworkData'];
}): React.ReactElement => {
  return (
    <div data-testid="network-transfer" className={transactionItemsStyle}>
      <Image
        width={48}
        height={48}
        src={assetData?.logoUrl as string}
        alt={assetData?.name as string}
      />

      <div className={arrowStyles}>
        <div className={arrowStart} />

        <div className={arrowLine}>
          <Image
            className={networkImageStyles}
            width={32}
            height={32}
            src={networkData?.logoUrl as string}
            alt={networkData?.name as string}
          />

          <Icon
            className={arrowHeadStyles}
            icon="chevron-right"
            theme="regular"
            size="12"
            allowTransparency
          />
        </div>
      </div>

      {/* TODO show External Address abbreviation (EA) until wallet info is available */}
      <div className={receiverItemStyle}>EA</div>
    </div>
  );
};

export { NetworkTransfer };
