'use client';

import type { FC } from 'react';
import { NavigationBackButton } from '../../../../../navigation/index';
import { useRedirectBasedOnRefQueryParam } from '../../../../../asset-details/utils/useRedirectBasedOnRefQueryParam';

export const QueryParamBackButton: FC = () => {
  const { redirectBasedOnRefQueryParam } = useRedirectBasedOnRefQueryParam();

  return (
    <NavigationBackButton
      handleVirtualNavigation={redirectBasedOnRefQueryParam}
    />
  );
};
