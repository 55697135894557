import { APPLICATION_CONFIGS } from './APPLICATION_CONFIGS';
import { AppConfig } from './types';

export const getAppConfig = (): AppConfig => {
  const appIdNumber = Number(
    process.env.NEXT_PUBLIC_APP_ID ?? '999999999999999999',
  );

  const foundConfig = APPLICATION_CONFIGS.find(
    (item) => item.appId === appIdNumber,
  );

  if (!foundConfig) {
    throw new Error(`No config found for app !`);
  }

  return foundConfig;
};
