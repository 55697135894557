'use client';

import { useContext, createContext, useState, PropsWithChildren } from 'react';
import {
  AssetsListSortBy,
  AssetsViewQuery$data,
  AssetsViewQuery$variables,
} from '@bts-web/data-layer/server';
import { getAssetsViewWithParams } from '@bts-web/core-features/common';

interface AssetsPriceContextProps {
  assetsData: AssetsViewQuery$data | null;
  assetsQueryVariables: AssetsViewQuery$variables | null;
  setAssetsData: (assets: NonNullable<AssetsViewQuery$data>) => void;
  setAssetsQueryVariables: (
    assetsQueryVariables: NonNullable<AssetsViewQuery$variables>,
  ) => void;
  isAssetsListLoading: boolean;
  setIsAssetsListLoading: (isLoading: boolean) => void;
  priceChangeTimeRange: AssetsListSortBy;
  setPriceChangeTimeRange: (timeRange: AssetsListSortBy) => void;
}

const AssetsPriceContext = createContext<AssetsPriceContextProps | null>(null);

export function useAssetsPriceContext() {
  const contextValue = useContext(AssetsPriceContext);

  if (contextValue === null) {
    throw new Error(
      'useAssetsPriceContext must be used within a AssetsPriceContextProvider',
    );
  }

  return contextValue;
}

export const AssetsPriceContextProvider = ({
  children,
  initialData,
  initialQueryVariables,
}: PropsWithChildren<{
  initialData: Awaited<ReturnType<typeof getAssetsViewWithParams>>['data'];
  initialQueryVariables: AssetsViewQuery$variables;
}>) => {
  const [assetsData, setAssetsData] =
    useState<Awaited<ReturnType<typeof getAssetsViewWithParams>>['data']>(
      initialData,
    );

  const [assetsQueryVariables, setAssetsQueryVariables] =
    useState<AssetsViewQuery$variables>(initialQueryVariables);

  const [isAssetsListLoading, setIsAssetsListLoading] = useState(false);

  const [priceChangeTimeRange, setPriceChangeTimeRange] =
    useState<AssetsListSortBy>('PRICE_CHANGE_24HOURS');

  return (
    <AssetsPriceContext.Provider
      value={{
        assetsData,
        assetsQueryVariables,
        setAssetsData,
        setAssetsQueryVariables,
        isAssetsListLoading,
        setIsAssetsListLoading,
        priceChangeTimeRange,
        setPriceChangeTimeRange,
      }}
    >
      {children}
    </AssetsPriceContext.Provider>
  );
};
