'use client';

import { Item, RadioGroupItemProps } from '@radix-ui/react-radio-group';

export interface ListCellsSelectionItemProps extends RadioGroupItemProps {
  className?: string;
}

function ListCellsSelectionItem({
  className,
  id,
  value,
  children,
  ...rest
}: ListCellsSelectionItemProps) {
  return (
    <Item
      data-testid="list-cells-selection-item"
      {...{ className, id, value, ...rest }}
    >
      {children}
    </Item>
  );
}

export { ListCellsSelectionItem };
