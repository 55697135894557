'use client';

import { ReactElement } from 'react';
import * as ToggleGroup from '@radix-ui/react-toggle-group';
import * as Toggle from '@radix-ui/react-toggle';

export type BarItemProps = {
  internal: {
    className: string;
  };
  external: {
    icon?: ReactElement | null;
    isGrouped?: boolean;
    value?: string;
    children?: React.ReactNode;
    disabled?: boolean;
    onPressedChange?: (isOn: boolean) => void;
  };
};

export const BarItem = ({
  children,
  icon = null,
  isGrouped,
  value,
  ...toggleGroupAndItemProps
}: BarItemProps['internal'] & BarItemProps['external']) => {
  const ToggleChildren = (
    <>
      {icon}

      {isGrouped && children}

      {!isGrouped && value}
    </>
  );

  if (isGrouped && value) {
    return (
      <ToggleGroup.Item value={value as string} {...toggleGroupAndItemProps}>
        {ToggleChildren}
      </ToggleGroup.Item>
    );
  }

  return (
    <Toggle.Root {...toggleGroupAndItemProps}>{ToggleChildren}</Toggle.Root>
  );
};
