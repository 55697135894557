import { css } from '@bts-web/utils-style-engine';
import { Icon } from '@bts-web/design-system/component/icon';

const errorStyles = {
  display: 'flex',
  alignItems: 'center',
  gap: 'extra_small_3',
  color: 'negative.text_primary',
  fontSize: 'caption.small_light',
  fontWeight: 'caption.small_light',
  letterSpacing: 'caption.small_light',
  lineHeight: 'caption.small_light',
};

export const InputError = ({ message }: { message: string }) => {
  return (
    <p className={css(errorStyles)} aria-label="input error">
      <Icon icon="dismiss-circle" theme="regular" size="12" />

      {message}
    </p>
  );
};
