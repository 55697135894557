'use client';

import { FC } from 'react';
import { css } from '@bts-web/utils-style-engine';
import { QRCode as QRCodeLogo } from 'react-qrcode-logo';
import { QR_CODE_SIZE } from '../../utils/constants';

interface QRCodeProps {
  value: string;
  logoImageUrl?: string;
}

const qrCodeContainerStyle = css({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  py: 'large',
});

export const QRCode: FC<QRCodeProps> = ({ value, logoImageUrl }) => {
  let logoImageProps = {};

  if (logoImageUrl) {
    logoImageProps = {
      logoImage: logoImageUrl,
      logoWidth: 38,
      logoHeight: 38,
      removeQrCodeBehindLogo: true,
    };
  }

  return (
    <div className={qrCodeContainerStyle}>
      <QRCodeLogo size={QR_CODE_SIZE} value={value} {...logoImageProps} />
    </div>
  );
};
