import { FC } from 'react';
import { TabBarItem, TabBarItemProps } from './TabBarItem/TabBarItem';
import { css } from '@bts-web/utils-style-engine';

export interface TabBarProps {
  tabItems: Pick<TabBarItemProps, 'label' | 'value' | 'disabled'>[];
  value: string;
  onValueChange: (value: string) => void;
}

export const TabBar: FC<TabBarProps> = ({ value, tabItems, onValueChange }) => {
  return (
    <div
      role="navigation"
      aria-label={`tab bar`}
      className={css({
        display: 'flex',
      })}
    >
      {tabItems.map((item) => {
        return (
          <TabBarItem
            key={item.value}
            label={item.label}
            value={item.value}
            onClick={onValueChange}
            parentValue={value}
            disabled={item.disabled}
          />
        );
      })}
    </div>
  );
};
