'use client';

import {
  getAMLQuestions,
  getAppropriatenessQuestions,
} from '@bts-web/core-features/common';
import {
  AmlQuestionsViewQuery,
  AppropriatenessQuestionsViewQuery,
} from '@bts-web/data-layer/server';
import React, { useState, Context, PropsWithChildren } from 'react';

// Converts read-only keys to normal keys
type Mutable<T> = {
  -readonly [P in keyof T]: T[P] extends ReadonlyArray<infer U>
    ? Array<Mutable<U>>
    : T[P] extends object
      ? Mutable<T[P]>
      : T[P];
};

type NonNullableAppropriatenessQuestion = Mutable<
  NonNullable<
    NonNullable<
      NonNullable<
        AppropriatenessQuestionsViewQuery['response']['appropriatenessQuestions']
      >['edges']
    >[number]
  >['node']
>;

// **********************************
// ***************** appropriateness questions and answers
// **********************************

export type Question = Pick<
  Mutable<NonNullable<NonNullableAppropriatenessQuestion>>,
  'id' | 'label' | 'order' | 'question' | '__typename' | 'answers'
>;

export type Answer = Pick<
  Mutable<NonNullable<NonNullableAppropriatenessQuestion>['answers'][number]>,
  'answer' | 'id' | 'order'
>;

// **********************************
// ***************** onboarding question and answer
// **********************************

type NonNullableOnboardingQuestion = NonNullable<
  Mutable<
    Mutable<
      NonNullable<
        NonNullable<
          NonNullable<
            AmlQuestionsViewQuery['response']['amlQuestions']
          >['edges']
        >[number]
      >['node']
    >
  >
>;

export type OnboardingAnswer = NonNullable<
  Mutable<
    NonNullable<
      Mutable<NonNullable<NonNullableOnboardingQuestion['answers']>>
    >[number]
  >
>;

export type OnboardingQuestion = Pick<
  NonNullable<Mutable<NonNullableOnboardingQuestion>>,
  'id' | 'description' | 'question'
> & { answers: OnboardingAnswer[] };

// **********************************
// ***************** used for both onboarding questions and appropriateness questions
// **********************************

export interface QuestionAnswer {
  questionId: string;
  answerId: string;
  value?: string | null;
}

export interface QuestionContextProviderProps {
  initialQuestions?: Question | OnboardingQuestion | undefined;
  children: React.ReactNode;
  Context: Context<QuestionContextProps>;
  id: string;
}

export type QuestionContextProps = {
  setAnswerForQuestion: (
    questionId: string,
    answerId: string,
    value?: string,
  ) => void;
  getAnswerForQuestion: (questionId: string) => QuestionAnswer | null;
  clearQuestionAnswers: () => void;
  setQuestions: (questions: Question[] | OnboardingQuestion[]) => void;
  getNextQuestion: (questionId: string) => Question | OnboardingQuestion | null;
  getQuestion: (questionId: string) => Question | OnboardingQuestion | null;
  id: string;
  questions?: Question[] | OnboardingQuestion[];
} | null;

export type CreateQuestionContextProviderProps = Omit<
  QuestionContextProviderProps,
  'children'
> & {
  initialQuestionAnswers?: QuestionAnswer[];
  saveQuestionAnswers?: (answers: QuestionAnswer[]) => void;
  removeQuestionAnswers?: () => void;
};

export type QuestionsSources =
  | typeof getAppropriatenessQuestions
  | typeof getAMLQuestions;

function createQuestionContextProvider({
  Context,
  id,
  initialQuestionAnswers,
  saveQuestionAnswers,
  removeQuestionAnswers,
}: CreateQuestionContextProviderProps) {
  const QuestionContextProvider = ({
    children,
    initialQuestions,
  }: PropsWithChildren<{
    initialQuestions: Awaited<ReturnType<QuestionsSources>>;
  }>) => {
    const [questions, setQuestions] =
      useState<Question[] | OnboardingQuestion[]>(
        initialQuestions as Question[],
      ) || [];

    const [answers, setAnswers] = useState<QuestionAnswer[]>(
      initialQuestionAnswers || [],
    );

    const setAnswerForQuestion = (
      questionId: string,
      answerId: string,
      value?: string,
    ) => {
      const answer: QuestionAnswer = { questionId, answerId };

      if (value) {
        answer.value = value;
      }

      const answerIndex = answers.findIndex(
        (item) => item.questionId === questionId,
      );

      let newAnswers;

      if (answerIndex >= 0) {
        newAnswers = [
          ...answers.slice(0, answerIndex),
          answer,
          ...answers.slice(answerIndex + 1),
        ];
      } else {
        newAnswers = [...answers, answer];
      }

      setAnswers(newAnswers);

      if (saveQuestionAnswers) {
        saveQuestionAnswers(newAnswers);
      }
    };

    const getAnswerForQuestion = (
      questionId: string,
    ): QuestionAnswer | null => {
      const findAnswer = answers.find((item) => item.questionId === questionId);

      return findAnswer || null;
    };

    const clearQuestionAnswers = (): void => {
      setAnswers([]);

      if (removeQuestionAnswers) {
        removeQuestionAnswers();
      }
    };

    const getNextQuestion = (
      questionId: string,
    ): Question | OnboardingQuestion | null => {
      const currentQuestionIndex = questions.findIndex(
        (question: Question | OnboardingQuestion) => question.id === questionId,
      );

      return questions[currentQuestionIndex + 1] || null;
    };

    const getQuestion = (
      questionId: string,
    ): Question | OnboardingQuestion | null => {
      return (
        questions.find(
          (question: Question | OnboardingQuestion) =>
            question.id === questionId,
        ) ?? null
      );
    };

    const value = {
      id,
      setAnswerForQuestion,
      getAnswerForQuestion,
      clearQuestionAnswers,
      setQuestions,
      getQuestion,
      getNextQuestion,
      questions,
    };

    return <Context.Provider value={value}>{children}</Context.Provider>;
  };

  return QuestionContextProvider;
}

export function createQuestionContextProviderFactory(
  config: CreateQuestionContextProviderProps,
) {
  const QuestionContextProvider = createQuestionContextProvider(config);

  return QuestionContextProvider;
}
