import { css } from '@bts-web/utils-style-engine';
import { FC } from 'react';
import { PoweredByTextType } from '../../utils';

export const PoweredByText: FC<PoweredByTextType> = ({
  fontSize,
  value,
  centered,
}) => {
  return (
    <div
      data-testid="powered-by-copy"
      aria-label="powered by text"
      className={css({
        width: '100%',
        textAlign: !centered ? 'left' : 'center',
        color: 'neutrals.text_secondary',
        fontWeight: 'body.medium',
        letterSpacing: 'body.medium',
        lineHeight: 'body.medium',
        fontSize: fontSize,
        whiteSpace: 'wrap',
        mt: 'small',
        mb: 'medium',
      })}
    >
      {value}
    </div>
  );
};
