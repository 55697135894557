'use client';

import { FC, ReactNode, useState } from 'react';
import { AppUserFullInfo } from '../types';
import { UserInfoContext } from './UserInfoContext';

export const UserInfoProvider: FC<{
  data: AppUserFullInfo;
  children: ReactNode;
}> = ({ children, data }) => {
  const [userData, setUserData] = useState<AppUserFullInfo>(data);

  return (
    <UserInfoContext.Provider
      value={{
        data: userData,
        getUserInfo: () => {
          return userData;
        },
        updateUserInfo: setUserData,
      }}
    >
      {children}
    </UserInfoContext.Provider>
  );
};
