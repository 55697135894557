'use client';

import {
  ReactNode,
  cloneElement,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import { css, Z_INDEX_LIBRARY } from '@bts-web/utils-style-engine';
import { ButtonComponentProps } from '../../common/types';
import { Root, Portal, Overlay, Content } from '@radix-ui/react-dialog';
import { useDrawer } from './DrawerContextProvider';

interface DrawerProps {
  id: string;
  buttonTriggerNode?: ReactElement<ButtonComponentProps>;
  contentNode: ReactNode;
  disableOverlayClose?: boolean;
  position?: 'right' | 'bottom';
  onClose?: () => void;
}

/**
 * Drawer is a functional wrapper component used for displaying custom content
 *  in a right-side modal together with a background overlay on top of the main UI
 *
 * @component
 * @param {object} props - The properties that define the component's behavior and display.
 * @param {ReactNode} props.buttonTriggerNode -  The buttonTriggerNode will triger the show/hide action of the drawer
 * @param {ReactNode} props.contentNode - The contentNode the drawer content
 * @param {boolean | undefined} props.disableOverlayClose - Blocks the closing when clicking just on the overlay, for usage with drawers which internally have their own close button
 *
 */
export const Drawer = ({
  id,
  buttonTriggerNode,
  contentNode,
  disableOverlayClose = false,
  position = 'right',
  onClose,
}: DrawerProps) => {
  const { openDrawer, closeDrawer, drawerState } = useDrawer();

  const [drawerStatus, setDrawerStatus] = useState<'open' | 'close' | ''>('');

  useEffect(() => {
    setDrawerStatus(drawerState.open ? 'open' : 'close');

    setTimeout(() => {
      if (!drawerState.open && drawerStatus === 'close') {
        setDrawerStatus('');
      }
    }, 600);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerState.open]);

  const handleCloseDrawer = () => {
    if (onClose) {
      onClose();
    }

    closeDrawer(id);
  };

  const button = buttonTriggerNode
    ? cloneElement(buttonTriggerNode, {
        onClick: () => openDrawer(id),
      })
    : null;

  const handleOverlayClick = () => {
    if (disableOverlayClose) {
      return;
    }

    handleCloseDrawer();
  };

  return (
    <>
      {button}
      <Root
        open={drawerStatus === 'open' && id === drawerState.id}
        onOpenChange={disableOverlayClose ? undefined : handleCloseDrawer}
      >
        <Portal>
          <Overlay
            data-testid={`${position}-drawer-overlay`}
            style={{ zIndex: Z_INDEX_LIBRARY.DRAWER }}
            className={css({
              position: 'fixed',
              inset: '0',
              animation: 'overlayShow 400ms cubic-bezier(0.16, 1, 0.3, 1)',
              backgroundColor: 'rgba(166, 166, 166, 0.50)',
              backdropFilter: 'blur(6px)',
            })}
            data-element={`${position}-drawer-overlay`}
            onClick={handleOverlayClick}
          />

          <Content
            data-testid={`${position}-drawer-content`}
            data-element={`${position}-drawer-content`}
            data-state={drawerStatus}
            style={{ zIndex: Z_INDEX_LIBRARY.DRAWER }}
            className={css({
              position: 'fixed',
              bg: 'neutrals.card_fill_primary',
              ...(position === 'right'
                ? {
                    top: 0,
                    right: 0,
                    width: '420px',
                    height: '100vh',
                    '&[data-state="open"]': {
                      animation:
                        'slideLeftAndFade 300ms cubic-bezier(0.16, 1, 0.3, 1)',
                    },
                    '&[data-state="close"]': {
                      animation:
                        'slideRightAndFade 500ms cubic-bezier(0.16, 1, 0.3, 1)',
                    },
                  }
                : {}),
              ...(position === 'bottom'
                ? {
                    borderTopRightRadius: '8px',
                    borderTopLeftRadius: '8px',
                    top: 'auto',
                    left: 0,
                    bottom: 0,
                    right: 0,
                    height: 'auto',
                    maxHeight: '100%',
                    width: '100%',
                    '&[data-state="open"]': {
                      animation:
                        'slideUpAndFade 300ms cubic-bezier(0.16, 1, 0.3, 1)',
                    },
                    '&[data-state="close"]': {
                      animation:
                        'slideDownAndFade 500ms cubic-bezier(0.16, 1, 0.3, 1)',
                    },
                  }
                : {}),
            })}
          >
            {contentNode}
          </Content>
        </Portal>
      </Root>
    </>
  );
};
