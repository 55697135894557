export type TradeScreenTranslations = {
  eur: string;
  subTotal: string;
  total: string;
  fees: string;
  grams: string;
  inclSpread: string;
  actionButtonText: string;
  actionButtonLoadingText: string;
  poweredByBpfs: string;
  poweredByBitpanda: string;
  investingRiskToggle: string;
  stocksToggleNotAppropriate: string;
  errorTooHigh: string;
  errorTooLow: string;
  errorTradingNotPossibleTitle: string;
  errorAssetNotAvailableTitle: string;
  errorAssetNotAvailableSubtitle: string;
  errorTradingNotPossibleSubtitle: string;
  errorTradingNotPossibleLink: string;
  offerExpiredTitle: string;
  offerExpiredSubtitle: string;
  max: string;
  buy: string;
  buyNow: string;
  transactionFailedNotificationTitle: string;
  transactionFailedNotificationDescription: string;
  transactionDeclinedNotificationTitle: string;
  transactionDeclinedNotificationDescription: string;
  actionButtonVerifyText?: string;
  verifyTradeInfo?: string;
  acceptMetalTerms: string;
  derivativeContract: string;
  prospectus: string;
  andConfirmThatIhaveReadAndUnderstoodThe: string;
  and: string;
  costTransparencyDocuments: string;
  keyInformationDocument: string;
  investorInformationDocument: string;
  legalToggleLastPart: string;
  legalTogglePartOne: string;
  sell: string;
  sellNow: string;
  units: string;
  successBuySubtitleFirstLine: string;
  successBuySubtitleSecondLine: string;
  successScreenSubtitleFirstLine: string;
  successScreenSubtitleSecondLine: string;
  successSellSubtitleFirstLine: string;
  failureTransactionTitle: string;
  failureTransactionInline: string;
  backToPortfolio: string;
  taxWithHeld?: string;
  youRecieve?: string;
  aboutTaxTextLink?: string;
  cryptoTaxInfoText1?: string;
  cryptoTaxInfoText2?: string;
  cryptoTaxInfoTitle?: string;
  taxSystemOfflineWarningSubtitle?: string;
  taxSystemOfflineWarningTitle?: string;
  successBuyPageTitle: string;
  successSellPageTitle: string;
  successPageButtonContent: string;
  failPageTitle: string;
  failPageButtonContent: string;
  pendingSellPageTitle: string;
  pendingBuyPageTitle: string;
  pendingPageBUttonContent: string;
  failCardText: string;
  sellScreenTaxNotFinalWarning: string;
  payment_method: string;
  sepa_transfer: string;
  minimum: string;
};

const tradeScreenTranslations: TradeScreenTranslations = {
  buy: 'buy',
  eur: 'eur_label',
  subTotal: 'subtotal',
  total: 'total',
  fees: 'fees',
  grams: 'grams',
  inclSpread: 'incl_spread',
  buyNow: 'buy_now_button',
  actionButtonText: 'confirm',
  actionButtonLoadingText: 'trade_cta_button_loading',
  poweredByBpfs: 'powered_by_bpfs',
  poweredByBitpanda: 'powered_by_bitpanda',
  and: 'and',
  acceptMetalTerms: 'accept_metal_terms',
  andConfirmThatIhaveReadAndUnderstoodThe:
    'and_confirm_that_I_have_read_and_understood_the',

  investingRiskToggle: 'investing_risk_toggle',
  stocksToggleNotAppropriate: 'stocks_toggle_not_appropriate_description',
  actionButtonVerifyText: 'buy_verify_button',
  verifyTradeInfo: 'buy_verify_info',
  errorTooHigh: 'inline_error_amount_too_high',
  errorTooLow: 'inline_error_amount_too_low',
  errorAssetNotAvailableTitle: 'info_toast_asset_unavailable_title',
  errorAssetNotAvailableSubtitle: 'info_toast_asset_unavailable_subtitle',
  errorTradingNotPossibleTitle:
    'info_toast_trading_for_asset_not_possible_title',

  errorTradingNotPossibleSubtitle:
    'info_toast_trading_for_asset_not_possible_subtitle',

  errorTradingNotPossibleLink: 'info_toast_trading_for_asset_not_possible_link',

  offerExpiredTitle: 'info_toast_offer_expired_title',
  offerExpiredSubtitle: 'info_toast_offer_expired_subtitle',
  transactionFailedNotificationTitle: 'error_toast_transaction_failed_title',
  transactionFailedNotificationDescription:
    'error_toast_transaction_failed_subtitle',

  transactionDeclinedNotificationTitle:
    'error_toast_user_cancel_transaction_title',

  transactionDeclinedNotificationDescription:
    'error_toast_user_cancel_transaction_title',

  derivativeContract: 'derivative_contract',
  costTransparencyDocuments: 'cost_transparency_document',
  prospectus: 'prospectus',
  investorInformationDocument: 'investor_information_document',
  keyInformationDocument: 'key_information_document',
  legalTogglePartOne: 'legal_toggle_part_one',
  legalToggleLastPart: 'legal_toggle_last_part',
  max: 'Max',
  successBuySubtitleFirstLine: 'success_buy_subtitle_line_1',
  successBuySubtitleSecondLine: 'success_buy_subtitle_line_2',
  successScreenSubtitleFirstLine: 'success_screen_subtitle_line_1',
  successScreenSubtitleSecondLine: 'success_screen_subtitle_line_2',
  successSellSubtitleFirstLine: 'success_sale_subtitle_line_1',
  failureTransactionTitle: 'transaction_fail_title',
  backToPortfolio: 'back_to_portfolio_button',
  failureTransactionInline: 'inline_error_tranasaction_fail',
  sell: 'sell',
  units: 'units',
  sellNow: 'sell_now_button',
  taxWithHeld: 'tax_withheld',
  youRecieve: 'you_receive',
  aboutTaxTextLink: 'about_tax_text_link',
  cryptoTaxInfoText1: 'crypto_tax_info_text_line_1',
  cryptoTaxInfoText2: 'crypto_tax_info_text_line_2',
  cryptoTaxInfoTitle: 'crypto_tax_info_title',
  taxSystemOfflineWarningSubtitle: 'tax_system_offline_warning_subtitle',
  taxSystemOfflineWarningTitle: 'tax_system_offline_warning_title',
  successBuyPageTitle: 'success_buy_title',
  successSellPageTitle: 'success_sale_title',
  successPageButtonContent: 'ok_got_it_button',
  failPageTitle: 'transaction_fail_title',
  failPageButtonContent: 'back_to_portfolio_button',
  failCardText: 'inline_error_tranasaction_fail',
  pendingBuyPageTitle: 'pending_buy_title',
  pendingSellPageTitle: 'pending_sell_title',
  pendingPageBUttonContent: 'go_to_portfolio',
  sellScreenTaxNotFinalWarning: 'sell_screen_tax_not_final_warning',
  payment_method: 'payment_method',
  sepa_transfer: 'sepa_transfer',
  minimum: 'minimum',
};

export const getTradeTranslations = (
  transUtil: (param: string) => string,
): TradeScreenTranslations => {
  return Object.entries(tradeScreenTranslations).reduce((acc, [key, value]) => {
    acc[key as keyof TradeScreenTranslations] = transUtil(value);

    return acc;
  }, {} as TradeScreenTranslations);
};
