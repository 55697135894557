'use client';

import { forwardRef, ReactNode } from 'react';
import * as Accordion from '@radix-ui/react-accordion';
import { Icon } from '@bts-web/design-system/component/icon';

interface AccordionHeaderProps {
  children: ReactNode;
  IconComponent?: ReactNode;
}

export const AccordionHeader = forwardRef<
  HTMLButtonElement,
  AccordionHeaderProps
>((props, forwardedRef) => {
  const { IconComponent, children, ...restProps } = props;

  return (
    <Accordion.Header data-element="header" data-testid="header">
      <Accordion.Trigger
        {...restProps}
        data-element="trigger"
        ref={forwardedRef}
        data-testid="trigger"
      >
        {children}

        <span aria-hidden data-element="icon" data-testid="icon">
          {IconComponent || <Icon icon="chevron-down" size="24" />}
        </span>
      </Accordion.Trigger>
    </Accordion.Header>
  );
});
