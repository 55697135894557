'use client';

import { ReactNode } from 'react';
import * as RadixAccordion from '@radix-ui/react-accordion';
import { Item, type AccordionItem } from './AccordionItem.use-client';
import { AccordionContent } from './AccordionContent';

export interface AccordionProps {
  internal: {
    className: string;
    itemClassName: string;
  };
  external: {
    defaultValue?: string;
    items: AccordionItem[];
    IconComponent?: ReactNode;
  };
}

export function Accordion({
  defaultValue,
  className,
  itemClassName,
  IconComponent,
  items,
  ...restProps
}: AccordionProps['internal'] & AccordionProps['external']) {
  const isSingleItem = items.length === 1;

  return (
    <RadixAccordion.Root
      data-testid="accordion-root"
      className={className}
      type="single"
      defaultValue={defaultValue}
      collapsible
      {...restProps}
    >
      {isSingleItem ? (
        <>
          {items[0].contents.map(({ ContentIndicatorSlot, ...restContent }) => (
            <AccordionContent
              {...restContent}
              key={`content_${restContent.title}_${restContent.subtitle}`}
            >
              {ContentIndicatorSlot}
            </AccordionContent>
          ))}
        </>
      ) : (
        <>
          {items.map((item) => (
            <Item
              key={`accordion_item_${item.value}`}
              className={itemClassName}
              IconComponent={IconComponent}
              item={item}
            />
          ))}
        </>
      )}
    </RadixAccordion.Root>
  );
}
