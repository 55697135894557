import {
  ListCellsSelectionRoot as DesignListCellsSelectionRoot,
  ListCellsSelectionRootProps,
} from '@bts-web/design-system/component/list-cells-selection';
import { css } from '@bts-web/utils-style-engine';

const ListCellsSelectionRoot = ({
  children,
  onValueChange,
  ...rest
}: ListCellsSelectionRootProps) => (
  <DesignListCellsSelectionRoot
    className={css({
      backgroundColor: 'neutrals.card_fill_primary',
      display: 'flex',
      flexDirection: 'column',
    })}
    onValueChange={onValueChange}
    {...rest}
  >
    {children}
  </DesignListCellsSelectionRoot>
);

export { ListCellsSelectionRoot };
