'use client';

import { useContext, createContext, FC, PropsWithChildren } from 'react';
import { storage } from '@bts-web/utils-storage';
import {
  createQuestionContextProviderFactory,
  QuestionContextProps,
  QuestionAnswer,
} from '../QuestionContextProviderFactory.use-client';
import { getAppropriatenessQuestions } from '@bts-web/core-features/common';

const APPROPRIATENESS_QUESTION_ANSWERS_STORAGE = 'appropriateness.answers';

export const AppropriatenessQuestionContext =
  createContext<QuestionContextProps>(null);

export function useAppropriatenessQuestionContext() {
  const contextValue = useContext(AppropriatenessQuestionContext);

  if (contextValue === null) {
    throw new Error(
      'useAppropriatenessQuestionContext must be used within a AppropriatenessQuestionContextProvider',
    );
  }

  return contextValue;
}

export const saveQuestionAnswers = (answers: QuestionAnswer[]) => {
  storage.setItem(APPROPRIATENESS_QUESTION_ANSWERS_STORAGE, answers);
};

export const removeQuestionAnswers = () => {
  storage.removeItem(APPROPRIATENESS_QUESTION_ANSWERS_STORAGE);
};

const AppropriatenessQuestionProviderElement =
  createQuestionContextProviderFactory({
    Context: AppropriatenessQuestionContext,
    id: APPROPRIATENESS_QUESTION_ANSWERS_STORAGE,
    initialQuestionAnswers: storage.getItem(
      APPROPRIATENESS_QUESTION_ANSWERS_STORAGE,
    ),
    saveQuestionAnswers,
    removeQuestionAnswers,
  });

export const AppropriatenessQuestionContextProvider: FC<
  PropsWithChildren<{
    initialData: Awaited<ReturnType<typeof getAppropriatenessQuestions>>;
  }>
> = ({ initialData, children }) => {
  return (
    <AppropriatenessQuestionProviderElement initialQuestions={initialData}>
      {children}
    </AppropriatenessQuestionProviderElement>
  );
};
