import { css } from '@bts-web/utils-style-engine';
import { FC, useEffect, useState } from 'react';

interface OfferLoaderElementProps {
  duration: number;
  size?: number;
}

export const OfferLoaderElement: FC<OfferLoaderElementProps> = ({
  duration,
  size = 120,
}) => {
  const [progress, setProgress] = useState(100); // Start at 100%

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress <= 0) {
          clearInterval(interval);

          return 100; // Reset to 100% when it reaches 0%
        }

        const delta = (100 / duration) * 10; // Corrected update rate every 10 ms

        return Math.max(oldProgress - delta, 0);
      });
    }, 10);

    return () => clearInterval(interval);
  }, [duration]);

  const strokeWidth = size / 12; // Adjust stroke width based on size

  const radius = size / 2 - strokeWidth; // Adjust radius to fit within the SVG

  const circumference = 2 * Math.PI * radius;

  const strokeDashoffset = circumference * ((100 - progress) / 100); // Invert progress direction

  return (
    <svg width={size} height={size} aria-label="offer loader indicator">
      <circle
        stroke="blue"
        fill="transparent"
        strokeWidth={strokeWidth}
        strokeDasharray={circumference}
        strokeDashoffset={strokeDashoffset}
        r={radius}
        cx={size / 2}
        cy={size / 2}
        className={css({
          stroke: 'brand.fill_secondary',
        })}
      />
    </svg>
  );
};
