import { css } from '@bts-web/utils-style-engine';

const ListCellsSelectionItemBadge = ({
  visual = 'default',
  value,
}: {
  visual?: 'default' | 'active';
  value: string;
}) => (
  <span
    data-testid="list-cell-selection-badge"
    className={css({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexShrink: 0,
      width: '24px',
      height: '24px',
      fontSize: 'body.medium_medium',
      fontWeight: 'body.medium_medium',
      lineHeight: 'body.medium_medium',
      borderRadius: '21px',
      ...(visual === 'default'
        ? {
            backgroundColor: 'neutrals.fill_quinary',
            color: 'neutrals.text_primary',
          }
        : {}),
      ...(visual === 'active'
        ? {
            backgroundColor: 'neutrals.fill_tertiary',
            color: 'neutrals.text_primary_inverted',
          }
        : {}),
    })}
  >
    {value}
  </span>
);

export { ListCellsSelectionItemBadge };
