'use client';

import { FC } from 'react';
import { UserAccessWithRoleCheck, ButtonBase } from '../../../common';
import { submitButtonWrapperAndButtonProperStyles } from '../../utils';

export const TradeFormSubmitButton: FC<{
  disabled?: boolean;
  submitText: string;
  loading: boolean;
}> = ({ disabled, submitText, loading }) => {
  return (
    <div className={submitButtonWrapperAndButtonProperStyles}>
      <UserAccessWithRoleCheck>
        <ButtonBase
          type="submit"
          visual="accent"
          size="large"
          data-testid="trade-submit-button"
          aria-label="submit trade button"
          disabled={loading || disabled}
          isLoading={loading}
          fullWidth
        >
          <span>{submitText}</span>
        </ButtonBase>
      </UserAccessWithRoleCheck>
    </div>
  );
};
