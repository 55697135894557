import { css } from '@bts-web/utils-style-engine';
import { AmountFor } from '@bts-web/data-layer/server';
import { FC } from 'react';
import { DisplaySwitchItem } from './subComponents/DisplaySwitchItem';

export type DisplaySwitchItemType = {
  label: string;
  value: AmountFor;
};

export type DisplaySwitchWrapperType = {
  parentValue: string;
  items: DisplaySwitchItemType[];
  onChange: (value: AmountFor) => void;
};

export const DisplaySwitchWrapper: FC<DisplaySwitchWrapperType> = ({
  items,
  parentValue,
  onChange,
}) => {
  return (
    <div
      role="group"
      aria-label="display switch wrapper"
      className={css({
        bgColor: 'neutrals.fill_focused',
        display: 'inline-flex',
        gap: 'extra_small_4',
        alignItems: 'center',
        p: 'extra_small_4',
        borderRadius: '4px',
      })}
    >
      {items.map((item) => {
        const { label, value } = item;

        const active = parentValue === value;

        return (
          <DisplaySwitchItem
            key={value}
            active={active}
            label={label}
            value={value}
            onChange={onChange}
          />
        );
      })}
    </div>
  );
};
