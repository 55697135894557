import { ReactNode } from 'react';
import { css, stack } from '@bts-web/utils-style-engine';
import { HTMLRenderer } from '../../components/HTMLRenderer/HTMLRenderer';

const headingStyles = css({
  color: 'neutrals.text_primary_inverted',
  fontSize: {
    base: 'headline.extra_large_semi_bold',
    lg: 'display.large_bold',
  },
  fontWeight: {
    base: 'headline.extra_large_semi_bold',
    lg: 'display.large_bold',
  },
  lineHeight: {
    base: 'headline.extra_large_semi_bold',
    lg: 'display.large_bold',
  },
  letterSpacing: {
    base: 'headline.extra_large_semi_bold',
    lg: 'display.large_bold',
  },
});

const descriptionStyles = css({
  color: {
    base: 'neutrals.text_primary_inverted',
    lg: 'neutrals.text_secondary_inverted',
  },
  fontSize: {
    base: 'body.medium',
    lg: 'title.large',
  },
  fontWeight: {
    base: 'body.medium',
    lg: 'title.large',
  },
  letterSpacing: {
    base: 'body.medium',
    lg: 'title.large',
  },
  lineHeight: {
    base: 'body.medium',
    lg: 'title.large',
  },
  paddingRight: 'extra_large_2',
});

export interface IntroHeaderProps {
  primaryText: string;
  secondaryText?: string;
  description?: string;
  headerComponent?: ReactNode;
}

export const IntroHeader = ({
  primaryText,
  secondaryText,
  description,
  headerComponent,
}: IntroHeaderProps) => (
  <div
    data-testid="intro-header"
    aria-label="intro header"
    className={stack({ direction: 'column', gap: 'small' })}
  >
    <h1 className={headingStyles}>
      <p>{primaryText}</p>
      {secondaryText && <p>{secondaryText}</p>}
    </h1>

    {description && (
      <p className={descriptionStyles}>
        <HTMLRenderer>{description}</HTMLRenderer>
      </p>
    )}

    {headerComponent}
  </div>
);
