/**
 * @generated SignedSource<<2b46eda584c6315e234e8dcbf8f806a1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AssetHistoryPeriod = "DAY" | "FIVE_YEARS" | "MONTH" | "SIX_MONTHS" | "WEEK" | "YEAR";
export type AssetByTypeViewQuery$variables = {
  assetHistoryPeriod?: AssetHistoryPeriod | null;
  id?: string | null;
};
export type AssetByTypeViewQuery$data = {
  readonly assetById: {
    readonly __typename: string;
    readonly distributionPolicy?: string | null;
    readonly dividendPerShare?: PrecisionFloat | null;
    readonly dividendPerShareYear?: number | null;
    readonly dividendYield?: number | null;
    readonly dividendYieldYear?: number | null;
    readonly fundSize?: number | null;
    readonly history: ReadonlyArray<{
      readonly __typename: "AssetHistory";
      readonly close: PrecisionFloat | null;
      readonly time: string | null;
    } | null> | null;
    readonly id: string;
    readonly isin?: string | null;
    readonly legalUrl: string | null;
    readonly name: string | null;
    readonly peRatio?: number | null;
    readonly peRatioYear?: number | null;
    readonly portfolio?: {
      readonly assetBalance: PrecisionFloat | null;
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly assets: {
            readonly edges: ReadonlyArray<{
              readonly averageBuyPrice: PrecisionFloat | null;
              readonly fiatBalance: PrecisionFloat | null;
              readonly totalReturn: ValueChange | null;
            } | null> | null;
          } | null;
          readonly overallAmount: PrecisionFloat | null;
          readonly returnPercentage: number | null;
        } | null;
      } | null> | null;
      readonly totalInvested: PrecisionFloat | null;
      readonly totalReturn: ValueChange | null;
    } | null;
    readonly provider?: string | null;
    readonly recommendation?: {
      readonly breakdown: ReadonlyArray<{
        readonly percentage: number | null;
        readonly type: string | null;
      } | null> | null;
      readonly totalCount: number | null;
    } | null;
    readonly replicationMethod?: string | null;
    readonly revenue?: PrecisionFloat | null;
    readonly revenueYear?: string | null;
    readonly totalExpenseRatio?: number | null;
    readonly yearlyNetIncomes?: ReadonlyArray<{
      readonly period: string | null;
      readonly value: PrecisionFloat | null;
    } | null> | null;
  } | null;
};
export type AssetByTypeViewQuery = {
  response: AssetByTypeViewQuery$data;
  variables: AssetByTypeViewQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "assetHistoryPeriod"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "isin",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "fundSize",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "distributionPolicy",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "replicationMethod",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "provider",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalExpenseRatio",
    "storageKey": null
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalReturn",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "assetById",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "legalUrl",
        "storageKey": null
      },
      (v2/*: any*/),
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "period",
            "variableName": "assetHistoryPeriod"
          }
        ],
        "concreteType": "AssetHistory",
        "kind": "LinkedField",
        "name": "history",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "time",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "close",
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "dividendYield",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "dividendPerShare",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "dividendYieldYear",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "dividendPerShareYear",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "peRatioYear",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "peRatio",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "revenue",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "revenueYear",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AssetRecommendation",
            "kind": "LinkedField",
            "name": "recommendation",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AssetRecommendationBreakdown",
                "kind": "LinkedField",
                "name": "breakdown",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "percentage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "type",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "HistoricalValue",
            "kind": "LinkedField",
            "name": "yearlyNetIncomes",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "value",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "period",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "type": "StockAsset",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": (v3/*: any*/),
        "type": "EtfAsset",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": (v3/*: any*/),
        "type": "EtcAsset",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AssetPortfoliosConnection",
            "kind": "LinkedField",
            "name": "portfolio",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalInvested",
                "storageKey": null
              },
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "assetBalance",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AssetPortfoliosEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Portfolio",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "returnPercentage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "overallAmount",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PortfolioAssetsConnection",
                        "kind": "LinkedField",
                        "name": "assets",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PortfolioAssetsEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "averageBuyPrice",
                                "storageKey": null
                              },
                              (v4/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "fiatBalance",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "type": "CryptoAsset",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AssetByTypeViewQuery",
    "selections": (v5/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "AssetByTypeViewQuery",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "138072b7dd96519f9013e44b4e667dcc",
    "id": null,
    "metadata": {},
    "name": "AssetByTypeViewQuery",
    "operationKind": "query",
    "text": "query AssetByTypeViewQuery(\n  $id: ID\n  $assetHistoryPeriod: AssetHistoryPeriod\n) {\n  assetById(id: $id) {\n    id\n    name\n    legalUrl\n    __typename\n    history(period: $assetHistoryPeriod) {\n      time\n      close\n      __typename\n    }\n    ... on StockAsset {\n      dividendYield\n      dividendPerShare\n      dividendYieldYear\n      dividendPerShareYear\n      peRatioYear\n      peRatio\n      revenue\n      revenueYear\n      recommendation {\n        totalCount\n        breakdown {\n          percentage\n          type\n        }\n      }\n      yearlyNetIncomes {\n        value\n        period\n      }\n    }\n    ... on EtfAsset {\n      isin\n      fundSize\n      distributionPolicy\n      replicationMethod\n      provider\n      totalExpenseRatio\n    }\n    ... on EtcAsset {\n      isin\n      fundSize\n      distributionPolicy\n      replicationMethod\n      provider\n      totalExpenseRatio\n    }\n    ... on CryptoAsset {\n      portfolio {\n        totalInvested\n        totalReturn\n        assetBalance\n        edges {\n          node {\n            returnPercentage\n            overallAmount\n            assets {\n              edges {\n                averageBuyPrice\n                totalReturn\n                fiatBalance\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "bad566eb65847dbbe111e70d71584984";

export default node;
