'use client';

import {
  createContext,
  useContext,
  useState,
  PropsWithChildren,
  useEffect,
} from 'react';
import {
  AssetHistoryPeriod,
  type AssetDetailsViewQuery$data,
} from '@bts-web/data-layer/server';
import { TimeAmounts } from '@bts-web/design-system/component/chart';
import { getAssetByIdAndHystoricalInterval } from '@bts-web/core-features/common';
import { getPrecisionFloatValue } from '@bts-web/utils-formatting';
import { useTradeSellDisabler } from '@bts-web/core-features/trade';

export type AssetFields = AssetDetailsViewQuery$data['assetById'];

interface AssetDetailsDataContextValue {
  assetData: AssetFields | null;
  loading: boolean;
  timeRange: TimeAmounts;
  changeTimeRange: (newPeriod: TimeAmounts) => void;
}

const PeriodsIds: Record<TimeAmounts, AssetHistoryPeriod> = {
  [TimeAmounts.OneDay]: 'DAY',
  [TimeAmounts.SevenDays]: 'WEEK',
  [TimeAmounts.OneMonth]: 'MONTH',
  [TimeAmounts.OneYear]: 'YEAR',
  [TimeAmounts.SixMonths]: 'SIX_MONTHS',
  [TimeAmounts.MaxYears]: 'FIVE_YEARS',
};

export const AssetDetailsDataContext =
  createContext<AssetDetailsDataContextValue>(
    {} as AssetDetailsDataContextValue,
  );

export function useAssetDetailsDataContext() {
  const contextValue = useContext(AssetDetailsDataContext);

  if (contextValue === null) {
    throw new Error(
      'useAssetDetailsDataContext must be used within a AssetDetailsDataContextProvider',
    );
  }

  return contextValue;
}

const ASSET_DETAILS_SELL_BUTTON_KEY = 'ASSET_DETAILS_SELL_BUTTON_KEY';

export const AssetDetailsDataContextProvider = ({
  children,
  initialData,
}: PropsWithChildren<{
  initialData: AssetDetailsViewQuery$data['assetById'];
}>) => {
  const [assetData, setAssetData] =
    useState<AssetDetailsDataContextValue['assetData']>(initialData);

  const [loading, setLoading] = useState(false);

  const [timeRange, setTimeRange] = useState<TimeAmounts>(TimeAmounts.OneDay);

  const changeTimeRange = (newPeriod: TimeAmounts) => {
    setLoading(true);

    const periodTimeAmountFormat = newPeriod;

    const periodQueryVarFormat = PeriodsIds[newPeriod] as AssetHistoryPeriod;

    getAssetByIdAndHystoricalInterval({
      assetHistoryPeriod: periodQueryVarFormat,
      assetId: initialData!.id,
    }).then((response) => {
      setLoading(false);

      setTimeRange(periodTimeAmountFormat);

      setAssetData(response.data?.assetById);
    });
  };

  const isAssetOwned = Boolean(
    Number(getPrecisionFloatValue(assetData?.portfolio?.fiatBalance)),
  );

  // used for refreshing context data after server refetch (e.g. change language)
  useEffect(() => {
    setAssetData(initialData);
  }, [initialData]);

  useTradeSellDisabler({
    shouldDisable: isAssetOwned === false,
    disablerKey: ASSET_DETAILS_SELL_BUTTON_KEY,
  });

  return (
    <AssetDetailsDataContext.Provider
      value={{
        assetData,
        loading,
        timeRange,
        changeTimeRange: changeTimeRange,
      }}
    >
      {children}
    </AssetDetailsDataContext.Provider>
  );
};
