import { FC } from 'react';
import { SVGIconType } from '../types';
import { css } from '@bts-web/utils-style-engine';

const BitPandaLogoIcon: FC<SVGIconType> = ({ color, size }) => {
  const colorClasses = css({
    color: color || 'rgba(0, 0, 0, 1)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  });

  return (
    <div className={colorClasses} style={{ width: size, height: 'auto' }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        fill="none"
        viewBox="0 0 22 33"
      >
        <title>Bitpanda</title>
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M3.208 6.051l10.025-3.115a6.037 6.037 0 012.488-.26l-1.258-.905a5.254 5.254 0 00-4.525-.698L.416 4.03 3.208 6.05zm15.273 8.547s1.77-1.585 1.77-4.987V8.17c0-2.241-2.618-5.883-6.811-4.577l-9.947 3.09v18.17l2.794-.867v-4.468l8.977-2.789a2.344 2.344 0 013.073 2.216v1.772c0 1.803-1.616 4.36-4.15 5.145L.422 30.14l2.793 2.018 12.4-3.854c2.618-.826 5.517-3.028 5.517-7.733v-1.81c-.007-2.835-2.651-4.163-2.651-4.163zM6.292 16.63l7.998-2.478h-.005c1.919-.593 3.175-2.224 3.179-4.48V8.388c0-1.358-1.51-2.673-3.178-2.157L6.292 8.71v7.922zm8.2-9.757l-.523.163v1.947a2.273 2.273 0 01-1.587 2.153l-5.395 1.677v2.89l7.091-2.202c1.676-.516 2.686-1.948 2.686-3.828V8.383c-.001-.888-1.027-1.897-2.271-1.509zm3.14 12.08v1.77c0 1.613-1.47 3.815-3.664 4.488L0 29.552V4.588l2.794 2.018v19.193l10.09-3.136a2.747 2.747 0 001.955-2.625v-2.45l.633-.196c1.183-.365 2.16.562 2.16 1.563z"
          clipRule="evenodd"
        ></path>
      </svg>
    </div>
  );
};

export { BitPandaLogoIcon };
