'use client';

import * as Progress from '@radix-ui/react-progress';

export interface ProgressBarProps {
  internal: {
    className: string;
    primitiveStyles: {
      indicatorStyle: string;
    };
  };
  external: {
    progress: number;
  };
}

export const ProgressBar = (
  props: ProgressBarProps['internal'] & ProgressBarProps['external'],
) => {
  const {
    className,
    primitiveStyles: { indicatorStyle },
    progress,
  } = props;

  const indicatorCSSVariables: React.CSSProperties = {
    '--progress': `${progress}%`,
  } as React.CSSProperties;

  return (
    <Progress.Root
      className={className}
      data-testid="progress-bar"
      value={progress}
    >
      <Progress.Indicator
        data-testid="progress-bar-indicator"
        className={indicatorStyle}
        style={indicatorCSSVariables}
      />
    </Progress.Root>
  );
};
