import { FC } from 'react';
import { DisplaySwitchItemType } from '../DisplaySwitchWrapper';
import { css } from '@bts-web/utils-style-engine';
import { AmountFor } from '@bts-web/data-layer/server';

export type DisplaySwitchItemProps = DisplaySwitchItemType & {
  active: boolean;
  onChange: (value: AmountFor) => void;
};

const DisplaySwitchItem: FC<DisplaySwitchItemProps> = ({
  active,
  label,
  value,
  onChange,
}) => {
  return (
    <button
      aria-label="display switch item"
      // define this so the form doesn't submit when the button is clicked
      type="button"
      disabled={active}
      className={css({
        px: 'extra_small_3',
        py: 'extra_small_4',
        fontSize: 'caption.medium_medium',
        color: active ? 'neutrals.text_primary' : 'neutrals.text_secondary',
        bgColor: active
          ? 'neutrals.card_fill_primary'
          : 'neutrals.fill_focused',
        borderRadius: '2px',
        minWidth: '40px',
        _hover: !active
          ? {
              cursor: 'pointer',
              color: 'neutrals.text_secondary',
              bgColor: 'neutrals.fill_quaternary',
            }
          : {},
      })}
      onClick={() => onChange(value)}
    >
      {label}
    </button>
  );
};

export { DisplaySwitchItem };
