'use client';

import { SVGIconType } from '../../../../common/svgIcons/types';
import { Icon, IconProps } from '@bts-web/design-system/component/icon';
import { css } from '@bts-web/utils-style-engine';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { FC, ReactNode } from 'react';

type LeftNavMenuEntryBaseProps = {
  url: string;
  children: ReactNode;
  disabled?: boolean;
};

type IconPropsGroup = {
  icon: string;
  iconTheme?: IconProps['theme'];
  IconComponent?: never;
};

type IconComponentGroup = {
  icon?: never;
  iconTheme?: never;
  IconComponent: FC<SVGIconType>;
};

type LeftNavMenuEntryProps = LeftNavMenuEntryBaseProps &
  (IconPropsGroup | IconComponentGroup);

export const LeftNavMenuEntry: FC<LeftNavMenuEntryProps> = ({
  icon,
  url,
  children,
  disabled,
  iconTheme,
  IconComponent,
}) => {
  const pathName = usePathname();

  const isActive = pathName.includes(url);

  return (
    <Link
      aria-label={`${disabled ? 'disabled ' : ''} nav link button to ${children}`}
      href={url}
      className={`${css({
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
        maxWidth: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        transition: 'background-color 0.3s',
        ...(!disabled
          ? {
              '&:hover': {
                '& > div': { backgroundColor: 'neutrals.fill_focused' },
              },
            }
          : {
              color: 'neutrals.text_disabled',
              pointerEvents: 'none',
              userSelect: 'none',
            }),
      })}`}
      onClick={
        disabled
          ? (e) => {
              e.preventDefault();

              return;
            }
          : undefined
      }
    >
      <div
        className={`${disabled ? 'disabled ' : ''}${css({
          borderRadius: '5px',
          width: 48,
          height: 40,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: disabled ? 'neutrals.text_disabled' : 'inherit',
          backgroundColor: isActive ? 'neutrals.fill_quinary' : 'transparent',
        })}`}
      >
        {IconComponent ? (
          <IconComponent
            color={`${!disabled ? 'neutrals.text_primary' : 'neutrals.text_disabled'}`}
            size={22}
          />
        ) : icon && iconTheme ? (
          <Icon
            icon={icon} // `icon` is guaranteed to be defined if `IconComponent` is not used
            theme={iconTheme ?? 'filled'}
            color={`${!disabled ? 'neutrals.text_primary' : 'neutrals.text_disabled'}`}
          />
        ) : null}
      </div>
      <span
        className={css({
          textDecoration: 'inherit',
          color: 'inherit',
          fontWeight: 'inherit',
        })}
      >
        {children}
      </span>
    </Link>
  );
};
