'use client';

import { useContext, createContext, PropsWithChildren, FC } from 'react';
import { storage } from '@bts-web/utils-storage';
import {
  createQuestionContextProviderFactory,
  QuestionContextProps,
  QuestionAnswer,
} from '../QuestionContextProviderFactory.use-client';
import { getAMLQuestions } from '@bts-web/core-features/common';

const AML_QUESTION_ANSWERS_STORAGE = 'aml.answers';

export const AmlQuestionContext = createContext<QuestionContextProps>(null);

export function useAmlQuestionContext() {
  const contextValue = useContext(AmlQuestionContext);

  if (contextValue === null) {
    throw new Error(
      'useAmlQuestionContext must be used within a AmlQuestionContextProvider',
    );
  }

  return contextValue;
}

export const saveQuestionAnswers = (answers: QuestionAnswer[]) => {
  storage.setItem(AML_QUESTION_ANSWERS_STORAGE, answers);
};

export const removeQuestionAnswers = () => {
  storage.removeItem(AML_QUESTION_ANSWERS_STORAGE);
};

export const AmlQuestionContextProviderElement =
  createQuestionContextProviderFactory({
    Context: AmlQuestionContext,
    id: AML_QUESTION_ANSWERS_STORAGE,
    initialQuestionAnswers: storage.getItem(AML_QUESTION_ANSWERS_STORAGE),
    saveQuestionAnswers,
    removeQuestionAnswers,
  });

export const AmlQuestionContextProvider: FC<
  PropsWithChildren<{
    initialData: Awaited<ReturnType<typeof getAMLQuestions>>;
  }>
> = ({ initialData, children }) => {
  return (
    <AmlQuestionContextProviderElement initialQuestions={initialData}>
      {children}
    </AmlQuestionContextProviderElement>
  );
};
