'use client';

import { storage } from '@bts-web/utils-storage';
import { PREVIOUS_INPUT_VALUE_KEY } from '../constants';

// any value passed here must be in the FIAT value, as long as it stays the default view, and is bound only once, on mount
export const setInputValueToLocalStorage = ({
  value,
  assetId,
}: {
  value: string;
  assetId: string;
}) => {
  storage.setItem(PREVIOUS_INPUT_VALUE_KEY, JSON.stringify({ value, assetId }));
};

// needs to be used only once, when binding the input value, and the default view is FIAT
export const getInputValueFromLocalStorage = ({
  assetId,
}: {
  assetId: string;
}): string => {
  const storedValue: Record<string, string> =
    storage.getItem(PREVIOUS_INPUT_VALUE_KEY) ?? {};

  const { value, assetId: storedAssetId } = storedValue;

  if (!storedAssetId || assetId !== storedAssetId) {
    return '';
  } else {
    return value;
  }
};

// to be used when the input is used by the user, in the current case, on the action of accepting the offer
export const removeInputValueFromLocalStorage = () => {
  return storage.removeItem(PREVIOUS_INPUT_VALUE_KEY);
};
