'use client';

import { Root, RadioGroupProps } from '@radix-ui/react-radio-group';

export interface ListCellsSelectionRootProps extends RadioGroupProps {
  className?: string;
}

function ListCellsSelectionRoot({
  className,
  children,
  ...rest
}: ListCellsSelectionRootProps) {
  return (
    <Root data-testid="list-cells-selection-root" {...{ className, ...rest }}>
      {children}
    </Root>
  );
}

export { ListCellsSelectionRoot };
