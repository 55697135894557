import { css } from '@bts-web/utils-style-engine';
import { FC, PropsWithChildren } from 'react';

export const HiddenMobile: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div
      className={css({
        hideBelow: 'lg',
      })}
    >
      {children}
    </div>
  );
};
