/**
 * @generated SignedSource<<75d4d3299b3ba3b26ce2f3afedca30ce>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type TermsAndConditionsInput = {
  after?: string | null;
  before?: string | null;
  first?: number | null;
  last?: number | null;
};
export type TermsAndConditionsViewQuery$variables = {
  input?: TermsAndConditionsInput | null;
};
export type TermsAndConditionsViewQuery$data = {
  readonly termsAndConditions: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly source: string | null;
        readonly type: string | null;
        readonly version: string | null;
      } | null;
    } | null> | null;
  } | null;
};
export type TermsAndConditionsViewQuery = {
  response: TermsAndConditionsViewQuery$data;
  variables: TermsAndConditionsViewQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "TermsAndConditionsConnection",
    "kind": "LinkedField",
    "name": "termsAndConditions",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "TermsAndConditionsEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TermsAndConditions",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "version",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "source",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TermsAndConditionsViewQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TermsAndConditionsViewQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a866a7dfec59536e229f3abde85a462c",
    "id": null,
    "metadata": {},
    "name": "TermsAndConditionsViewQuery",
    "operationKind": "query",
    "text": "query TermsAndConditionsViewQuery(\n  $input: TermsAndConditionsInput\n) {\n  termsAndConditions(input: $input) {\n    edges {\n      node {\n        id\n        type\n        version\n        source\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e3d90f6feee65954948979d9373b6b3d";

export default node;
