'use client';

import { css } from '@bts-web/utils-style-engine';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { FC, ReactNode } from 'react';

interface MobileNavItemLinkProps {
  href: string;
  icon: ReactNode;
  label: string;
}

export const MobileNavItemLink: FC<MobileNavItemLinkProps> = ({
  icon,
  label,
  href,
}) => {
  const pathname = usePathname();

  const active = pathname.includes(href);

  return (
    <Link
      href={href}
      aria-label={`link to ${label?.replace(/ /g, '').toLowerCase()}`}
      role="link"
      className={`${css({
        alignItems: 'center',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        fontSize: 'caption.small',
        gap: 'extra_small_3',
        color: 'neutrals.text_primary',
        borderBottom: '2px solid transparent',
        py: 'extra_small_2',
        px: 'extra_small_2',
        '& span': {
          color: 'neutrals.text_secondary',
        },
        '&.active': {
          borderBottom: '2px solid black',
          '& span': {
            color: 'neutrals.text_primary',
          },
        },
      })} ${active ? ' active' : ''}`}
      data-active={active}
    >
      {icon}
      <span>{label}</span>
    </Link>
  );
};
