'use client';

import { ReactNode } from 'react';
import * as Accordion from '@radix-ui/react-accordion';
import { AccordionHeader } from './AccordionHeader.use-client';
import { AccordionContent } from './AccordionContent';

export interface AccordionData {
  title: string;
  image: string;
  fallbackImage?: string;
  subtitle?: string;
  ContentIndicatorSlot: ReactNode;
  onClick?: () => void;
}

export interface AccordionItem {
  value: string;
  header: AccordionData;
  contents: AccordionData[];
}

interface AccordionItemProps {
  internal: {
    className?: string;
    contentClassName?: string;
  };
  external: {
    item: AccordionItem;
    IconComponent?: ReactNode;
  };
}

export const Item = ({
  className,
  IconComponent,
  item,
}: AccordionItemProps['internal'] & AccordionItemProps['external']) => {
  const { value, header, contents } = item;

  const {
    ContentIndicatorSlot: HeaderIndicatorSlotComponent,
    ...restHeaderProps
  } = header;

  return (
    <Accordion.Item
      value={value}
      className={className}
      data-testid="accordion-item"
    >
      <AccordionHeader IconComponent={IconComponent}>
        <AccordionContent {...restHeaderProps}>
          {HeaderIndicatorSlotComponent}
        </AccordionContent>
      </AccordionHeader>

      <Accordion.Content data-element="content" data-testid="content">
        {contents.map(({ ContentIndicatorSlot, ...restContent }) => (
          <AccordionContent
            {...restContent}
            key={`content_${restContent.title}_${restContent.subtitle}_${value}`}
          >
            {ContentIndicatorSlot}
          </AccordionContent>
        ))}
      </Accordion.Content>
    </Accordion.Item>
  );
};
