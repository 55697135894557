/* eslint-disable @nx/enforce-module-boundaries */
import React, { FC } from 'react';

export const HTMLRenderer: FC<{ children: string }> = ({ children }) => {
  return (
    <>
      {children.split('\\n').map((line, index) => (
        <React.Fragment key={index}>
          {line}
          <br />
        </React.Fragment>
      ))}
    </>
  );
};
