import { css } from '@bts-web/utils-style-engine';
import { FC, ReactNode } from 'react';

interface AlertBoxProps {
  variant: 'success' | 'error' | 'warning' | 'info';
  children: ReactNode;
}

const AlertBox: FC<AlertBoxProps> = ({ children, variant }) => {
  return (
    <div
      aria-label={`${variant} message box`}
      className={css({
        border: '2px solid',
        borderRadius: '8px',
        padding: 'small',
        fontSize: 'label.medium_medium',
        fontWeight: 'label.medium_medium',
        lineHeight: 'label.medium_medium',
        letterSpacing: 'label.medium_medium',
        ...(variant === 'success'
          ? {
              borderColor: 'positive.text_primary',
              color: 'positive.text_primary',
            }
          : {}),
        ...(variant === 'warning'
          ? {
              borderColor: 'warning.stroke_primary',
              color: 'warning.text_primary',
            }
          : {}),
        ...(variant === 'info'
          ? {
              borderColor: 'information.text_primary',
              color: 'information.text_primary',
            }
          : {}),
        ...(variant === 'error'
          ? {
              borderColor: 'negative.text_primary',
              color: 'negative.text_primary',
            }
          : {}),
      })}
    >
      {children}
    </div>
  );
};

export { AlertBox };
