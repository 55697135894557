/**
 * @generated SignedSource<<7b422003743e58739f196193292a8720>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type InitiatedBy = "BITPANDA_CORPORATE_ACTION" | "BITPANDA_MANUAL_TRADE" | "BITPANDA_SAVINGS" | "OTHER" | "USER";
export type TransactionStatus = "CREATED" | "EXPIRED" | "FAILED" | "FINISHED" | "IN_PROGRESS";
export type TransactionInput = {
  tradeId?: string | null;
  tradeOfferId?: string | null;
};
export type TransactionViewQuery$variables = {
  input: TransactionInput;
};
export type TransactionViewQuery$data = {
  readonly transaction: {
    readonly __typename: string;
    readonly asset: {
      readonly __typename: string;
      readonly id: string;
      readonly logoUrl: string | null;
      readonly minimumSellAmount: PrecisionFloat | null;
      readonly name: string | null;
      readonly portfolio: {
        readonly fiatBalance: PrecisionFloat | null;
      } | null;
      readonly symbol: string | null;
    } | null;
    readonly assetAmount: PrecisionFloat | null;
    readonly assetPrice?: PrecisionFloat | null;
    readonly fiat: {
      readonly id: string;
      readonly name: string | null;
      readonly symbol: string | null;
    } | null;
    readonly fiatAmount: PrecisionFloat | null;
    readonly id: string;
    readonly initiatedBy: InitiatedBy | null;
    readonly spread: PrecisionFloat | null;
    readonly status: TransactionStatus | null;
    readonly time: any | null;
  } | null;
};
export type TransactionViewQuery = {
  response: TransactionViewQuery$data;
  variables: TransactionViewQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "symbol",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "assetPrice",
    "storageKey": null
  }
],
v6 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "transaction",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "asset",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v1/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "logoUrl",
            "storageKey": null
          },
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "AssetPortfoliosConnection",
            "kind": "LinkedField",
            "name": "portfolio",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fiatBalance",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "minimumSellAmount",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Fiat",
        "kind": "LinkedField",
        "name": "fiat",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v4/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "spread",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "assetAmount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "fiatAmount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "time",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "initiatedBy",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": (v5/*: any*/),
        "type": "SellTransaction",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": (v5/*: any*/),
        "type": "BuyTransaction",
        "abstractKey": null
      },
      (v2/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TransactionViewQuery",
    "selections": (v6/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TransactionViewQuery",
    "selections": (v6/*: any*/)
  },
  "params": {
    "cacheID": "fc4eac907f45435b47452673d674411b",
    "id": null,
    "metadata": {},
    "name": "TransactionViewQuery",
    "operationKind": "query",
    "text": "query TransactionViewQuery(\n  $input: TransactionInput!\n) {\n  transaction(input: $input) {\n    id\n    asset {\n      __typename\n      id\n      symbol\n      logoUrl\n      name\n      portfolio {\n        fiatBalance\n      }\n      minimumSellAmount\n    }\n    fiat {\n      id\n      name\n      symbol\n    }\n    spread\n    assetAmount\n    fiatAmount\n    status\n    time\n    initiatedBy\n    ... on SellTransaction {\n      assetPrice\n    }\n    ... on BuyTransaction {\n      assetPrice\n    }\n    __typename\n  }\n}\n"
  }
};
})();

(node as any).hash = "462d95860c71f9d4b89e396226ec0f26";

export default node;
