'use client';

import { useCallback } from 'react';
import { css } from '@bts-web/utils-style-engine';
import { Icon } from '@bts-web/design-system/component/icon';
import { throttle } from '@bts-web/utils-functions';
import { ButtonBase } from '../ButtonBase/ButtonBase';
import { useAppNotification } from '../../../notifications/NotificationContextProvider';

const labelStyles = css({
  fontSize: 'label.medium_medium',
  lineHeight: 'label.medium_medium',
  letterSpacing: 'label.medium_medium',
  fontWeight: 'label.medium_medium',
  color: 'neutrals.text_secondary',
});

const valueStyles = css({
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  minWidth: 0,
});

const containerStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const valueContainerStyles = css({
  display: 'flex',
  flexDirection: 'column',
  minWidth: 0,
  paddingRight: 'small',
});

interface CopyToClipboardProps {
  value: string;
  translations: {
    copy: string;
    walletAddress: string;
    successToastAddressTitle: string;
    addressCopiedFailed: string;
  };
}

export const CopyToClipboard = ({
  translations,
  value,
}: CopyToClipboardProps) => {
  const { copy, walletAddress, successToastAddressTitle, addressCopiedFailed } =
    translations;

  const { setAppNotification } = useAppNotification();

  const copyToClipboardAndShowNotification = async () => {
    try {
      await navigator.clipboard.writeText(value);

      setAppNotification({
        title: successToastAddressTitle,
        visual: 'success',
        customDuration: 3000,
      });
    } catch (error) {
      setAppNotification({
        title: addressCopiedFailed,
        visual: 'error',
        customDuration: 3000,
      });
    }
  };

  const handleCopyToClipboard = useCallback(
    throttle(() => copyToClipboardAndShowNotification(), 4000),
    [value],
  );

  return (
    <div className={containerStyle}>
      <div className={valueContainerStyles}>
        <div aria-label="wallet address label" className={labelStyles}>
          {walletAddress}
        </div>

        <div className={valueStyles}>{value}</div>
      </div>

      <div>
        <ButtonBase
          onClick={() => handleCopyToClipboard()}
          visual="ghost"
          size="small"
          aria-label="copy asset address"
          noPadding
        >
          {copy}
          <Icon icon="copy" size="20" />
        </ButtonBase>
      </div>
    </div>
  );
};
