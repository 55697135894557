'use client';

import React, { useState } from 'react';
import { Icon } from '@bts-web/design-system/component/icon';
import { Button } from '@bts-web/design-system/component/button';
import { sva } from '@bts-web/utils-style-engine';
import { SortOrder } from '@bts-web/data-layer/server';

const sortButtonStyles = sva({
  slots: ['root', 'icon'],
  base: {
    root: {
      cursor: 'pointer',
      display: 'inline-flex',
      minHeight: '40px',
      minWidth: '40px',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  variants: {
    visual: {
      primary: {
        root: {
          color: 'neutrals.text_primary',
          backgroundColor: 'neutrals.fill_quaternary',
          borderRadius: '30px',
          padding: 'extra_small_2',
          '&:disabled': {
            backgroundColor: 'neutrals.fill_quinary',
            opacity: '0.5',
          },
        },
      },
    },
  },
  defaultVariants: {
    visual: 'primary',
  },
});

type SortButtonStylesVariantProps = Parameters<typeof sortButtonStyles>[0];

type SortButtonProps = SortButtonStylesVariantProps & {
  disabled?: boolean;
  defaultValue?: SortOrder;
  onChange: (order: SortOrder) => void;
};

export const SortButton = ({
  visual = 'primary',
  defaultValue = 'DESC',
  disabled,
  onChange,
}: SortButtonProps) => {
  const [sortValue, setSortValue] = useState<SortOrder>(defaultValue);

  const handleClick = () => {
    const newSortValue = sortValue === 'DESC' ? 'ASC' : 'DESC';

    setSortValue(newSortValue);

    onChange(newSortValue);
  };

  const styles = sortButtonStyles({ visual });

  return (
    <Button
      data-state={sortValue.toLowerCase()}
      data-testid="sort-button"
      onClick={handleClick}
      disabled={disabled}
      className={styles.root ?? ''}
    >
      <Icon
        icon={sortValue === 'ASC' ? 'arrow-sort-up' : 'arrow-sort-down'}
        size="24"
      />
    </Button>
  );
};
