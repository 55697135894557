import { css } from '@bts-web/utils-style-engine';

interface DrawerContentProps extends React.PropsWithChildren {
  title: string;
  ariaLabel?: string;
}

const drawerIndicatorStyles = css({
  display: 'flex',
  justifyContent: 'center',
  paddingBottom: 'extra_small',
  paddingTop: 'extra_small_2',
  '& span': {
    backgroundColor: 'neutrals.fill_secondary',
    borderRadius: '8px',
    width: '40px',
    height: '2px',
  },
});

const titleStyles = css({
  height: '48px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'neutrals.text_primary',
  fontSize: 'label.medium_medium',
  fontWeight: 'label.medium_medium',
  letterSpacing: 'label.medium_medium',
  lineHeight: 'label.medium_medium',
  borderBottomWidth: '1px',
  borderBottomColor: 'neutrals.divider',
});

const containerStyles = css({
  display: 'flex',
  flexDirection: 'column',
  gap: '0',
  paddingBottom: 'small',
});

const DrawerContent = ({ title, ariaLabel, children }: DrawerContentProps) => {
  return (
    <div className={css({ display: 'flex', flexDirection: 'column' })}>
      <div className={drawerIndicatorStyles}>
        <span />
      </div>
      <div className={titleStyles}>{title}</div>
      <div className={containerStyles} aria-label={ariaLabel}>
        {children}
      </div>
    </div>
  );
};

export { DrawerContent };
