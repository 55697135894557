import { TransactionFinalizedSummaryDataQuery } from '@bts-web/data-layer/server';

export type Mutable<T> = {
  -readonly [P in keyof T]: T[P] extends (infer R)[]
    ? Mutable<R>[]
    : T[P] extends ReadonlyArray<infer R>
      ? Mutable<R>[]
      : T[P] extends object
        ? Mutable<T[P]>
        : T[P];
};

export type PoweredByTextType = {
  value: string;
  fontSize: string;
  centered?: boolean;
};

export enum AssetType {
  CRYPTO = 'CryptoAsset',
  STOCK = 'StockAsset',
  METAL = 'MetalAsset',
  ETF = 'EtfAsset',
  ETC = 'EtcAsset',
}

export type TradeControllerVariants = 'buy' | 'sell';

export type TradeBuyApplicationStatePushTan = 'declined' | 'failed';

export type TradeFinishTransactionControllerVariants =
  | 'success'
  | 'failure'
  | 'pending';

export type NonNullableTransactionFinalizedSummaryData = Mutable<
  NonNullable<TransactionFinalizedSummaryDataQuery['response']['transaction']>
>;
