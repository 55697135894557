'use client';

import { useState } from 'react';
import * as ToggleGroup from '@radix-ui/react-toggle-group';
import {
  BarItem as DBarItem,
  BarItemProps,
} from '@bts-web/design-system/component/bar-item';

export type BarItem = {
  label: string;
  value: string;
  [key: string]: any;
};

export type BarItems = Array<BarItem>;

export type BarProps = {
  internal: {
    className: string;
    barItemClassName: string;
  };
  external: {
    onValueChange: (selectedValue: string, selectedItem: BarItem) => void;
    items: BarItems;
    defaultValue?: string;
  } & BarItemProps['external'];
};

export const Bar = (props: BarProps['internal'] & BarProps['external']) => {
  const {
    className,
    barItemClassName,
    value,
    defaultValue,
    items,
    onValueChange,
    disabled,
  } = props;

  const [selectedOption, setOption] = useState(defaultValue);

  const onBarValueChange = (selectedValue: string) => {
    if (selectedValue) {
      const selectedItem = items.find(
        (item) => item.value === selectedValue,
      ) as BarItem;

      setOption(selectedValue);

      onValueChange(selectedValue, selectedItem);
    }
  };

  return (
    <ToggleGroup.Root
      className={className}
      value={value || selectedOption}
      onValueChange={onBarValueChange}
      orientation="horizontal"
      type="single"
    >
      {items.map((item) => (
        <DBarItem
          isGrouped
          className={barItemClassName}
          key={item.value}
          value={item.value}
          disabled={disabled}
          aria-label={item.label}
          data-testid={item.value}
        >
          {item.label}
        </DBarItem>
      ))}
    </ToggleGroup.Root>
  );
};
