'use client';

import { useEffect, useState } from 'react';
import { ContentProps } from './AccordionContent';
import Image from 'next/image';

const AccordionContentImage: React.FC<
  Pick<ContentProps, 'fallbackImage' | 'image' | 'title'> & {
    width: number;
    height: number;
  }
> = ({ image, fallbackImage, title, height, width }) => {
  /*
- the useState has been inserted as storage for the URL of the file, to be used in the onError event
- do not select to modify the <img src /> of the <Image /> element directly since it breaks the relationship in between the VirtualDOM and the DOM and caching mechanisms
*/
  const [imageSrc, setImageSrc] = useState<string | undefined>(
    () => image ?? fallbackImage,
  );

  useEffect(() => {
    setImageSrc(image);
  }, [image]);

  if (!imageSrc) {
    return null;
  }

  return (
    <Image
      data-testid="accordion-item-content-image"
      priority
      src={imageSrc}
      alt={title}
      width={width}
      height={height}
      unoptimized
      onError={() => {
        setImageSrc(fallbackImage ?? undefined);
      }}
    />
  );
};

export { AccordionContentImage };
