import { FC } from 'react';
import { SVGIconType } from '../types';
import { css } from '@bts-web/utils-style-engine';

const CompassIcon: FC<SVGIconType> = ({ color, size }) => {
  const colorClasses = css({
    color: color,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  });

  return (
    <div
      className={colorClasses}
      style={{ width: size, height: 'auto' }}
      data-testid="compass-icon"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        viewBox="0 0 22 22"
        fill="currentColor"
      >
        <path
          fill="currentColor"
          d="M13.718.858a10.5 10.5 0 00-12.86 12.86.75.75 0 001.449-.389A9 9 0 0113.329 2.307a.75.75 0 10.389-1.45z"
        ></path>
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M21.179 2.376c.659-1.018-.538-2.214-1.556-1.555L8.41 8.076c-.134.087-.247.2-.334.334L.821 19.623c-.659 1.018.537 2.214 1.555 1.556l11.214-7.256c.133-.086.247-.2.333-.333L21.18 2.376zM9.292 9.292l9.677-6.261-6.262 9.676-9.676 6.262 6.261-9.677z"
          clipRule="evenodd"
        ></path>
        <path
          fill="currentColor"
          d="M18.425 18.425a10.5 10.5 0 01-10.143 2.717.75.75 0 11.389-1.449A9 9 0 0019.693 8.671a.75.75 0 011.45-.389 10.499 10.499 0 01-2.718 10.143z"
        ></path>
      </svg>
    </div>
  );
};

export { CompassIcon };
