import { createContext } from 'react';
import { AppUserFullInfo } from '../types';

interface IUserContextInterface {
  data: AppUserFullInfo;
  getUserInfo: () => void;
  updateUserInfo: (data: AppUserFullInfo) => void;
}

export const UserInfoContext = createContext<{
  data: AppUserFullInfo;
  getUserInfo: () => void;
  updateUserInfo: (data: AppUserFullInfo) => void;
}>({} as IUserContextInterface);
