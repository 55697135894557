import {
  ChangeEventHandler,
  FocusEventHandler,
  forwardRef,
  useId,
} from 'react';
import { inputWrapperAndInputProperStyles } from '../../utils/styles';
import { getInputClassNameByAmountLength } from '../../utils';

type TradeInputProps = {
  onChange: ChangeEventHandler<HTMLInputElement>;
  onBlur?: (value: string) => void;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  value: string;
  currentLocale: string;
  placeholder?: HTMLInputElement['placeholder'];
};

export const TradeInput = forwardRef<HTMLInputElement, TradeInputProps>(
  ({ onBlur, onChange, onFocus, value, currentLocale, placeholder }, ref) => {
    const inputClassName = getInputClassNameByAmountLength(value);

    return (
      <div className={inputWrapperAndInputProperStyles}>
        <input
          autoComplete="off"
          aria-label="trade input field"
          data-testid="special-input-field__input"
          ref={ref}
          data-element="input"
          placeholder={placeholder}
          id={`amount-${useId()}`}
          type="text"
          inputMode="decimal"
          value={value}
          lang={currentLocale}
          onChange={onChange}
          onBlur={(e) => onBlur?.(e.target.value)}
          onFocus={onFocus}
          className={inputClassName}
        />
      </div>
    );
  },
);
