/**
 * Processes the application state for a PushTan trade buy operation and redirects based on the state. This is to be used in the ACCEPT TRADE OFFER => BUY flow, in the buy/accept/page.tsx file, as a child of the AcceptTradeController component.
 *
 * @param {TradeBuyApplicationStatePushTan} state - The current state of the PushTan trade buy application.
 * @param {string} assetId - The ID of the asset involved in the trade.
 *
 * If the state is 'declined', the function will redirect to `/trade/buy/${assetId}?applicationState=declined`.
 * If the state is 'failed', the function will redirect to `/trade/buy/${assetId}?applicationState=failed`.
 */
import { redirect } from 'next/navigation';
import { TradeBuyApplicationStatePushTan } from '../../utils/reusableTypes';
import { APPLICATION_STATE_KEY } from './constants';

export const processPushTanApplicationState = (
  state: TradeBuyApplicationStatePushTan,
  assetId: string,
) => {
  if (state === 'declined') {
    redirect(`/trade/buy/${assetId}?${APPLICATION_STATE_KEY}=declined`);
  }

  if (state === 'failed') {
    redirect(`/trade/buy/${assetId}?${APPLICATION_STATE_KEY}=failed`);
  }
};
