export type ReadOnlyTranslations = {
  readOnly: string;
  pendingApproval: string;
  tradingUnavailable: string;
};

export const assetDetailsTranslationsKeys: ReadOnlyTranslations = {
  readOnly: 'user_role_info_read_only',
  pendingApproval: 'user_stage_info_pending_approval',
  tradingUnavailable: 'error_title_trading_unavailable',
};

export const getReadOnlyTranslations = (
  t: (translationKey: keyof ReadOnlyTranslations) => string,
): ReadOnlyTranslations => {
  return Object.entries(assetDetailsTranslationsKeys).reduce(
    (acc, [key, value]) => {
      acc[key as keyof ReadOnlyTranslations] = t(
        value as keyof ReadOnlyTranslations,
      );

      return acc;
    },
    {} as ReadOnlyTranslations,
  );
};
