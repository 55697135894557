'use client';

import { css } from '@bts-web/utils-style-engine';
import { FC, ReactNode, useRef, useState } from 'react';
import { useIsBelowScreenWidth } from '../utils/useIsBelowScreenWidth';
import { useClickAwayListener } from '../../common/components/ClickAwayListener/useClickAwayListener';

export type AssetDetailTradeWrapperProps = {
  AssetDetailsContent: ReactNode;
  TradeStateContent: ReactNode;
  hideMobile: 'tradeElement' | 'assetElement';
  startFocusValue?: boolean;
};

/**
 * `AssetDetailTradeWrapper` is a functional component that wraps the asset details and trade state content.
 *
 * @component
 * @param {object} props - The properties that define the component's behavior and display.
 * @param {ReactNode} props.AssetDetailsContent - The content for the asset details section.
 * @param {ReactNode} props.TradeStateContent - The content for the trade state section.
 * @param {ReactNode} props.startFocusValue - Whether the trade state section should be focused on initial render.
 * @param {'tradeElement' | 'assetElement'} props.hideMobile - Determines which element to hide on mobile view.
 *
 * @example
 * return (
 *   <AssetDetailTradeWrapper
 *     AssetDetailsContent={<AssetDetails />}
 *     TradeStateContent={<TradeState />}
 *     hideMobile="tradeElement"
 *   />
 * );
 *
 * @returns {ReactElement} The rendered `AssetDetailTradeWrapper` component.
 */
export const AssetDetailTradeWrapper: FC<AssetDetailTradeWrapperProps> = ({
  AssetDetailsContent,
  TradeStateContent,
  hideMobile,
  startFocusValue,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const [isTradeFocused, setIsTradeFocused] = useState(
    startFocusValue ?? false,
  );

  const isMobile = useIsBelowScreenWidth(1024);

  const handleClickAway = () => {
    setIsTradeFocused(false);
  };

  useClickAwayListener(ref, handleClickAway);

  return (
    <>
      {isTradeFocused && !isMobile ? (
        <div
          data-testid="trade-overlay"
          aria-label="asset detail trade wrapper overlay"
          className={css({
            background: 'rgba(166, 166, 166, 0.50)',
            position: 'fixed',
            backdropFilter: 'blur(6px)',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 5,
          })}
        />
      ) : null}

      <div
        className={css({
          display: 'flex',
          width: '100%',
        })}
        aria-label="asset detail trade wrapper"
      >
        {isMobile && hideMobile === 'assetElement' ? null : (
          <div
            className={css({
              width: '100%',
            })}
            aria-label="asset detail trade wrapper asset details content"
            data-testid="asset-detail-content"
          >
            {AssetDetailsContent}
          </div>
        )}

        {isMobile && hideMobile === 'tradeElement' ? null : (
          <div
            ref={ref}
            style={{
              maxWidth:
                isMobile && hideMobile === 'assetElement' ? '100%' : '420px',
            }}
            className={css({
              pl: 'extra_small_4',
              zIndex: 6,
              width: '100%',
              position: 'sticky',
              height: '100vh',
              top: {
                lg: 'calc(var(--spacing-extra_small_4) + 72px)',
              },
              maxHeight: {
                base: '100vh',
                lg: 'calc(100vh - var(--spacing-extra_small_4) - 74px)',
              },
              backgroundColor: 'neutrals.widget_primary',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'stretch',
            })}
            onClick={() => {
              setIsTradeFocused(true);
            }}
            aria-label="asset detail trade wrapper trade state content"
            data-testid="trade-state-content"
          >
            <div
              className={css({
                bg: 'neutrals.card_fill_primary',
                display: 'flex',
                flex: 'auto',
                flexDirection: 'column',
              })}
            >
              {TradeStateContent}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
