import { css } from '@bts-web/utils-style-engine';
import React, { FC } from 'react';
import Link from 'next/link';
import { QueryParamBackButton } from './subComponents/QueryParamBackButton/QueryParamBackButton';

interface BreadcrumbItem {
  label: string;
  href?: string;
}

interface TitleString {
  titleType: 'string';
  value?: string;
}

interface TitleBreadcrumbs {
  titleType: 'breadcrumbs';
  value: BreadcrumbItem[];
}

interface TitleReactNode {
  titleType: 'reactNode';
  value: React.ReactNode;
}

export type TitleUnionType = TitleString | TitleBreadcrumbs | TitleReactNode;

export const LayoutTitleElement: FC<{ title: TitleUnionType }> = ({
  title,
}) => {
  if (title.titleType === 'string') {
    return (
      <h1
        className={css({
          color: 'neutrals.text_primary',
        })}
      >
        {title.value}
      </h1>
    );
  }

  if (title.titleType === 'breadcrumbs') {
    return <BreadCrumbWrapper items={title.value} />;
  }

  return null;
};

const SepataratorElement = () => (
  <li
    aria-hidden="true"
    data-testid="separator"
    className={css({ color: 'neutrals.fill_tertiary' })}
  >
    /
  </li>
);

const BreadCrumbWrapper: FC<{
  items: BreadcrumbItem[];
}> = ({ items }) => {
  return (
    <nav>
      <ul
        className={css({
          display: 'flex',
          alignItems: 'center',
          gap: 'extra_small',
          fontSize: 'body.medium',
          p: 0,
          m: 0,
          listStyle: 'none',
        })}
      >
        <QueryParamBackButton />

        {items.map((item, index) => {
          const isLastItem = index === items.length - 1;

          if (item.href === undefined) {
            return (
              <React.Fragment key={`${item.label}-nav-entry-${index}`}>
                <li
                  className={css({
                    borderRadius: '8px',
                    backgroundColor: 'neutrals.fill_quinary',
                    px: 'extra_small_2',
                    py: 'extra_small_4',
                  })}
                >
                  <div>{item.label}</div>
                </li>
                {!isLastItem && (
                  <SepataratorElement key={`${item.label}-separator`} />
                )}
              </React.Fragment>
            );
          }

          return (
            <React.Fragment key={`${item.label}-nav-entry-${index}`}>
              <li
                className={css({
                  px: 'extra_small_2',
                  py: 'extra_small_4',
                })}
              >
                <Link href={item.href}>{item.label}</Link>
              </li>
              {!isLastItem && (
                <SepataratorElement key={`${item.label}-separator`} />
              )}
            </React.Fragment>
          );
        })}
      </ul>
    </nav>
  );
};
