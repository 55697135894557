/**
 * @generated SignedSource<<4daf1678efb0930e33888f3d6c7c7758>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CorporateOnboardingRiskQuestionsQuery$variables = {};
export type CorporateOnboardingRiskQuestionsQuery$data = {
  readonly riskQuestions: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly __typename: string;
        readonly answers: ReadonlyArray<{
          readonly __typename: "RiskAnswer";
          readonly answer: string;
          readonly id: string;
          readonly order: number;
          readonly translationKey: string;
        }>;
        readonly id: string;
        readonly order: number;
        readonly question: string;
        readonly translationKey: string;
      } | null;
    } | null> | null;
  } | null;
  readonly termsAndConditions: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
      } | null;
    } | null> | null;
  } | null;
};
export type CorporateOnboardingRiskQuestionsQuery = {
  response: CorporateOnboardingRiskQuestionsQuery$data;
  variables: CorporateOnboardingRiskQuestionsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "translationKey",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "order",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "input",
        "value": {
          "first": 100
        }
      }
    ],
    "concreteType": "TermsAndConditionsConnection",
    "kind": "LinkedField",
    "name": "termsAndConditions",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "TermsAndConditionsEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TermsAndConditions",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": "termsAndConditions(input:{\"first\":100})"
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "input",
        "value": {
          "first": 200
        }
      }
    ],
    "concreteType": "RiskQuestionConnection",
    "kind": "LinkedField",
    "name": "riskQuestions",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "RiskQuestionEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "question",
                "storageKey": null
              },
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "RiskAnswer",
                "kind": "LinkedField",
                "name": "answers",
                "plural": true,
                "selections": [
                  (v0/*: any*/),
                  (v2/*: any*/),
                  (v1/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "answer",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": "riskQuestions(input:{\"first\":200})"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CorporateOnboardingRiskQuestionsQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "CorporateOnboardingRiskQuestionsQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "6c926d00cd0c1cdf6b66c0285146e09e",
    "id": null,
    "metadata": {},
    "name": "CorporateOnboardingRiskQuestionsQuery",
    "operationKind": "query",
    "text": "query CorporateOnboardingRiskQuestionsQuery {\n  termsAndConditions(input: {first: 100}) {\n    edges {\n      node {\n        id\n      }\n    }\n  }\n  riskQuestions(input: {first: 200}) {\n    edges {\n      node {\n        id\n        question\n        translationKey\n        order\n        answers {\n          id\n          order\n          translationKey\n          __typename\n          answer\n        }\n        __typename\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1917ad44c70e09b4de6cd06a36996cb8";

export default node;
