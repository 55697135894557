'use client';

import { useSearchParams, useRouter } from 'next/navigation';
import { FC, useEffect } from 'react';
import { verifyLinkKey } from '../constants';

/**
 * `TradeFormMountChecker` is a React component used when a user navigates to a page
 * that has verification search parameters in the URL, but the input form is empty.
 *
 * If the `verifyLinkKey` search parameter is present and the `formValue` prop (representing the input form value) is not empty,
 * the component removes the `verifyLinkKey` search parameter from the URL.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.formValue - The value of the form input.
 * @returns {null} The component does not render anything.
 */
export const TradeFormMountChecker: FC<{ formValue: string }> = ({
  formValue,
}) => {
  const router = useRouter();

  const searchParams = useSearchParams();

  useEffect(() => {
    const isVerifyStep = searchParams.get(verifyLinkKey);

    if (isVerifyStep && formValue.length === 0) {
      router.back();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
