/**
 * @generated SignedSource<<abd788d8bf8e9c7fdd79829d7574a483>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Appropriateness = "APPROPRIATE" | "NOT_APPROPRIATE" | "NOT_EXPERIENCED" | "UNKNOWN";
export type OnboardUserInput = {
  aml: ReadonlyArray<UserAML>;
  termsAndConditions: ReadonlyArray<string>;
};
export type UserAML = {
  answerId: string;
  questionId: string;
};
export type OnboardUserMutation$variables = {
  input?: OnboardUserInput | null;
};
export type OnboardUserMutation$data = {
  readonly onboardUser: {
    readonly accountLevelId?: string;
    readonly active?: boolean;
    readonly appropriateness?: Appropriateness | null;
    readonly appropriatenessRequiresTestCompletion?: boolean | null;
    readonly appropriatenessRequiresTestResubmission?: boolean | null;
    readonly blacklisted?: boolean;
    readonly id: string;
    readonly onboarded: boolean;
    readonly providerActivated?: boolean;
    readonly tradingActive?: boolean;
    readonly tradingDisabledReason?: string | null;
    readonly verificationReason?: string | null;
    readonly verificationStatus?: string;
    readonly verified?: boolean;
  } | null;
};
export type OnboardUserMutation = {
  response: OnboardUserMutation$data;
  variables: OnboardUserMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "onboarded",
  "storageKey": null
},
v4 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "providerActivated",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appropriateness",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appropriatenessRequiresTestCompletion",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appropriatenessRequiresTestResubmission",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "active",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "blacklisted",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "verified",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "verificationStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "verificationReason",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accountLevelId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tradingActive",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tradingDisabledReason",
      "storageKey": null
    }
  ],
  "type": "IndividualUser",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OnboardUserMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "onboardUser",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OnboardUserMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "onboardUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "03fead0ccb73df243c035437b4dbcae6",
    "id": null,
    "metadata": {},
    "name": "OnboardUserMutation",
    "operationKind": "mutation",
    "text": "mutation OnboardUserMutation(\n  $input: OnboardUserInput\n) {\n  onboardUser(input: $input) {\n    __typename\n    id\n    onboarded\n    ... on IndividualUser {\n      providerActivated\n      appropriateness\n      appropriatenessRequiresTestCompletion\n      appropriatenessRequiresTestResubmission\n      active\n      blacklisted\n      verified\n      verificationStatus\n      verificationReason\n      accountLevelId\n      tradingActive\n      tradingDisabledReason\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3af057e1f9b540111935d366bd7e8b06";

export default node;
