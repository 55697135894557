/**
 * @generated SignedSource<<a866d70efec7e42a01437fd10dc7707b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type PortfolioPeriod = "DAY" | "MAX" | "MONTH" | "SIX_MONTHS" | "WEEK" | "YEAR";
export type PortfolioViewQuery$variables = {
  period: PortfolioPeriod;
};
export type PortfolioViewQuery$data = {
  readonly portfolio: {
    readonly assetGroups: {
      readonly edges: ReadonlyArray<{
        readonly fiatBalance: PrecisionFloat;
        readonly name: string;
        readonly node: ReadonlyArray<{
          readonly edges: ReadonlyArray<{
            readonly assetBalance: PrecisionFloat | null;
            readonly fiatBalance: PrecisionFloat | null;
            readonly node: {
              readonly id: string;
              readonly logoUrl: string | null;
              readonly name: string | null;
              readonly price: PrecisionFloat | null;
              readonly symbol: string | null;
            } | null;
            readonly totalReturn: ValueChange | null;
          } | null> | null;
          readonly totalCount: number | null;
        }> | null;
        readonly order: number | null;
        readonly totalReturn: ValueChange;
      }> | null;
    } | null;
    readonly historicalData: ReadonlyArray<{
      readonly period: string | null;
      readonly value: PrecisionFloat | null;
    } | null> | null;
    readonly overallAmount: PrecisionFloat | null;
    readonly returnPercentage: number | null;
  } | null;
};
export type PortfolioViewQuery = {
  response: PortfolioViewQuery$data;
  variables: PortfolioViewQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "period"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "period",
        "variableName": "period"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "overallAmount",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "returnPercentage",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "order",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalReturn",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fiatBalance",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "assetBalance",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "symbol",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "logoUrl",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "HistoricalValue",
  "kind": "LinkedField",
  "name": "historicalData",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "value",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "period",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PortfolioViewQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Portfolio",
        "kind": "LinkedField",
        "name": "portfolio",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "PortfolioAssetGroupsConnection",
            "kind": "LinkedField",
            "name": "assetGroups",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PortfolioAssetGroupEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PortfolioAssetsConnection",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": true,
                    "selections": [
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PortfolioAssetsEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v9/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v10/*: any*/),
                              (v4/*: any*/),
                              (v11/*: any*/),
                              (v12/*: any*/),
                              (v13/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v14/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PortfolioViewQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Portfolio",
        "kind": "LinkedField",
        "name": "portfolio",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "PortfolioAssetGroupsConnection",
            "kind": "LinkedField",
            "name": "assetGroups",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PortfolioAssetGroupEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PortfolioAssetsConnection",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": true,
                    "selections": [
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PortfolioAssetsEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v9/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "__typename",
                                "storageKey": null
                              },
                              (v10/*: any*/),
                              (v4/*: any*/),
                              (v11/*: any*/),
                              (v12/*: any*/),
                              (v13/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v14/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ba923e19de87cd171fc4bb1d9490c86f",
    "id": null,
    "metadata": {},
    "name": "PortfolioViewQuery",
    "operationKind": "query",
    "text": "query PortfolioViewQuery(\n  $period: PortfolioPeriod!\n) {\n  portfolio(input: {period: $period}) {\n    overallAmount\n    returnPercentage\n    assetGroups {\n      edges {\n        name\n        order\n        totalReturn\n        fiatBalance\n        node {\n          totalCount\n          edges {\n            totalReturn\n            fiatBalance\n            assetBalance\n            node {\n              __typename\n              id\n              name\n              symbol\n              logoUrl\n              price\n            }\n          }\n        }\n      }\n    }\n    historicalData {\n      value\n      period\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c9ef79962bb428588c3a61ec23fdfb29";

export default node;
