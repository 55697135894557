/**
 * @generated SignedSource<<f7e16682f49fb5fe31c90f5da6776568>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateCryptoAddressInput = {
  networkId: string;
};
export type CreateCryptoAddressMutation$variables = {
  input?: CreateCryptoAddressInput | null;
};
export type CreateCryptoAddressMutation$data = {
  readonly createCryptoAddress: {
    readonly address: string;
    readonly createdAt: any;
    readonly id: string;
  } | null;
};
export type CreateCryptoAddressMutation = {
  response: CreateCryptoAddressMutation$data;
  variables: CreateCryptoAddressMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CryptoAddress",
    "kind": "LinkedField",
    "name": "createCryptoAddress",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "address",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdAt",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateCryptoAddressMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateCryptoAddressMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f84c00d5bd01cc7ede31d5877b42cfdc",
    "id": null,
    "metadata": {},
    "name": "CreateCryptoAddressMutation",
    "operationKind": "mutation",
    "text": "mutation CreateCryptoAddressMutation(\n  $input: CreateCryptoAddressInput\n) {\n  createCryptoAddress(input: $input) {\n    id\n    address\n    createdAt\n  }\n}\n"
  }
};
})();

(node as any).hash = "f9fecd5ce3f5a4aaeffd4b4df626e893";

export default node;
