'use client';

import { useRouter, useSearchParams } from 'next/navigation';

export const useRedirectBasedOnRefQueryParam = () => {
  const router = useRouter();

  const searchParams = useSearchParams();

  return {
    redirectBasedOnRefQueryParam: () => {
      const comingFrom = searchParams.get('ref');

      if (comingFrom === 'portfolio') {
        router.push('/assets/portfolio');
      } else if (comingFrom === 'prices') {
        const appliedFilter = searchParams.get('assetType');

        if (appliedFilter) {
          router.push(`/assets/discover?assetType=${appliedFilter}`);
        } else {
          router.push('/assets/discover');
        }
      } else {
        router.back();
      }
    },
  };
};
