'use client';

import { stack, css, ConditionalValue } from '@bts-web/utils-style-engine';
import { DialogProps } from '@bts-web/design-system/component/dialog';
import { useRouter } from 'next/navigation';
import { ModalComponent } from '@bts-web/utils-context';
import { Icon } from '@bts-web/design-system/component/icon';
import { useClientTranslation } from '@bts-web/utils-lokalise';
import { getUserAccessModalTranslations } from './getUserAccessModalTranslations';
import { AccessModalInfoType, getAccessModalInfo } from './getAccessModalInfo';
import { ButtonBase } from '../ButtonBase/ButtonBase';
import { DialogBase } from '../DialogBase/DialogBase';

export type DialogComponentProps = Omit<
  DialogProps['external'],
  'closeIcon'
> & {
  visual?: ConditionalValue<'primary' | 'secondary'> | undefined;
  size?: ConditionalValue<'small' | 'fullscreen'> | undefined;
};

const modalWrapStyles = stack({
  gap: 'large',
  px: 'extra_large_4',
  py: 'small',
  alignItems: 'center',
  textAlign: 'center',
});

const modalContentStyles = stack({
  gap: 'extra_small_2',
  textAlign: 'center',
  alignItems: 'center',
});

const titleStyles = css({
  fontSize: 'headline.large_semi_bold',
  fontWeight: 'headline.large_semi_bold',
  lineHeight: 'headline.large_semi_bold',
  letterSpacing: 'headline.large_semi_bold',
});

const messageStyles = css({
  color: 'neutrals.text_secondary',
  fontSize: 'body.medium',
  fontWeight: 'body.medium',
  letterSpacing: 'body.medium',
  lineHeight: 'body.medium',
});

const modalIconStyles = css({
  '&[data-element="icon-warning"]': {
    color: 'warning.fill_primary',
  },
  '&[data-element="icon-info"]': {
    color: 'positive.fill_primary',
  },
});

export type UserAccesModalProps = {
  type: AccessModalInfoType;
};

export const UserAccessModal: ModalComponent<UserAccesModalProps> = ({
  type,
  onClose,
}) => {
  const router = useRouter();

  const { t } = useClientTranslation();

  const translations = getUserAccessModalTranslations(
    t as (key: string) => string,
  );

  const { icon, title, message, action } = getAccessModalInfo(
    translations,
    type,
  );

  const handleClick = () => {
    onClose();

    if (action.to) {
      router.push(action?.to);
    }
  };

  return (
    <DialogBase
      id="base-modal"
      open
      onOpenChange={onClose}
      size="small"
      visual="secondary"
      closeIcon={null}
      disableBackdropClose
    >
      <div className={modalWrapStyles}>
        <div className={modalContentStyles}>
          {icon?.name && (
            <Icon
              icon={icon?.name}
              size="48"
              theme="regular"
              data-element={`icon-${icon?.type}`}
              className={modalIconStyles}
            />
          )}
          <div className={stack({ gap: 'medium' })}>
            <h1 className={titleStyles}>{title}</h1>
            <div className={messageStyles}>{message}</div>
          </div>
        </div>

        {action &&
          (action?.component || (
            <div
              className={css({
                maxWidth: '342px',
                width: '100%',
              })}
            >
              <ButtonBase onClick={handleClick} visual="accent">
                {action?.label}
              </ButtonBase>
            </div>
          ))}
      </div>
    </DialogBase>
  );
};
