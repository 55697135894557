import {
  ListCellsSelectionItemContainer as DesignListCellsSelectionItemContainer,
  ListCellsSelectionItemContainerProps,
} from '@bts-web/design-system/component/list-cells-selection';
import { css } from '@bts-web/utils-style-engine';
import { dividerStyles, transparentDividerStyles } from './styles';

const displayFlexWithCenteredItems = {
  display: 'flex',
  alignItems: 'center',
};

const labelLargeTypography = {
  fontSize: 'label.large',
  fontWeight: 'label.large',
  lineHeight: 'label.large',
  letterSpacing: 'label.large',
};

const captionMediumTypography = {
  fontSize: 'caption.medium_medium',
  fontWeight: 'caption.medium_medium',
  lineHeight: 'caption.medium_medium',
  letterSpacing: 'caption.medium_medium',
};

const ListCellsSelectionItemContainer = ({
  activeItemId,
  children,
  isDisabled = false,
  separator = true,
  itemId,
  icon,
  label,
  subLabel,
}: ListCellsSelectionItemContainerProps & {
  activeItemId: string;
  isDisabled?: boolean;
  separator?: boolean;
}) => {
  const visual = isDisabled
    ? 'disabled'
    : activeItemId === itemId
      ? 'active'
      : 'default';

  return (
    <>
      <DesignListCellsSelectionItemContainer
        className={css({
          ...displayFlexWithCenteredItems,
          '&:hover, &:focus': {
            backgroundColor: 'neutrals.fill_focused',
          },
          '& > label': {
            display: 'flex',
            flexDirection: 'column',
            gap: 'extra_small_4',
            width: '100%',
            '& > span:first-child': {
              ...displayFlexWithCenteredItems,
              ...labelLargeTypography,
              flexGrow: 1,
              gap: 'small',
            },
            '& > span:nth-child(2)': {
              ...captionMediumTypography,
            },
          },
          '& > label, & > .item-slot': {
            padding: 'medium',
          },
          ...(visual === 'default'
            ? {
                background: 'transparent',
                color: 'neutrals.text_primary',
              }
            : {}),
          ...(visual === 'active'
            ? {
                backgroundColor: 'neutrals.fill_focused',
                color: 'neutrals.text_primary',
              }
            : {}),
          ...(visual === 'disabled'
            ? {
                background: 'transparent',
                color: 'neutrals.text_disabled',
              }
            : {}),
        })}
        icon={icon}
        itemId={itemId}
        label={label}
        subLabel={subLabel}
      >
        {children}
      </DesignListCellsSelectionItemContainer>
      {separator && (
        <div
          className={
            activeItemId !== itemId ? dividerStyles : transparentDividerStyles
          }
        />
      )}
    </>
  );
};

export { ListCellsSelectionItemContainer };
