import { css } from '@bts-web/utils-style-engine';

export const submitButtonWrapper = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 'extra_small_4',
  width: '100%',
  textAlign: 'center',
  pt: 'extra_small',
  pb: 'medium',
  mt: 'auto',
};

export const submitButtonWrapperAndButtonProperStyles = css({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  mx: 'auto',
  maxWidth: '440px',
});

export const inputWrapperAndInputProperStyles = css({
  width: '100%',
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  '& .cursorPointer': {
    cursor: 'pointer',
    minWidth: '0',
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  '& .placeHolderFiller': {
    color: 'neutrals.fill_quinary',
  },
  '& input, & > div': {
    width: '100%',
    height: '46px',
    color: 'neutrals.text_primary',
    caretColor: 'neutrals.text_primary',
    '&::placeholder': {
      color: 'neutrals.fill_quinary',
    },

    _focus: {
      border: 'none',
      outline: 'none',
    },

    _disabled: {
      background: 'none',
    },
  },
  '& .large-input-font': {
    fontWeight: 'display.medium',
    letterSpacing: 'display.medium',
    lineHeight: 'display.medium',
    fontSize: { base: '1.6rem', lg: 'display.medium' },
  },
  '& .medium-input-font': {
    fontSize: 'headline.large',
    fontWeight: 'headline.large',
    letterSpacing: 'headline.large',
    lineHeight: 'headline.large',
  },
  '& .small-input-font': {
    fontSize: 'headline.medium',
    fontWeight: 'headline.medium',
    letterSpacing: 'headline.medium',
    lineHeight: 'headline.medium',
  },
  '& .ellipsis-input-font': {
    fontSize: 'headline.medium',
    fontWeight: 'headline.medium',
    letterSpacing: 'headline.medium',
    lineHeight: 'headline.medium',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
});
