'use client';

import {
  Indicator,
  RadioGroupIndicatorProps,
} from '@radix-ui/react-radio-group';

export interface ListCellsSelectionIndicatorProps
  extends RadioGroupIndicatorProps {
  className?: string;
}

function ListCellsSelectionIndicator({
  className,
  ...rest
}: ListCellsSelectionIndicatorProps) {
  return (
    <Indicator
      data-testid="list-cells-selection-indicator"
      {...{ className, ...rest }}
    ></Indicator>
  );
}

export { ListCellsSelectionIndicator };
