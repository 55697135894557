import { TFunction } from 'i18next';
import { ITopDesktopHeaderBarTranslatedValues } from '../types';

const desktopHeaderBarTranslations: ITopDesktopHeaderBarTranslatedValues = {
  profile: 'general_profile',
  customer_support: 'customer_support',
  frequently_asked_questions: 'frequently_asked_questions',
  legal_notice: 'legal_notice',
  general_logout: 'general_logout',
  terms_and_conditions: 'terms_and_conditions',
};

export const getDesktopHeaderBarTranslations = (
  transUtil: TFunction,
): ITopDesktopHeaderBarTranslatedValues => {
  return Object.entries(desktopHeaderBarTranslations).reduce(
    (acc, [key, value]) => {
      acc[key as keyof ITopDesktopHeaderBarTranslatedValues] = transUtil(value);

      return acc;
    },
    {} as ITopDesktopHeaderBarTranslatedValues,
  );
};
