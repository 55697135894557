import { FC } from 'react';
import { css } from '@bts-web/utils-style-engine';
import {
  PrecisionFloat,
  getPrecisionFloatValue,
} from '@bts-web/utils-formatting';
import { TradeScreenTranslations } from '../../utils';
import { TaxInformationSheetWrapper } from '../../AcceptTradeParent/TaxInformationSheetWrapper/TaxInformationSheetWrapper';

const taxBoxPrimaryContainerStyles = css({
  display: 'flex',
  flexDirection: 'column',
  gap: 'extra_small',
  alignItems: 'flex-start',
  padding: 'small',
  borderWidth: '2px',
  borderStyle: 'solid',
  borderColor: 'warning.stroke_primary',
  borderRadius: 8,
  textAlign: 'left',
});

const taxTitleStyles = css({
  color: 'warning.text_primary',
  fontSize: 'label.medium_medium',
  fontWeight: 'label.medium_medium',
  lineHeight: 'label.medium_medium',
  letterSpacing: 'label.medium_medium',
});

const taxSubtitleStyles = css({
  color: 'neutrals.text_primary',
  fontSize: 'body.medium',
  fontWeight: 'body.medium',
  lineHeight: 'body.medium',
  letterSpacing: 'body.medium',
});

const summaryBoxPrimaryContainerStyles = css({
  display: 'flex',
  color: 'neutrals.text_primary',
  fontSize: 'headline.small_medium',
  fontWeight: 'headline.small_medium',
  lineHeight: 'headline.small_medium',
  letterSpacing: 'headline.small_medium',
  justifyContent: 'space-between',
});

const summaryBoxSecondaryContainerStyles = css({
  display: 'flex',
  color: 'neutrals.text_secondary',
  fontSize: 'body.medium',
  fontWeight: 'body.medium',
  letterSpacing: 'body.medium',
  lineHeight: 'body.medium',
  justifyContent: 'space-between',
});

const summaryBoxWarningContainerStyles = css({
  display: 'flex',
  color: 'neutrals.text_secondary',
  fontSize: 'body.medium',
  fontWeight: 'body.medium',
  letterSpacing: 'body.medium',
  lineHeight: 'body.medium',
  justifyContent: 'space-between',
  '& [data-element="currency"]': {
    color: 'warning.text_primary',
  },
});

const getSummaryBoxClass = (variant: VariantTypes) => {
  switch (variant) {
    case 'primary':
      return summaryBoxPrimaryContainerStyles;

    case 'warning':
      return summaryBoxSecondaryContainerStyles;

    default:
      return summaryBoxWarningContainerStyles;
  }
};

interface TradeSummaryItemProps {
  label?: string;
  amount: PrecisionFloat;
  variant: VariantTypes;
  currency?: string;
  locale: string;
  approximateSymbol?: string;
}

export const TradeSummaryItem = ({
  variant,
  label,
  amount,
  currency,
  locale,
  approximateSymbol,
}: TradeSummaryItemProps) => {
  const amountValue = getPrecisionFloatValue(amount);

  const localeFormattedCurrency = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  }).format(Number(amountValue));

  return (
    <div
      data-testid="trade-summary-item"
      className={getSummaryBoxClass(variant)}
    >
      <div>{label}</div>
      <div data-element="currency">
        {approximateSymbol}
        {localeFormattedCurrency}
      </div>
    </div>
  );
};

type VariantTypes = 'secondary' | 'primary' | 'warning';

type TradeTaxSummaryProps = {
  currency: string;
  isTaxServiceOffline?: boolean;
  translations: TradeScreenTranslations;
  currentLocale: string;
  taxAmount: PrecisionFloat;
  fiatAmount: PrecisionFloat;
};

export const TradeTaxSummary: FC<TradeTaxSummaryProps> = ({
  currency,
  currentLocale,
  fiatAmount,
  isTaxServiceOffline,
  taxAmount,
  translations,
}) => {
  return (
    <div
      className={css({
        p: 'medium',
        mb: 'auto',
      })}
      aria-label="trade-tax-summary-container"
    >
      <div
        className={css({
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          gap: 0,
          borderRadius: 8,
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: 'neutrals.stroke_secondary',
        })}
      >
        <div
          className={css({
            display: 'flex',
            flexDirection: 'column',
            gap: 'extra_small',
            p: 'medium',
          })}
        >
          <TradeSummaryItem
            variant={isTaxServiceOffline ? 'warning' : 'secondary'}
            label={translations.taxWithHeld}
            amount={taxAmount}
            locale={currentLocale}
            currency={currency}
            approximateSymbol="≈ "
          />

          <div
            className={css({
              display: 'flex',
              flexDirection: 'column',
              gap: 'small',
            })}
          >
            {isTaxServiceOffline && (
              <div className={taxBoxPrimaryContainerStyles}>
                <p className={taxTitleStyles}>
                  {translations.taxSystemOfflineWarningTitle}
                </p>
                <p className={taxSubtitleStyles}>
                  {translations.taxSystemOfflineWarningSubtitle}
                </p>
              </div>
            )}
            <TradeSummaryItem
              variant="primary"
              label={translations.youRecieve}
              amount={fiatAmount}
              locale={currentLocale}
              currency={currency}
              approximateSymbol="≈ "
            />
          </div>
        </div>
        <TaxInformationSheetWrapper
          aboutTaxTextLink={translations.aboutTaxTextLink}
          taxInfoText1={translations.cryptoTaxInfoText1}
          taxInfoText2={translations.cryptoTaxInfoText2}
          taxTitle={translations.cryptoTaxInfoTitle}
        />
      </div>
    </div>
  );
};
