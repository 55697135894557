import { AmountFor } from '@bts-web/data-layer/server';
import { AssetType } from './reusableTypes';
import {
  MaximumFractionDigits,
  PrecisionFloat,
  getRoundedPrecision,
  getTargetAmount,
  intlAssetValueFormatting,
} from '@bts-web/utils-formatting';

export const isBpfsAsset = (assetType: AssetType) =>
  [AssetType.ETF, AssetType.ETC, AssetType.STOCK].includes(assetType);

export const getInputClassNameByAmountLength = (amount: string): string => {
  if (amount && amount.length >= 16) {
    return 'ellipsis-input-font';
  }

  if (amount.length > 14) {
    return 'small-input-font';
  }

  if (amount.length >= 8) {
    return 'medium-input-font';
  }

  return 'large-input-font';
};

export const valueFromFiatToAsset = (
  amount: string,
  currentPrice: PrecisionFloat,
  customPrecision?: number,
): NonNullable<PrecisionFloat> => {
  const decimalSeparator = amount.includes(',') ? ',' : '.';

  const parsedValue = getTargetAmount({
    targetType: 'ASSET',
    price: currentPrice,
    sourceAmount: {
      value: amount.replace(decimalSeparator, '.'),
      precision: MaximumFractionDigits.ASSET,
    },
  });

  if (!parsedValue) {
    return {
      value: '',
      precision: customPrecision ?? MaximumFractionDigits.ASSET,
    };
  }

  return {
    value: getRoundedPrecision({
      value: parsedValue?.value ?? '',
      precision: customPrecision ?? MaximumFractionDigits.ASSET,
    }).replace('.', decimalSeparator),
    precision: parsedValue.precision,
  };
};

export const valueFromAssetToFiat = (
  amount: string,
  currentPrice: PrecisionFloat,
) => {
  const decimalSeparator = amount.includes(',') ? ',' : '.';

  const parsedValue = getTargetAmount({
    targetType: 'FIAT',
    price: currentPrice,
    sourceAmount: {
      value: amount.replace(decimalSeparator, '.'),
      precision: MaximumFractionDigits.FIAT,
    },
  });

  if (!parsedValue) {
    return;
  }

  return {
    value: parsedValue.value.replace('.', decimalSeparator),
    precision: parsedValue.precision,
  };
};

export const getDisplayValueFromSource = ({
  sourceType,
  currency,
  locale,
  sourceAmount,
  customSymbol,
}: {
  sourceAmount: string;
  sourceType: AmountFor;
  currency: Parameters<typeof intlAssetValueFormatting>['1']['currency'];
  locale: Parameters<typeof intlAssetValueFormatting>['1']['locale'];
  customSymbol?: string;
}) => {
  const isFiat = sourceType === 'FIAT';

  const finalMaximumFractionDigits =
    sourceType === 'ASSET'
      ? MaximumFractionDigits.ASSET
      : MaximumFractionDigits.FIAT;

  const computedValue = intlAssetValueFormatting(sourceAmount ?? '0', {
    locale: locale,
    minimumFractionDigits: isFiat ? 2 : undefined,
    maximumFractionDigits: finalMaximumFractionDigits,
    currency: isFiat ? currency : undefined,
    notation: isFiat ? 'standard' : undefined,
  });

  return `${computedValue} ${!isFiat ? (customSymbol ?? '') : ''}`;
};
