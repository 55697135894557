'use client';

import { css } from '@bts-web/utils-style-engine';
import Link from 'next/link';
import { FC, ReactNode } from 'react';

type BaseDocumentationExternalLinkProps = {
  href: string;
  download?: string;
  size?: 'small';
  // this is to be used when the target site has 'Refused to display 'URL' in a frame because it set 'X-Frame-Options' to 'sameorigin'.' so we open the link in a new tab
  useRawOpenUrl?: boolean;
};

type DocumentationExternalLinkProps =
  | (BaseDocumentationExternalLinkProps & {
      label: string;
      children?: never;
    })
  | (BaseDocumentationExternalLinkProps & {
      label?: never;
      children: ReactNode;
    });

const DocumentationExternalLink: FC<DocumentationExternalLinkProps> = ({
  label,
  href,
  download,
  children,
  useRawOpenUrl,
  size,
}) => {
  const route = `/document?documentUrl=${href}&documentTitle=${label ? label : typeof children === 'string' ? children : ''}`;

  const downloadLinkProps = { href, download };

  const navigationLinkProps = { href: route, passHref: true };

  const externalLinkProps = { href, target: '_blank' };

  const props = download
    ? downloadLinkProps
    : useRawOpenUrl
      ? externalLinkProps
      : navigationLinkProps;

  const Element = useRawOpenUrl || download ? 'a' : Link;

  return (
    <Element
      {...props}
      aria-label={`external-link`}
      className={css({
        textDecoration: 'underline',
        display: 'inline-flex',
        alignItems: 'center',
        flexShrink: 0,
        cursor: 'pointer',
        gap: 'extra_small_2',
        textUnderlineOffset: '4px',
        fontWeight: '500',
        width: 'fit-content',
        color: 'inherit',
        fontFamily: '12312312',
        _hover: {
          color: 'neutrals.fill_pressed',
        },
        _disabled: {
          color: 'neutrals.text_disabled',
          cursor: 'default',
        },
        ...(size === 'small'
          ? {
              fontSize: '11px',
            }
          : {}),
      })}
      data-testid="documentation-external-link"
      role="link"
      tabIndex={0}
    >
      {label || children}
    </Element>
  );
};

export { DocumentationExternalLink };
