'use client';

import { ReactElement } from 'react';
import { css } from '@bts-web/utils-style-engine';

interface TradeOptionButtonProps {
  title: string;
  icon: ReactElement;
  onClick: () => void;
}

const buttonTitleStyles = css({
  color: 'neutrals.text_primary',
  fontSize: 'label.medium_medium',
  fontWeight: 'label.medium_medium',
  letterSpacing: 'label.medium_medium',
  lineHeight: 'label.medium_medium',
});

const iconContainerStyles = css({
  display: 'flex',
  backgroundColor: 'neutrals.text_primary_inverted',
  width: '32px',
  height: '32px',
  borderRadius: '100px',
  justifyContent: 'center',
  alignItems: 'center',
});

const buttonStyles = css({
  display: 'flex',
  gap: 'small',
  alignItems: 'center',
  cursor: 'pointer',
  padding: 'medium',
  borderBottomWidth: '1px',
  borderBottomColor: 'neutrals.divider',
  '&:active': {
    background: 'rgba(1,1,1,0.1)',
  },
});

const TradeOptionButton = ({
  title,
  icon,
  onClick,
}: TradeOptionButtonProps) => (
  <button
    className={buttonStyles}
    data-testid={`trade-btn-${title.replace(/ /g, '').toLowerCase()}`}
    onClick={onClick}
    aria-label={`trade button ${title.replace(/ /g, '').toLowerCase()}`}
  >
    <div className={iconContainerStyles}>{icon}</div>

    <div
      className={css({ display: 'flex', gap: '2px', alignItems: 'flex-start' })}
    >
      <span className={buttonTitleStyles}>{title}</span>
    </div>
  </button>
);

export { TradeOptionButton };
