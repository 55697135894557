// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Args = any[];

const throttle = (callback: (...args: Args) => void, delay: number) => {
  let isRunning = false;

  return (...args: Args) => {
    if (!isRunning) {
      isRunning = true;

      callback.apply({}, args);

      setTimeout(() => {
        isRunning = false;
      }, delay);
    }
  };
};

export { throttle };
