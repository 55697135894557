'use client';

import React, { useRef, useEffect } from 'react';

interface ClickAwayListenerProps {
  onClickAway: (event: MouseEvent | TouchEvent) => void;
  children: React.ReactNode;
}

const ClickAwayListener: React.FC<ClickAwayListenerProps> = ({
  onClickAway,
  children,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickAway = (event: MouseEvent | TouchEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onClickAway(event);
      }
    };

    document.addEventListener('mousedown', handleClickAway);

    document.addEventListener('touchstart', handleClickAway);

    return () => {
      document.removeEventListener('mousedown', handleClickAway);

      document.removeEventListener('touchstart', handleClickAway);
    };
  }, [onClickAway]);

  return <div ref={ref}>{children}</div>;
};

export { ClickAwayListener };
