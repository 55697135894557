import { FC } from 'react';
import {
  ReadMore as ReadMoreDesign,
  ReadMoreProps,
} from '@bts-web/design-system/component/read-more';
import { css } from '@bts-web/utils-style-engine';

const ReadMore: FC<ReadMoreProps['external']> = (props) => {
  return (
    <ReadMoreDesign
      className={css({
        color: 'neutrals.text_primary',
        fontSize: 'body.medium',
        fontWeight: 'body.medium',
        lineHeight: 'body.medium',
        '& [aria-expanded]': {
          color: 'brand.text_primary',
          cursor: 'pointer',
          fontSize: 'body.medium_medium',
          fontWeight: 'body.medium_medium',
          lineHeight: 'body.medium_medium',
        },
      })}
      {...props}
    />
  );
};

export { ReadMore };
