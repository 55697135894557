import { css } from '@bts-web/utils-style-engine';
import { FC, PropsWithChildren } from 'react';

export const HiddenDesktop: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div
      data-testid="hidden-desktop-util"
      className={css({
        hideFrom: 'lg',
      })}
    >
      {children}
    </div>
  );
};
