import { css } from '@bts-web/utils-style-engine';
import {
  ListCellsSelectionIndicator as DesignListCellsSelectionIndicator,
  ListCellsSelectionIndicatorProps,
} from '@bts-web/design-system/component/list-cells-selection';

export type ListCellsSelectionIndicatorStylesVariant = {
  visual: 'checkmark';
};

const ThemedListCellsSelectionIndicator = ({
  visual = 'checkmark',
  ...rest
}: ListCellsSelectionIndicatorProps &
  ListCellsSelectionIndicatorStylesVariant) => {
  return (
    <DesignListCellsSelectionIndicator
      {...rest}
      className={css({
        ...(visual === 'checkmark'
          ? {
              display: 'block',
              width: '8px',
              height: '18px',
              transform: 'rotate(45deg)',
              borderBottom: '2px solid black',
              borderRight: '2px solid black',
            }
          : {}),
      })}
    />
  );
};

export { ThemedListCellsSelectionIndicator as ListCellsSelectionIndicator };
