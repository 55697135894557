// ***************** NOTE HERE ***************** //
// --- use star exports here otherwise jest will not be able to mock the functions --- //
// ***************** NOTE HERE ***************** //

export * from './QuestionsContext/QuestionContextProviderFactory.use-client';

export {
  AmlQuestionContextProvider,
  useAmlQuestionContext,
} from './QuestionsContext/AmlQuestionsContext/AmlQuestionContext.use-client';

export {
  AppropriatenessQuestionContextProvider,
  useAppropriatenessQuestionContext,
} from './QuestionsContext/ApropriatenessQuestionsContext/AppropriatenessQuestionContext.use-client';

export * from './PortfolioContext/PortfolioContext.use-client';

export * from './ComposableModalContext/ComposableModalContext';

export * from './TradeContext/TradeContext';

export * from './AssetsPriceContext/AssetsPriceContext.use-client';

export * from './TradeContext/utils/tests/TradeContextProvider.mock';

export * from './AssetDetailsDataContext/AssetDetailsDataContext.use-client';
