import {
  ProgressBar as DesignProgressBar,
  ProgressBarProps,
} from '@bts-web/design-system/component/progress-bar';
import { css } from '@bts-web/utils-style-engine';
import { FC } from 'react';

export type ThemedProgressBarProps = ProgressBarProps['external'] & {
  isGreen?: boolean;
  isLoading?: boolean;
};

const ProgressBar: FC<ThemedProgressBarProps> = ({
  progress,
  isGreen,
  isLoading,
}) => {
  return (
    <DesignProgressBar
      className={css({
        height: '4px',
        overflow: 'hidden',
        position: 'relative',
        ...(!isLoading
          ? { backgroundColor: 'neutrals.fill_disabled', borderRadius: '20px' }
          : {}),
      })}
      primitiveStyles={{
        indicatorStyle: css({
          borderRadius: '1px',
          width: '100%',
          height: '100%',
          // make sure that the component using this recipe defines the '--progress' variable in order for the indicator to be visible
          transform: `translateX(-100%) translateX(var(--progress))`,
          ...(isGreen
            ? { backgroundColor: 'positive.fill_primary' }
            : { backgroundColor: 'neutrals.fill_primary' }),
        }),
      }}
      progress={isLoading ? 100 : progress}
    />
  );
};

export { ProgressBar };
