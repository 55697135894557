import { type AmountFor } from '@bts-web/data-layer/server';

export const FIAT_REGEXP = /^\d+[.,]?(?:\d{1,2})?$/;

export const ASSET_REGEXP = /^\d+[.,]?(\d{1,8})?$/;

export const validateAmountInput = (
  value: string,
  displayType: AmountFor,
): boolean => {
  if (!value) {
    return true;
  }

  if (Number(value) >= Number.MAX_SAFE_INTEGER) {
    return false;
  }

  const validateInputRegExp =
    displayType === 'FIAT' ? FIAT_REGEXP : ASSET_REGEXP;

  return validateInputRegExp.test(value);
};
