export type UserAccessModalTranslations = {
  userRoleModalReadOnlySubtitle: string;
  userRoleModalReadOnlyTitle: string;
  userStageInfoPendingApproval: string;
  userStageModalPendingApprovalSubtitle: string;
  goToPortfolio: string;
  errorMessagePendingApprovalTrading: string;
  errorMessagePendingApprovalTransfers: string;
  errorMessageTradingUnavailable: string;
  errorTitleTradingUnavailable: string;
  errorTitleTransfersUnavailable: string;
};

const UserAccessModalTranslations: UserAccessModalTranslations = {
  userRoleModalReadOnlySubtitle: 'user_role_modal_read_only_subtitle',
  userRoleModalReadOnlyTitle: 'user_role_modal_read_only_title',
  userStageInfoPendingApproval: 'user_stage_info_pending_approval',
  userStageModalPendingApprovalSubtitle:
    'user_stage_modal_pending_approval_subtitle',
  goToPortfolio: 'go_to_portfolio',
  errorMessagePendingApprovalTrading: 'error_message_pending_approval_trading',
  errorMessagePendingApprovalTransfers:
    'error_message_pending_approval_transfers',
  errorMessageTradingUnavailable: 'error_message_trading_unavailable',
  errorTitleTradingUnavailable: 'error_title_trading_unavailable',
  errorTitleTransfersUnavailable: 'error_title_transfers_unavailable',
};

export const getUserAccessModalTranslations = (
  transUtil: (param: string) => string,
): UserAccessModalTranslations => {
  return Object.entries(UserAccessModalTranslations).reduce(
    (acc, [key, value]) => {
      acc[key as keyof UserAccessModalTranslations] = transUtil(value);

      return acc;
    },
    {} as UserAccessModalTranslations,
  );
};
