'use client';

import { type PropsWithChildren, Suspense, useEffect, useState } from 'react';
import { TradeContext } from '@bts-web/utils-context';
import { closeTradeDrawer } from './utils';
import { TradeOptionsDrawer } from '../TradeDrawerProper/TradeOptionsDrawer';
import { DEFAULT_DISABLED_SELL } from '../useTradeSellDisabler/useTradeSellDisabler';

export type TradeInnerTranslatedValues = {
  title: string;
  buyButtonText: string;
  sellButtonText: string;
};

const emptyFn = () => {
  return;
};

const TradeProvider = ({
  children,
  translations,
}: PropsWithChildren<{
  translations: TradeInnerTranslatedValues;
}>) => {
  const [isClientReady, setIsClientReady] = useState(false);

  const [sellButtonDisablerKeys, setSellButtonDisablerKeys] = useState<
    string[]
  >([DEFAULT_DISABLED_SELL]);

  const [onClickTradeActions, setOnClickTradeActions] = useState({
    buy: emptyFn,
    sell: emptyFn,
  });

  const onClickBuyOption = () => {
    closeTradeDrawer();

    onClickTradeActions.buy();
  };

  const onClickSellOption = () => {
    closeTradeDrawer();

    onClickTradeActions.sell();
  };

  useEffect(() => {
    setIsClientReady(true);
  }, []);

  return (
    <TradeContext.Provider
      value={{
        isSellButtonVisible: sellButtonDisablerKeys.length === 0,
        setOnClickTradeActions,
        setSellButtonDisablerKeys,
      }}
    >
      {children}
      {isClientReady && (
        <Suspense fallback={null}>
          <TradeOptionsDrawer
            translatedValues={translations}
            {...{
              isSellButtonVisible: sellButtonDisablerKeys.length === 0,
              onClickBuyOption,
              onClickSellOption,
            }}
          />
        </Suspense>
      )}
    </TradeContext.Provider>
  );
};

export { TradeProvider };
