/* eslint-disable @nx/enforce-module-boundaries */
'use client';

import { css } from '@bts-web/utils-style-engine';
import {
  FC,
  useRef,
  useState,
  KeyboardEvent,
  ChangeEvent,
  FocusEvent,
  FormEvent,
  useEffect,
} from 'react';

export type TDigitCodeInputProps = {
  parentInputName?: string;
  hasError?: boolean;
  onChange?: (isFilled: boolean, code: string) => void;
};

export const ORDINAL_NUMBER_STRINGS = [
  'first',
  'second',
  'third',
  'fourth',
  'fifth',
  'sixth',
];

const DigitCodeInput: FC<TDigitCodeInputProps> = ({
  parentInputName,
  hasError,
  onChange,
}) => {
  const [code, setCode] = useState<string[]>(['', '', '', '', '', '']);

  const inputRefs = useRef<Array<HTMLInputElement | null>>([]);

  const handleChange = (index: number, e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    const lastChar = value.slice(-1);

    if (lastChar === '' || /^\d$/.test(lastChar)) {
      const newCode = [...code];

      newCode[index] = lastChar;

      setCode(newCode);

      // Optional: submit on filled
      if (newCode.every((digit) => digit !== '')) {
        console.log('Code completed:', newCode.join(''));
        // Add your submission logic here
      }
    }
  };

  const handleKeyDown = (index: number, e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace') {
      if (code[index] !== '') {
        // If current input is not empty, just clear it without moving focus
        const newCode = [...code];

        newCode[index] = '';

        setCode(newCode);
      } else if (index > 0) {
        // If current input is empty and it's not the first input, move focus back
        inputRefs.current[index - 1]?.focus();
      }
    } else if (
      !/^\d$/.test(e.key) &&
      !['ArrowLeft', 'ArrowRight', 'Tab'].includes(e.key)
    ) {
      e.preventDefault(); // Prevent non-numeric input
    }
  };

  const handlePaste = (e: React.ClipboardEvent) => {
    e.preventDefault();

    const pastedData = e.clipboardData.getData('text').replace(/\D/g, '');

    const pastedCode = pastedData.slice(0, 6).split('');

    const newCode = [...code];

    pastedCode.forEach((digit, index) => {
      if (index < 6) newCode[index] = digit;
    });

    setCode(newCode);

    const nextEmptyIndex = newCode.findIndex((digit) => digit === '');

    if (nextEmptyIndex !== -1) {
      inputRefs.current[nextEmptyIndex]?.focus();
    } else {
      inputRefs.current[5]?.focus();
    }
  };

  const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
    e.target.select();
  };

  const handleInput = (index: number, e: FormEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;

    if (/^\d$/.test(value) && index < 5) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  // TODO refactor the component in order to move this logic to handleChange
  useEffect(() => {
    if (onChange) {
      const isFilled = code.every((digit) => digit !== '');

      onChange(isFilled, code.join(''));
    }
  }, [code, onChange]);

  return (
    <div data-testid="digit-code-input">
      {parentInputName && (
        <input type="hidden" name={parentInputName} value={code.join('')} />
      )}
      <div
        className={css({
          display: 'flex',
          gap: 'extra_small',
          py: 'small',
        })}
      >
        {code.map((digit, index) => (
          <input
            aria-label={`2fa code ${ORDINAL_NUMBER_STRINGS[index]} digit input`}
            key={index}
            ref={(el) => (inputRefs.current[index] = el)}
            value={digit}
            onChange={(e) => handleChange(index, e)}
            onKeyDown={(e) => handleKeyDown(index, e)}
            onPaste={handlePaste}
            onFocus={handleFocus}
            onInput={(e) => handleInput(index, e)}
            maxLength={1}
            type="text"
            inputMode="numeric"
            pattern="\d*"
            className={css({
              border: '1px solid',
              px: 'small',
              borderColor: hasError
                ? 'negative.stroke_primary'
                : 'neutrals.stroke_primary',
              borderRadius: '8px',
              width: '100%',
              cursor: 'pointer',
              textAlign: 'center',
              height: 'extra_large_5',
              lineHeight: 'extra_large_5',
              fontSize: 'headline.small_medium',
              flex: 1,
              '&:focus': {
                borderColor: 'information.text_primary',
              },
            })}
          />
        ))}
      </div>
    </div>
  );
};

export { DigitCodeInput };
