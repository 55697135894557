/**
 * @generated SignedSource<<6431da613a9fc6c2dba54be321074f51>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type TransactionStatus = "CREATED" | "EXPIRED" | "FAILED" | "FINISHED" | "IN_PROGRESS";
export type AcceptTradeOfferInput = {
  id: string;
};
export type AcceptTradeOfferMutation$variables = {
  input?: AcceptTradeOfferInput | null;
};
export type AcceptTradeOfferMutation$data = {
  readonly acceptTradeOffer: {
    readonly asset: {
      readonly __typename: string;
      readonly id: string;
      readonly logoUrl: string | null;
      readonly name: string | null;
      readonly price: PrecisionFloat | null;
      readonly symbol: string | null;
    };
    readonly id: string;
    readonly offer: {
      readonly assetAmount: PrecisionFloat;
      readonly assetConversionRate: PrecisionFloat;
      readonly expiresAt: any;
      readonly fiatAmount: PrecisionFloat;
      readonly hasTaxOfflineWarning: boolean | null;
      readonly id: string;
      readonly spread: PrecisionFloat;
      readonly taxAmount: PrecisionFloat | null;
      readonly totalAmount: PrecisionFloat | null;
    };
    readonly status: TransactionStatus | null;
    readonly time: any;
  } | null;
};
export type AcceptTradeOfferMutation = {
  response: AcceptTradeOfferMutation$data;
  variables: AcceptTradeOfferMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "TradeTransaction",
    "kind": "LinkedField",
    "name": "acceptTradeOffer",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "time",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "TradeOffer",
        "kind": "LinkedField",
        "name": "offer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fiatAmount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "assetAmount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalAmount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "taxAmount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "assetConversionRate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "expiresAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasTaxOfflineWarning",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "spread",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "asset",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "symbol",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "logoUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "price",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AcceptTradeOfferMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AcceptTradeOfferMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "9fee46cf9c2c768f8c26c694c742ef5b",
    "id": null,
    "metadata": {},
    "name": "AcceptTradeOfferMutation",
    "operationKind": "mutation",
    "text": "mutation AcceptTradeOfferMutation(\n  $input: AcceptTradeOfferInput\n) {\n  acceptTradeOffer(input: $input) {\n    id\n    status\n    time\n    offer {\n      id\n      fiatAmount\n      assetAmount\n      totalAmount\n      taxAmount\n      assetConversionRate\n      expiresAt\n      hasTaxOfflineWarning\n      spread\n    }\n    asset {\n      id\n      symbol\n      logoUrl\n      name\n      price\n      __typename\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "05945b64770e1732b85e73c14e072049";

export default node;
