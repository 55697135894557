'use client';

import { useContext, createContext, useState, PropsWithChildren } from 'react';
import {
  PortfolioPeriod,
  PortfolioViewQuery,
} from '@bts-web/data-layer/server';
import { PrecisionFloat } from '@bts-web/utils-formatting';
import { NonNullablePortfolioResponse } from '@bts-web/core-features/portfolio';
import { getPortfolio } from '@bts-web/core-features/common';

export interface PortfolioContextProps {
  returnPercentage?: number | null;
  overallAmount: PrecisionFloat;
  historicalData: NonNullable<
    PortfolioViewQuery['response']['portfolio']
  >['historicalData'];
  assetGroups: NonNullable<
    NonNullable<PortfolioViewQuery['response']['portfolio']>['assetGroups']
  >['edges'];
  changePeriod: (timerange: PortfolioPeriod) => Promise<void>;
  timeSpan: PortfolioPeriod;
  isLoading: boolean;
}

const PortfolioContext = createContext<PortfolioContextProps | null>(null);

export function usePortfolioContext() {
  const contextValue = useContext(PortfolioContext);

  if (contextValue === null) {
    throw new Error(
      'usePortfolioContext must be used within a PortfolioContextProvider',
    );
  }

  return contextValue;
}

export const PortfolioContextProvider = ({
  children,
  initialData,
}: PropsWithChildren<{ initialData: NonNullablePortfolioResponse }>) => {
  const [portfolio, setPortfolio] =
    useState<NonNullablePortfolioResponse | null>(initialData);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [timeSpan, setTimeSpan] = useState<PortfolioPeriod>('DAY');

  const fetchNewPortfolioData = async (timerange: PortfolioPeriod) => {
    setIsLoading(true);

    getPortfolio({
      period: timerange,
      tags: [timerange],
    }).then((response) => {
      setPortfolio(response.data.portfolio);

      setTimeSpan(timerange);

      setIsLoading(false);
    });
  };

  return (
    <PortfolioContext.Provider
      value={{
        isLoading: isLoading,
        assetGroups: portfolio?.assetGroups?.edges || null,
        historicalData: portfolio?.historicalData || null,
        overallAmount: portfolio?.overallAmount,
        returnPercentage: portfolio?.returnPercentage,
        timeSpan,
        changePeriod: fetchNewPortfolioData,
      }}
    >
      {children}
    </PortfolioContext.Provider>
  );
};
