/* eslint-disable @nx/enforce-module-boundaries */
import { css } from '@bts-web/utils-style-engine';
import { leftNavMenuStyles } from './DesktopLeftNav';

const DesktopLeftNavSkeleton = () => {
  return (
    <nav
      className={css({
        ...leftNavMenuStyles,
        bgColor: 'neutrals.fill_quaternary',
      })}
    />
  );
};

export { DesktopLeftNavSkeleton };
