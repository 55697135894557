import { Z_INDEX_LIBRARY } from '@bts-web/utils-style-engine';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { FC, ReactNode } from 'react';

export interface IMenuProps {
  components: {
    buttonElement: ReactNode;
    menuContent: ReactNode;
  };
  dropdownMenuProps?: DropdownMenu.DropdownMenuContentProps;
}

export const Menu: FC<IMenuProps> = ({ components, dropdownMenuProps }) => {
  const { buttonElement, menuContent } = components;

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>{buttonElement}</DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          {...dropdownMenuProps}
          style={{
            boxShadow:
              '0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2)',
            willChange: 'transform, opacity',
            zIndex: Z_INDEX_LIBRARY.DROPDOWN,
            ...(dropdownMenuProps ? dropdownMenuProps.style : {}),
          }}
        >
          {menuContent}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};
