/**
 * @generated SignedSource<<da9998d42cb965b5ab1dc1444ce68fcc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type TransactionStatus = "CREATED" | "EXPIRED" | "FAILED" | "FINISHED" | "IN_PROGRESS";
export type TransactionFinalizedSummaryDataQuery$variables = {
  offerId?: string | null;
};
export type TransactionFinalizedSummaryDataQuery$data = {
  readonly transaction: {
    readonly asset: {
      readonly __typename: string;
      readonly description: string | null;
      readonly id: string;
      readonly isBuyActive: boolean | null;
      readonly isSellActive: boolean | null;
      readonly legalUrl: string | null;
      readonly logoUrl: string | null;
      readonly marketCap: number | null;
      readonly minimumBuyAmount: PrecisionFloat | null;
      readonly minimumSellAmount: PrecisionFloat | null;
      readonly monthlyVolatility: number | null;
      readonly name: string | null;
      readonly portfolio: {
        readonly assetBalance: PrecisionFloat | null;
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly assets: {
              readonly edges: ReadonlyArray<{
                readonly assetBalance: PrecisionFloat | null;
                readonly totalInvested: PrecisionFloat | null;
                readonly totalReturn: ValueChange | null;
              } | null> | null;
            } | null;
          } | null;
        } | null> | null;
        readonly fiatBalance: PrecisionFloat | null;
      } | null;
      readonly price: PrecisionFloat | null;
      readonly price52WeeksHigh: PrecisionFloat | null;
      readonly price52WeeksLow: PrecisionFloat | null;
      readonly priceBuy: PrecisionFloat | null;
      readonly priceChange1Month: ValueChange | null;
      readonly priceChange1Week: ValueChange | null;
      readonly priceChange1Year: ValueChange | null;
      readonly priceChange24Hours: ValueChange | null;
      readonly priceChange5Years: ValueChange | null;
      readonly priceDailyHigh: PrecisionFloat | null;
      readonly priceDailyLow: PrecisionFloat | null;
      readonly priceSell: PrecisionFloat | null;
      readonly symbol: string | null;
    } | null;
    readonly assetAmount: PrecisionFloat | null;
    readonly fiatAmount: PrecisionFloat | null;
    readonly status: TransactionStatus | null;
    readonly taxAmount: PrecisionFloat | null;
    readonly totalAmount: PrecisionFloat | null;
  } | null;
};
export type TransactionFinalizedSummaryDataQuery = {
  response: TransactionFinalizedSummaryDataQuery$data;
  variables: TransactionFinalizedSummaryDataQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "offerId"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "tradeOfferId",
        "variableName": "offerId"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "assetBalance",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "asset",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "symbol",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "logoUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "price",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "marketCap",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "legalUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "priceDailyHigh",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "priceDailyLow",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "price52WeeksHigh",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "price52WeeksLow",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "monthlyVolatility",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "priceChange24Hours",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "priceChange1Week",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "priceChange1Month",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "priceChange1Year",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "priceChange5Years",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "priceSell",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "priceBuy",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minimumBuyAmount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minimumSellAmount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isBuyActive",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isSellActive",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AssetPortfoliosConnection",
      "kind": "LinkedField",
      "name": "portfolio",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fiatBalance",
          "storageKey": null
        },
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "AssetPortfoliosEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Portfolio",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PortfolioAssetsConnection",
                  "kind": "LinkedField",
                  "name": "assets",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "PortfolioAssetsEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "totalReturn",
                          "storageKey": null
                        },
                        (v4/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "totalInvested",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v5/*: any*/)
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "assetAmount",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fiatAmount",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalAmount",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "taxAmount",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TransactionFinalizedSummaryDataQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "transaction",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TransactionFinalizedSummaryDataQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "transaction",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v2/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2b9485f19c2e85c0dfbde34cc6e73ff3",
    "id": null,
    "metadata": {},
    "name": "TransactionFinalizedSummaryDataQuery",
    "operationKind": "query",
    "text": "query TransactionFinalizedSummaryDataQuery(\n  $offerId: ID\n) {\n  transaction(input: {tradeOfferId: $offerId}) {\n    __typename\n    status\n    asset {\n      id\n      name\n      symbol\n      logoUrl\n      price\n      description\n      marketCap\n      legalUrl\n      priceDailyHigh\n      priceDailyLow\n      price52WeeksHigh\n      price52WeeksLow\n      monthlyVolatility\n      priceChange24Hours\n      priceChange1Week\n      priceChange1Month\n      priceChange1Year\n      priceChange5Years\n      priceSell\n      priceBuy\n      minimumBuyAmount\n      minimumSellAmount\n      isBuyActive\n      isSellActive\n      portfolio {\n        fiatBalance\n        assetBalance\n        edges {\n          node {\n            assets {\n              edges {\n                totalReturn\n                assetBalance\n                totalInvested\n              }\n            }\n          }\n        }\n      }\n      __typename\n    }\n    assetAmount\n    fiatAmount\n    totalAmount\n    taxAmount\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "81777855cdfd34a13456e8d9ccccab0a";

export default node;
