/**
 * @generated SignedSource<<930561f2b4410b2be895a784df4e681e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AmlQuestionsViewQuery$variables = {
  first: number;
};
export type AmlQuestionsViewQuery$data = {
  readonly amlQuestions: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly answers: ReadonlyArray<{
          readonly answer: string | null;
          readonly id: string;
        } | null> | null;
        readonly description: string | null;
        readonly id: string;
        readonly question: string | null;
      } | null;
    } | null>;
  } | null;
};
export type AmlQuestionsViewQuery = {
  response: AmlQuestionsViewQuery$data;
  variables: AmlQuestionsViewQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "first",
            "variableName": "first"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "AMLQuestionConnection",
    "kind": "LinkedField",
    "name": "amlQuestions",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AMLQuestionEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AMLQuestion",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "question",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AMLAnswer",
                "kind": "LinkedField",
                "name": "answers",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "answer",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AmlQuestionsViewQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AmlQuestionsViewQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "b6014bc57309ca02e2b90f1e4cf3cadd",
    "id": null,
    "metadata": {},
    "name": "AmlQuestionsViewQuery",
    "operationKind": "query",
    "text": "query AmlQuestionsViewQuery(\n  $first: Int!\n) {\n  amlQuestions(input: {first: $first}) {\n    edges {\n      node {\n        id\n        question\n        description\n        answers {\n          id\n          answer\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "bad948fe7b3349e236e7e9b1ab212b57";

export default node;
