import { css } from '@bts-web/utils-style-engine';
import { FC } from 'react';

export const DividerElement: FC = () => {
  return (
    <div
      className={css({
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
        borderColor: 'neutrals.divider',
      })}
    />
  );
};
