import { FC } from 'react';
import { SkeletonElement } from '../../../common/components/loading/SkeletonElement/SkeletonElement';
import { css } from '@bts-web/utils-style-engine';
import { getAppConfig } from '@bts-web/core-app-config';

const AssetSkeleton = () => (
  <div
    className={css({
      display: 'flex',
      py: 'medium',
      gap: 'medium',
      borderBottomWidth: '1px',
      borderColor: 'neutrals.divider',
    })}
  >
    <div>
      <SkeletonElement width="40px" height="40px" borderRadius="50%" />
    </div>

    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        gap: 'extra_small_3',
      })}
    >
      <SkeletonElement width="103px" height="20px" borderRadius="30px" />
      <SkeletonElement width="52px" height="16px" borderRadius="30px" />
    </div>
  </div>
);

export const AssetsListSkeleton = () => (
  <div
    aria-label="assets list loading placeholder"
    className={css({ px: 'medium' })}
  >
    <AssetSkeleton />
    <AssetSkeleton />
    <AssetSkeleton />
    <AssetSkeleton />
    <AssetSkeleton />
  </div>
);

const TransfersSkeleton: FC = () => {
  const { feature_showCryptoTransfersDrawerSearchBar } = getAppConfig();

  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
      })}
    >
      {/* TABS */}
      <SkeletonElement height="58px" width="100%" />

      {/* Navbar */}
      <div
        className={css({
          height: '56px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          px: 'medium',
        })}
      >
        <SkeletonElement height="16px" width="16px" />

        <SkeletonElement height="18px" width="100px" />

        <SkeletonElement height="16px" width="16px" />
      </div>

      {/* STEPPER */}
      <div
        className={css({
          height: '52px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        })}
      >
        <SkeletonElement height="4px" width="372px" />
      </div>

      {/* SEARCH */}
      {feature_showCryptoTransfersDrawerSearchBar && (
        <div
          className={css({
            py: 'extra_small',
            px: 'medium',
          })}
        >
          <SkeletonElement height="56px" width="372px" borderRadius="8px" />
        </div>
      )}

      {/* ASSETS */}
      <AssetsListSkeleton />
    </div>
  );
};

export { TransfersSkeleton };
