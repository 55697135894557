import { FC } from 'react';
import { css } from '@bts-web/utils-style-engine';
import { TradeQuickSelectItem } from '../types';

export type QuickSelectValuePassed =
  TradeQuickSelectItem['fiatAndAssetValues'] & { isMax?: boolean };

export type QuickSelectButtonProps = {
  data: TradeQuickSelectItem;
  onClick: (value: QuickSelectValuePassed) => void;
  active?: boolean;
  disabled?: boolean;
};

export const QuickSelectButton: FC<QuickSelectButtonProps> = ({
  data,
  onClick,
  active,
  disabled,
}) => {
  const { fiatAndAssetValues, label, isMax } = data;

  const onButtonClick = () => {
    onClick({ ...fiatAndAssetValues, isMax });
  };

  return (
    <button
      disabled={active || disabled}
      type="button"
      onClick={() => onButtonClick()}
      aria-label={label}
      className={css({
        flex: 1,
        px: 'small',
        py: 'extra_small',
        cursor: 'pointer',
        color: !active ? 'neutrals.text_primary' : 'neutrals.card_fill_primary',
        borderColor: 'neutrals.stroke_secondary',
        borderWidth: '1px',
        borderStyle: 'solid',
        '&:hover:not(:disabled)': {
          bgColor: 'neutrals.fill_quinary',
          color: 'neutrals.text_primary',
        },
        bgColor: !active
          ? 'neutrals.card_fill_primary'
          : 'neutrals.fill_primary',
        fontSize: 'body.medium',
        borderRadius: '8px',
        _disabled: {
          cursor: 'default',
          ...(!active ? { backgroundColor: 'neutrals.fill_disabled' } : {}),
        },
      })}
    >
      {label}
    </button>
  );
};
