import { FC, memo } from 'react';
import { css } from '@bts-web/utils-style-engine';
import { AmountFor } from '@bts-web/data-layer/server';
import { TradeQuickSelectItem } from '../../types';
import {
  QuickSelectButton,
  QuickSelectValuePassed,
} from '../../QuickSelectButton/QuickSelectButton';

export interface QuickSelectButtonsProps {
  items: TradeQuickSelectItem[];
  onQuickSelectButtonClick: (item: QuickSelectValuePassed) => void;
  disabled: boolean;
  displayType: AmountFor;
  inputValue: string;
}

export const QuickSelectButtons: FC<QuickSelectButtonsProps> = memo(
  ({ items, onQuickSelectButtonClick, disabled, displayType, inputValue }) => (
    <div
      className={css({
        alignItems: 'flex-start',
        display: 'inline-flex',
        maxWidth: '100%',
        overflowX: 'auto',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        py: 'extra_small_2',
        gap: 'extra_small_2',
      })}
    >
      {items.map((item) => {
        const isButtonActive =
          Number(inputValue) ===
          Number(item.fiatAndAssetValues[displayType]?.value);

        return (
          <QuickSelectButton
            active={isButtonActive}
            key={item.value}
            data={item}
            onClick={onQuickSelectButtonClick}
            disabled={disabled}
          />
        );
      })}
    </div>
  ),
);
