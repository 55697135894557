/**
 * @generated SignedSource<<c59b0020a943dcdacc8bc0ca4358d925>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AmountFor = "ASSET" | "FIAT";
export type TradeOfferOperation = "BUY" | "SELL";
export type CreateTradeOfferInput = {
  amount: string;
  amountFor: AmountFor;
  assetId: string;
  operation: TradeOfferOperation;
};
export type CreateTradeOfferMutation$variables = {
  input?: CreateTradeOfferInput | null;
};
export type CreateTradeOfferMutation$data = {
  readonly createTradeOffer: {
    readonly deepLink?: string;
    readonly offer?: {
      readonly " $fragmentSpreads": FragmentRefs<"CreateTradeOfferMutationFragment">;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"CreateTradeOfferMutationFragment">;
  } | null;
};
export type CreateTradeOfferMutation = {
  response: CreateTradeOfferMutation$data;
  variables: CreateTradeOfferMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "CreateTradeOfferMutationFragment"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deepLink",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = [
  (v4/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "fiatAmount",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "assetAmount",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "assetConversionRate",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "expiresAt",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "hasTaxOfflineWarning",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "spread",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateTradeOfferMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "createTradeOffer",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": (v2/*: any*/),
            "type": "TradeOffer",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "TradeOffer",
                "kind": "LinkedField",
                "name": "offer",
                "plural": false,
                "selections": (v2/*: any*/),
                "storageKey": null
              }
            ],
            "type": "TradeOfferSignatureNeeded",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateTradeOfferMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "createTradeOffer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": (v5/*: any*/),
            "type": "TradeOffer",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "TradeOffer",
                "kind": "LinkedField",
                "name": "offer",
                "plural": false,
                "selections": (v5/*: any*/),
                "storageKey": null
              }
            ],
            "type": "TradeOfferSignatureNeeded",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/)
            ],
            "type": "Node",
            "abstractKey": "__isNode"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4ba9d6240aec6bd3f8bedd8e4c241831",
    "id": null,
    "metadata": {},
    "name": "CreateTradeOfferMutation",
    "operationKind": "mutation",
    "text": "mutation CreateTradeOfferMutation(\n  $input: CreateTradeOfferInput\n) {\n  createTradeOffer(input: $input) {\n    __typename\n    ... on TradeOffer {\n      ...CreateTradeOfferMutationFragment\n    }\n    ... on TradeOfferSignatureNeeded {\n      deepLink\n      offer {\n        ...CreateTradeOfferMutationFragment\n        id\n      }\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n\nfragment CreateTradeOfferMutationFragment on TradeOffer {\n  id\n  fiatAmount\n  assetAmount\n  assetConversionRate\n  expiresAt\n  hasTaxOfflineWarning\n  spread\n}\n"
  }
};
})();

(node as any).hash = "89f0b961b2f1c54c9d8f4b48d5893a29";

export default node;
