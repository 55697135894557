import Image from 'next/image';
import { css } from '@bts-web/utils-style-engine';
import { IntroHeader, IntroHeaderProps } from '../IntroHeader/IntroHeader';
import BitpandaBWhite from './BitpandaBWhite.svg';
import { ResponsiveLayoutWithLogo } from '../../../layouts/ResponsiveLayoutWithLogo/ResponsiveLayoutWithLogo';
import { HiddenDesktop } from '../../../layouts/LayoutWithLeftDesktopNav/Reusable/HiddenDesktop/HiddenDesktop';

export interface InfoLayoutProps
  extends IntroHeaderProps,
    React.PropsWithChildren {}

const infoLayoutContainerStyles = css({
  backgroundColor: 'brand.fill_secondary',
  borderRadius: {
    base: 0,
    lg: 8,
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
  width: '100%',
  px: 'medium',
  paddingTop: 'extra_large',
});

export const InfoLayout = ({
  children,
  ...introHeaderProps
}: InfoLayoutProps) => (
  <ResponsiveLayoutWithLogo>
    <div className={infoLayoutContainerStyles}>
      <div
        className={css({
          display: 'flex',
          flexDirection: 'column',
          gap: 'medium',
        })}
      >
        <HiddenDesktop>
          <Image
            width={23}
            height={34}
            priority
            src={BitpandaBWhite}
            alt="Bitpanda"
          />
        </HiddenDesktop>
        <IntroHeader {...introHeaderProps} />
      </div>
      {children}
    </div>
  </ResponsiveLayoutWithLogo>
);
