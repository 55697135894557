import { ReactNode } from 'react';
import { css } from '@bts-web/utils-style-engine';
import Link from 'next/link';

type ButtonVisualVariants =
  | 'primary'
  | 'secondary'
  | 'ghost'
  | 'accent'
  | 'primaryOnBrand';

type ButtonSizeVariants = 'small' | 'medium' | 'large';

type LinkButtonComponentProps = {
  size?: ButtonSizeVariants;
  visual?: ButtonVisualVariants;
  to: string;
  noPadding?: boolean;
  children: ReactNode;
  fullWidth?: boolean;
  className?: string;
  inverse?: boolean;
};

export const HyperLinkButtonBase: (
  props: LinkButtonComponentProps,
) => React.ReactElement = ({
  size = 'large',
  visual = 'secondary',
  children,
  noPadding = false,
  fullWidth = false,
  className,
  inverse,
  to,
  ...rest
}) => {
  return (
    <Link
      aria-label={`link button ${children}`}
      href={to}
      data-visual={visual}
      data-inverse={inverse}
      className={`${className ? className : ''} ${css({
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexShrink: 0,
        gap: 'extra_small_2',
        borderRadius: '8px',
        cursor: 'pointer',
        ...(visual === 'primary' && !inverse
          ? {
              backgroundColor: 'neutrals.fill_primary',
              color: 'neutrals.text_primary_inverted',
              '&:active': {
                bg: 'neutrals.fill_pressed',
                color: 'neutrals.text_primary_inverted',
              },
              '&:focus': {
                bg: 'neutrals.fill_pressed',
                color: 'neutrals.text_primary_inverted',
              },
            }
          : {}),
        ...(visual === 'primary' && inverse
          ? {
              color: 'neutrals.fill_primary',
              backgroundColor: 'neutrals.text_primary_inverted',
              '&:hover': {
                backgroundColor: 'neutrals.fill_hovered_inverse',
              },
              '&:active': {
                backgroundColor: 'neutrals.fill_pressed_inverse',
              },
              '&:focus': {
                backgroundColor: 'neutrals.fill_focused_inverse',
              },
            }
          : {}),
        ...(visual === 'secondary'
          ? {
              borderWidth: '1px',
              borderColor: 'neutrals.stroke_primary',
              bg: 'neutrals.card_fill_primary',
              color: 'neutrals.text_primary',
              '&:active': {
                bg: 'neutrals.card_fill_primary',
                color: 'neutrals.text_primary',
                borderColor: 'neutrals.stroke_primary',
              },
              '&:focus': {
                bg: 'neutrals.fill_focused',
                color: 'neutrals.text_primary',
                borderColor: 'neutrals.stroke_primary',
              },
            }
          : {}),
        ...(visual === 'ghost'
          ? {
              color: 'neutrals.text_primary',
              '&:active': {
                color: 'neutrals.text_primary',
              },
              '&:focus': {
                color: 'neutrals.text_primary',
              },
            }
          : {}),
        ...(visual === 'accent' && !inverse
          ? {
              backgroundColor: 'brand.fill_primary',
              color: 'brand.on_fill_primary',
              '&:active:enabled': {
                backgroundColor: 'brand.fill_pressed',
                color: 'brand.on_fill_primary',
              },
              '&:focus:enabled': {
                backgroundColor: 'brand.fill_pressed',
                color: 'brand.on_fill_primary',
              },
              '&:disabled': {
                backgroundColor: 'neutrals.fill_disabled',
                color: 'neutrals.text_disabled',
              },
              '&:hover:not(:disabled)': {
                backgroundColor: 'neutrals.on_fill_dark_disabled',
              },
            }
          : {}),
        ...(inverse && visual === 'accent'
          ? {
              borderWidth: '2px',
              borderColor: 'brand.fill_primary',
              backgroundColor: 'brand.on_fill_primary',
              color: 'brand.fill_primary',
              '&:active:enabled': {
                backgroundColor: 'brand.fill_pressed_inverse',
                color: 'brand.fill_primary',
              },
              '&:focus:enabled': {
                backgroundColor: 'brand.fill_focused_inverse',
                color: 'brand.fill_primary',
              },
              '&:disabled': {
                backgroundColor: 'neutrals.fill_disabled_inverse',
                color: 'neutrals.text_disabled_inverse',
              },
              '&:hover:not(:disabled)': {
                backgroundColor: 'brand.fill_hovered_inverse',
              },
            }
          : {}),
        ...(visual === 'primaryOnBrand'
          ? {
              backgroundColor: 'neutrals.on_fill_dark',
              color: 'neutrals.on_fill_light',
              '&:active:enabled': {
                bg: 'neutrals.on_fill_dark',
                color: 'neutrals.on_fill_light',
              },
              '&:focus:enabled': {
                bg: 'neutrals.fill_focused',
                color: 'neutrals.on_fill_light',
              },
              '&:disabled': {
                bg: 'neutrals.on_fill_dark_disabled',
                color: 'neutrals.text_disabled',
              },
            }
          : {}),
        ...(size === 'small'
          ? {
              fontSize: 'caption.medium_medium',
              py: 'extra_small_2',
              px: 'small',
              minHeight: 40,
            }
          : {}),
        ...(size === 'medium'
          ? {
              fontSize: 'label.medium_medium',
              lineHeight: 'label.medium_medium',
              letterSpacing: 'label.medium_medium',
              fontWeight: 'label.medium_medium',
              padding: 'extra_small_2',
              minHeight: 48,
              width: '100%',
            }
          : {}),
        ...(size === 'large'
          ? {
              fontSize: 'label.large',
              lineHeight: 'label.large',
              letterSpacing: 'label.large',
              fontWeight: 'label.large',
              px: 'extra_small_2',
              py: 18,
              minHeight: 56,
              width: '100%',
            }
          : {}),
        ...(noPadding === true
          ? {
              px: 0,
              py: 0,
              p: 0,
              minHeight: 0,
            }
          : {}),
      })}`}
      {...rest}
    >
      {children}
    </Link>
  );
};
