'use client';

import { useState } from 'react';
import { css } from '@bts-web/utils-style-engine';
import { Icon } from '@bts-web/design-system/component/icon';
import { BottomPopupSheet } from '../../Reusable/BottomPopupSheet/BottomPopupSheet';

interface TaxInformationSheetWrapperProps {
  aboutTaxTextLink?: string;
  taxTitle?: string;
  taxInfoText1?: string;
  taxInfoText2?: string;
}

const primaryContainerStyles = css({
  color: 'neutrals.text_primary',
  fontSize: 'title.medium_medium',
  fontWeight: 'title.medium_medium',
  lineHeight: 'title.medium_medium',
  letterSpacing: 'title.medium_medium',
  padding: 'medium',
  borderTopWidth: '1px',
  borderColor: 'neutrals.stroke_secondary',
});

const aboutTextLinkStyle = css({
  display: 'flex',
  gap: 'extra_small_2',
  alignItems: 'center',
  cursor: 'pointer',
  _hover: {
    color: 'neutrals.text_secondary',
    textDecoration: 'underline',
  },
});

export const TaxInformationSheetWrapper = ({
  aboutTaxTextLink,
  taxInfoText1,
  taxInfoText2,
  taxTitle,
}: TaxInformationSheetWrapperProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleToogle = () => {
    setIsOpen((prevstate) => !prevstate);
  };

  return (
    <>
      <div
        className={primaryContainerStyles}
        aria-label="tax information wrapper"
      >
        <button
          onClick={handleToogle}
          className={aboutTextLinkStyle}
          data-testid="about-test-link"
        >
          {aboutTaxTextLink}
          <Icon icon="info" size="16" />
        </button>
      </div>

      <BottomPopupSheet
        onOpenChange={handleToogle}
        taxTitle={taxTitle}
        isOpened={isOpen}
        taxInfoText1={taxInfoText1}
        taxInfoText2={taxInfoText2}
      />
    </>
  );
};
