import { Root, Portal, Title, Overlay, Content } from '@radix-ui/react-dialog';

export interface SheetProps {
  internal: {
    overlayClassName?: string;
    contentClassName: string;
  };
  external: {
    title?: string;
    isOpened: boolean;
    onOpenChange: () => void;
    textLine1?: string;
    textLine2?: string;
    id: string;
  };
}

const Sheet = ({
  id,
  title,
  isOpened,
  onOpenChange,
  textLine1,
  textLine2,
  overlayClassName,
  contentClassName,
}: SheetProps['internal'] & SheetProps['external']) => (
  <Root open={isOpened} onOpenChange={onOpenChange}>
    <Portal>
      <Overlay data-testid="sheet-overlay" className={overlayClassName} />
      <Content data-testid={`sheet-content-${id}`} className={contentClassName}>
        {title && (
          <Title data-testid="sheet-title" data-element="sheet-title">
            {title}
          </Title>
        )}
        <div data-testid="sheet-description" data-element="sheet-description">
          {textLine1 && <p>{textLine1}</p>}
          {textLine2 && <p>{textLine2}</p>}
        </div>
      </Content>
    </Portal>
  </Root>
);

export { Sheet };
