import { memo } from 'react';
import { useTradeForm } from '../hooks/useTradeForm';
import { TradeFormProps } from '../types';
import { TradeFormMountChecker } from '../../TradeFormMountChecker/TradeFormMountChecker.use-client';
import { TradeInputWrapper } from '../../../Reusable/TradeInputWrapper/TradeInputWrapper';
import { BelowInputInfoTable } from '../../../Reusable/BelowInputInfoTable/BelowInputInfoTable';
import { css } from '@bts-web/utils-style-engine';
import { DividerElement } from './DividerElement';
import { VerificationLinkButton } from '../../MainTradeVerificationLinkButton/MainTradeVerificationLinkButton';
import { intlAssetValueFormatting } from '@bts-web/utils-formatting';

export const VerificationLinkForm = memo(
  ({
    formState,
    staticValues,
    currentTradeDetails,
    verifyLink,
  }: {
    formState: ReturnType<typeof useTradeForm>['formState'];
    staticValues: TradeFormProps['staticValues'];
    currentTradeDetails: TradeFormProps['currentTradeDetails'];
    verifyLink: string;
  }) => (
    <>
      <TradeFormMountChecker formValue={formState.inputValue} />

      <TradeInputWrapper inputContextData={formState.inputContextData} />

      <div className={css({ pb: 'medium', pt: 'small' })}>
        <BelowInputInfoTable
          items={[
            {
              title: staticValues.translations['units'],
              value: formState.convertedSummary,
            },
            {
              title: staticValues.translations['minimum'],
              value: intlAssetValueFormatting(
                currentTradeDetails.minimumTradeAmount.FIAT?.value ?? 0,
                { currency: staticValues.currency },
              ),
            },
          ]}
        />
      </div>

      <DividerElement />

      <VerificationLinkButton
        poweredByText={staticValues.translatedValues.poweredByText}
        verifyButtonText={staticValues.translatedValues.verifyButtonText}
        verificationLink={verifyLink}
        verifyTradeInfoText={staticValues.translatedValues.verifyInfoText}
      />
    </>
  ),
);
