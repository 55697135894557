import { css } from '@bts-web/utils-style-engine';
import { BitPandaLogoIcon } from '../../../common/svgIcons/BitPandaLogoIcon/BitPandaLogoIcon';
import { ReadOnlyTag } from '../../../common/components/ReadOnlyTag/ReadOnlyTag';
import {
  MobileLanguageDrawer,
  type MobileLanguageDrawerProps,
} from '../MobileLanguageDrawer/MobileLanguageDrawer';
import { MobileProfileMenu } from '../MobileProfileMenu/MobileProfileMenu';
import { Suspense } from 'react';

export const mobileHeaderWrapperStyles = css({
  backgroundColor: 'screen_background.primary',
  display: 'flex',
  justifyContent: 'space-between',
  py: 'extra_small',
  paddingLeft: 'medium',
  paddingRight: 'medium',
  alignItems: 'center',
});

const MobileHeader = ({
  translations,
}: MobileLanguageDrawerProps): React.ReactElement => {
  return (
    <header className={mobileHeaderWrapperStyles}>
      <div
        className={css({
          display: 'flex',
          alignItems: 'center',
          gap: 'medium',
        })}
      >
        <BitPandaLogoIcon size={22} />

        <ReadOnlyTag />
      </div>

      <div
        className={css({
          display: 'flex',
          gap: 'small',
        })}
      >
        <Suspense fallback={null}>
          <MobileProfileMenu />
        </Suspense>
        <MobileLanguageDrawer translations={translations} />
      </div>
    </header>
  );
};

export { MobileHeader };
