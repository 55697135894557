/* eslint-disable @nx/enforce-module-boundaries */
'use server';

import { redirect } from 'next/navigation';
import { cookies } from 'next/headers';
import {
  getCloudflareHeaders,
  SESSION_COOKIE_NAME,
  VERCEL_APP_SESSION_COOKIE_NAME,
} from '@bts-web/utils-next-infrastructure';

const LOGOUT_SUCCESS_MESSAGE = 'successfully logged out';

export const logoutAction = async () => {
  const cookieStore = cookies();

  const cloudflareHeaders = getCloudflareHeaders();

  const sessionIdCookie = cookieStore.get(SESSION_COOKIE_NAME);

  if (!sessionIdCookie) {
    return;
  }

  const logoutResponse = await fetch(
    `${process.env.NEXT_PUBLIC_GQL_API_ENDPOINT_URL}/auth/logout`,
    {
      method: 'POST',
      credentials: 'include',
      headers: {
        ...cloudflareHeaders,
        cookie: `${SESSION_COOKIE_NAME}=${sessionIdCookie.value}`,
      },
    },
  );

  const json = await logoutResponse.json();

  if (
    logoutResponse.status === 200 &&
    json.message === LOGOUT_SUCCESS_MESSAGE
  ) {
    cookieStore.delete(SESSION_COOKIE_NAME);

    cookieStore.delete(VERCEL_APP_SESSION_COOKIE_NAME);

    if (json.logout_redirect_url) {
      redirect('/auth/logout');
    }
  }

  redirect('/');
};
