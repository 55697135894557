import { PropsWithChildren, ReactElement, forwardRef } from 'react';

export interface ListCellsSelectionItemContainerProps
  extends PropsWithChildren {
  className?: string;
  label: string;
  subLabel?: string;
  itemId?: string;
  icon?: ReactElement;
}

const ListCellsSelectionItemContainer = forwardRef<
  HTMLDivElement,
  ListCellsSelectionItemContainerProps
>(
  (
    {
      className,
      label,
      subLabel,
      itemId,
      children,
      icon,
    }: ListCellsSelectionItemContainerProps,
    ref,
  ) => {
    return (
      <div
        ref={ref}
        data-testid="list-cells-selection-item-container"
        className={className}
      >
        <label htmlFor={itemId}>
          <span>
            {icon} {label}
          </span>
          <span>{subLabel}</span>
        </label>
        <div className="item-slot">{children}</div>
      </div>
    );
  },
);

export { ListCellsSelectionItemContainer };
