// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Args = any[];

const debounce = (callback: (...args: Args) => void, delay: number) => {
  let timeout: NodeJS.Timeout;

  return (...args: Args) => {
    clearTimeout(timeout);

    timeout = setTimeout(() => callback.apply({}, args), delay);
  };
};

export { debounce };
