'use client';

import { useEffect } from 'react';
import { useSearchParams } from 'next/navigation';
import { Drawer, useDrawer } from '../../../layouts';
import { TradeOptionsDrawerContent } from './TradeOptionsDrawerContent';
import {
  toggleTrade,
  tradeDrawerKey,
  tradeDrawerShowQueryParamValue,
} from '../TradeProvider/utils';
import { TradeInnerTranslatedValues } from '../TradeProvider/TradeProvider';

interface TradeOptionsDrawerProps {
  isSellButtonVisible: boolean;
  onClickBuyOption: () => void;
  onClickSellOption: () => void;
  variant?: 'primary';
  fullScreen?: boolean;
  translatedValues: TradeInnerTranslatedValues;
}

const TRADE_SHEET_ID = `trade-sheet`;

const TradeOptionsDrawer = ({
  isSellButtonVisible,
  onClickBuyOption,
  onClickSellOption,
  translatedValues,
}: TradeOptionsDrawerProps) => {
  const searchParams = useSearchParams();

  const { openDrawer, closeDrawer } = useDrawer();

  const { buyButtonText, sellButtonText, title } = translatedValues;

  const isTradeDrawerKey = searchParams.get(tradeDrawerKey);

  useEffect(() => {
    const isTradeDrawerOpen =
      isTradeDrawerKey === tradeDrawerShowQueryParamValue;

    if (isTradeDrawerOpen) {
      openDrawer(TRADE_SHEET_ID);
    } else {
      closeDrawer(TRADE_SHEET_ID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTradeDrawerKey]);

  return (
    <Drawer
      onClose={toggleTrade}
      id={TRADE_SHEET_ID}
      position="bottom"
      contentNode={
        <TradeOptionsDrawerContent
          labels={{
            buy: buyButtonText,
            sell: sellButtonText,
            title,
          }}
          isSellButtonVisible={isSellButtonVisible}
          onClickBuyOption={onClickBuyOption}
          onClickSellOption={onClickSellOption}
        />
      }
    />
  );
};

export { TradeOptionsDrawer };
