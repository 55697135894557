/**
 * @generated SignedSource<<82cf58859a3dbaac043765c5ecb5dc2e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AssetByIdViewQuery$variables = {
  id?: string | null;
};
export type AssetByIdViewQuery$data = {
  readonly assetById: {
    readonly __typename: string;
    readonly description: string | null;
    readonly id: string;
    readonly isBuyActive: boolean | null;
    readonly isSellActive: boolean | null;
    readonly legalUrl: string | null;
    readonly logoUrl: string | null;
    readonly marketCap: number | null;
    readonly minimumBuyAmount: PrecisionFloat | null;
    readonly minimumSellAmount: PrecisionFloat | null;
    readonly monthlyVolatility: number | null;
    readonly name: string | null;
    readonly portfolio: {
      readonly assetBalance: PrecisionFloat | null;
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly assets: {
            readonly edges: ReadonlyArray<{
              readonly assetBalance: PrecisionFloat | null;
              readonly totalInvested: PrecisionFloat | null;
              readonly totalReturn: ValueChange | null;
            } | null> | null;
          } | null;
        } | null;
      } | null> | null;
      readonly fiatBalance: PrecisionFloat | null;
    } | null;
    readonly price: PrecisionFloat | null;
    readonly price52WeeksHigh: PrecisionFloat | null;
    readonly price52WeeksLow: PrecisionFloat | null;
    readonly priceBuy: PrecisionFloat | null;
    readonly priceChange1Month: ValueChange | null;
    readonly priceChange1Week: ValueChange | null;
    readonly priceChange1Year: ValueChange | null;
    readonly priceChange24Hours: ValueChange | null;
    readonly priceChange5Years: ValueChange | null;
    readonly priceDailyHigh: PrecisionFloat | null;
    readonly priceDailyLow: PrecisionFloat | null;
    readonly priceSell: PrecisionFloat | null;
    readonly symbol: string | null;
  } | null;
};
export type AssetByIdViewQuery = {
  response: AssetByIdViewQuery$data;
  variables: AssetByIdViewQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "assetBalance",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "assetById",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "symbol",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "logoUrl",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "price",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "marketCap",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "legalUrl",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "priceDailyHigh",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "priceDailyLow",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "price52WeeksHigh",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "price52WeeksLow",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "monthlyVolatility",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "priceChange24Hours",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "priceChange1Week",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "priceChange1Month",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "priceChange1Year",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "priceChange5Years",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "priceSell",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "priceBuy",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "minimumBuyAmount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "minimumSellAmount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isBuyActive",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isSellActive",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AssetPortfoliosConnection",
        "kind": "LinkedField",
        "name": "portfolio",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fiatBalance",
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "AssetPortfoliosEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Portfolio",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PortfolioAssetsConnection",
                    "kind": "LinkedField",
                    "name": "assets",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PortfolioAssetsEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "totalReturn",
                            "storageKey": null
                          },
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "totalInvested",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AssetByIdViewQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AssetByIdViewQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "dfdfab01b9d49755da4a5b99106a6c99",
    "id": null,
    "metadata": {},
    "name": "AssetByIdViewQuery",
    "operationKind": "query",
    "text": "query AssetByIdViewQuery(\n  $id: ID\n) {\n  assetById(id: $id) {\n    id\n    name\n    symbol\n    logoUrl\n    price\n    description\n    marketCap\n    legalUrl\n    priceDailyHigh\n    priceDailyLow\n    price52WeeksHigh\n    price52WeeksLow\n    monthlyVolatility\n    priceChange24Hours\n    priceChange1Week\n    priceChange1Month\n    priceChange1Year\n    priceChange5Years\n    priceSell\n    priceBuy\n    minimumBuyAmount\n    minimumSellAmount\n    isBuyActive\n    isSellActive\n    portfolio {\n      fiatBalance\n      assetBalance\n      edges {\n        node {\n          assets {\n            edges {\n              totalReturn\n              assetBalance\n              totalInvested\n            }\n          }\n        }\n      }\n    }\n    __typename\n  }\n}\n"
  }
};
})();

(node as any).hash = "9dab2c4757a28d49ceb6749a2692319c";

export default node;
