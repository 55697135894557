'use client';

import {
  ReactElement,
  ReactNode,
  createContext,
  useContext,
  useState,
} from 'react';
import { DialogProps } from '@bts-web/design-system/component/dialog';

export type BaseModalProps = {
  onClose: () => void;
};

export type ModalComponent<T = object> = (
  props: BaseModalProps & T,
) => ReactElement;

export type DialogComponentProps = Omit<DialogProps['external'], 'closeIcon'>;

type ShowModalFunction = {
  <T extends object = object>(
    ModalComponent: ModalComponent<T>,
    additionalProps?: Omit<T, keyof BaseModalProps>,
  ): void;
};

interface ModalContextProps {
  showModal: ShowModalFunction;
  onClose: () => void;
}

const ModalContext = createContext<ModalContextProps | null>(null);

export function useComposableModal() {
  const contextValue = useContext(ModalContext);

  if (contextValue === null) {
    throw new Error(
      'useComposableModal must be used within a ComposableModalContextProvider',
    );
  }

  return contextValue;
}

export const ComposableModalContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [modal, setModal] = useState<ReactElement | null>(null);

  const showModal: ShowModalFunction = (
    ModalComponent,
    additionalProps = {} as unknown,
  ) => {
    const handleClose = () => setModal(null);

    const modalElement = (
      <ModalComponent {...(additionalProps as any)} onClose={handleClose} />
    );

    setModal(modalElement);
  };

  const handleClose = () => setModal(null);

  return (
    <ModalContext.Provider value={{ showModal, onClose: handleClose }}>
      {children}
      {modal}
    </ModalContext.Provider>
  );
};
