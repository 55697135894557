import { DatadogErrorLevels } from '@bts-web/utils-next-infrastructure';
import { datadogRum } from '@datadog/browser-rum';

type TDatadogHelperParams = {
  errorMessage: string;
  errorSeverity: DatadogErrorLevels;
};

export const datadogErrorHelper = ({
  errorMessage,
  errorSeverity,
}: TDatadogHelperParams) => {
  datadogRum.addError(new Error(errorMessage), {
    errors: [errorMessage],
    environment: process.env.NEXT_PUBLIC_DATADOG_ENV,
    severity: errorSeverity,
  });
};
