'use client';

import { useFormState, useFormStatus } from 'react-dom';
import { FC } from 'react';
import { DigitCodeInput } from '../DigitCodeInput';
import { ButtonBase } from '../../ButtonBase/ButtonBase';
import { submitCode } from './playgroundUtils';
import { css } from '@bts-web/utils-style-engine';

const DigitCodeInputPlayground: FC = () => {
  const [formState, action] = useFormState(submitCode, {
    message: '',
  });

  return (
    <form
      action={action}
      className={css({
        p: 'medium',
      })}
    >
      <DigitCodeInput parentInputName="digitCode" />
      {formState.message && (
        <div
          className={css({
            p: 'small',
            color: 'positive.text_primary',
            textAlign: 'center',
          })}
        >
          {formState.message}
        </div>
      )}
      <SubmitButton />
    </form>
  );
};

const SubmitButton: FC = () => {
  const { pending } = useFormStatus();

  return (
    <ButtonBase
      type="submit"
      visual={'accent'}
      fullWidth
      isLoading={pending}
      disabled={pending}
    >
      Submit
    </ButtonBase>
  );
};

export { DigitCodeInputPlayground };
