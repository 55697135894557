interface TimelineBarProps {
  labels: Array<string>;
  className: string;
}

const TimelineBar = ({ labels, className }: TimelineBarProps) => (
  <div className={className} data-testid="timeline-bar">
    {labels.map((label) => (
      <span key={label}>{label}</span>
    ))}
  </div>
);

export { TimelineBar };
