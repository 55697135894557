import { BarItem } from '@bts-web/design-system/component/bar-item';
import { Icon } from '@bts-web/design-system/component/icon';
import { css } from '@bts-web/utils-style-engine';

const accessibilityTappableAreaSize = 'small';

export type ThemedToggleDisclaimerAgreeProps = {
  variant?: 'small' | 'medium';
  onPressedChange?: (isOn: boolean) => void;
};

export const ToggleDisclaimerAgree = ({
  variant = 'medium',
  onPressedChange,
}: ThemedToggleDisclaimerAgreeProps) => {
  const toggleSize = {
    small: {
      width: 40,
      height: 24,
    },
    medium: {
      width: 51,
      height: 31,
    },
  }[variant];

  return (
    <BarItem
      aria-label="toggle wrapper"
      className={css({
        padding: accessibilityTappableAreaSize,
        margin: `-${accessibilityTappableAreaSize}`,
        cursor: 'pointer',
        '& [data-element="icon"]': {
          display: 'block',
        },
        '& ::part(background)': {
          fill: 'neutrals.fill_primary',
        },
        '& ::part(knob)': {
          fill: 'neutrals.on_fill_dark',
        },
        '&[data-state="on"][disabled]': {
          '& ::part(background)': {
            fill: 'neutrals.fill_tertiary',
          },
        },
        '&[data-state="off"]': {
          '& ::part(background)': {
            fill: 'neutrals.fill_secondary',
          },
          '& ::part(knob)': {
            transform: 'translateX(-20px)',
          },
        },
        '&[data-state="off"][disabled]': {
          '& ::part(background)': {
            fill: 'dark.neutrals.fill_disabled',
          },
          '& ::part(knob)': {
            fill: 'dark.neutrals.fill_focused',
          },
        },
      })}
      data-testid="toggle-wrapper"
      onPressedChange={onPressedChange}
      icon={<Icon icon="toggle" {...toggleSize} data-element="icon" />}
    />
  );
};
