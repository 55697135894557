/**
 * @generated SignedSource<<fc8b001ef8b1995066495a3437425c5f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AssetsListGroup = "COIN" | "ETC" | "ETF" | "INDEX" | "METAL" | "STOCK" | "TOKEN";
export type AssetsListSortBy = "MARKETCAP" | "PRICE" | "PRICE_CHANGE_1MONTH" | "PRICE_CHANGE_1WEEK" | "PRICE_CHANGE_1YEAR" | "PRICE_CHANGE_24HOURS";
export type SortOrder = "ASC" | "DESC";
export type AssetsViewQuery$variables = {
  after?: string | null;
  first: number;
  groups?: ReadonlyArray<AssetsListGroup> | null;
  searchTerm?: string | null;
  sortBy?: AssetsListSortBy | null;
  sortOrder?: SortOrder | null;
};
export type AssetsViewQuery$data = {
  readonly assets: {
    readonly __typename: "AssetsConnection";
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly __typename: string;
        readonly id: string;
        readonly legalUrl: string | null;
        readonly logoUrl: string | null;
        readonly name: string | null;
        readonly price: PrecisionFloat | null;
        readonly price52WeeksHigh: PrecisionFloat | null;
        readonly price52WeeksLow: PrecisionFloat | null;
        readonly priceChange1Month: ValueChange | null;
        readonly priceChange1Week: ValueChange | null;
        readonly priceChange1Year: ValueChange | null;
        readonly priceChange24Hours: ValueChange | null;
        readonly priceDailyHigh: PrecisionFloat | null;
        readonly priceDailyLow: PrecisionFloat | null;
        readonly symbol: string | null;
      };
    } | null> | null;
    readonly pageInfo: {
      readonly endCursor: string | null;
      readonly hasNextPage: boolean;
    } | null;
  } | null;
};
export type AssetsViewQuery = {
  response: AssetsViewQuery$data;
  variables: AssetsViewQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "groups"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchTerm"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sortBy"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sortOrder"
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v7 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "after",
            "variableName": "after"
          },
          {
            "kind": "Variable",
            "name": "first",
            "variableName": "first"
          },
          {
            "kind": "Variable",
            "name": "groups",
            "variableName": "groups"
          },
          {
            "kind": "Variable",
            "name": "searchTerm",
            "variableName": "searchTerm"
          },
          {
            "kind": "Variable",
            "name": "sortBy",
            "variableName": "sortBy"
          },
          {
            "kind": "Variable",
            "name": "sortOrder",
            "variableName": "sortOrder"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "AssetsConnection",
    "kind": "LinkedField",
    "name": "assets",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AssetsEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "symbol",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "logoUrl",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "price",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "priceChange1Week",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "priceChange1Year",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "priceChange1Month",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "priceChange24Hours",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "priceDailyHigh",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "priceDailyLow",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "price52WeeksLow",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "price52WeeksHigh",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "legalUrl",
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "PageInfo",
        "kind": "LinkedField",
        "name": "pageInfo",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "endCursor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasNextPage",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v6/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AssetsViewQuery",
    "selections": (v7/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "AssetsViewQuery",
    "selections": (v7/*: any*/)
  },
  "params": {
    "cacheID": "2c4a1e2160399e0aa73e50d5efe1f297",
    "id": null,
    "metadata": {},
    "name": "AssetsViewQuery",
    "operationKind": "query",
    "text": "query AssetsViewQuery(\n  $after: String\n  $first: Int!\n  $sortBy: AssetsListSortBy\n  $sortOrder: SortOrder\n  $searchTerm: String\n  $groups: [AssetsListGroup!]\n) {\n  assets(input: {after: $after, first: $first, sortBy: $sortBy, sortOrder: $sortOrder, searchTerm: $searchTerm, groups: $groups}) {\n    edges {\n      node {\n        id\n        name\n        symbol\n        logoUrl\n        price\n        priceChange1Week\n        priceChange1Year\n        priceChange1Month\n        priceChange24Hours\n        priceDailyHigh\n        priceDailyLow\n        price52WeeksLow\n        price52WeeksHigh\n        legalUrl\n        __typename\n      }\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n    __typename\n  }\n}\n"
  }
};
})();

(node as any).hash = "91864d89011addeeca89551ac5c97d27";

export default node;
