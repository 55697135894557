/**
 * @generated SignedSource<<6eaddebaaa1d51475372459268ee5ff7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type TradeOfferViewQuery$variables = {
  offerId: string;
};
export type TradeOfferViewQuery$data = {
  readonly tradeOffer: {
    readonly asset: {
      readonly __typename: string;
      readonly id: string;
      readonly legalUrl: string | null;
      readonly logoUrl: string | null;
      readonly name: string | null;
      readonly portfolio: {
        readonly assetBalance: PrecisionFloat | null;
        readonly fiatBalance: PrecisionFloat | null;
      } | null;
      readonly priceBuy: PrecisionFloat | null;
      readonly priceSell: PrecisionFloat | null;
      readonly symbol: string | null;
    };
    readonly assetAmount: PrecisionFloat;
    readonly expiresAt: any;
    readonly fiatAmount: PrecisionFloat;
    readonly hasTaxOfflineWarning: boolean | null;
    readonly id: string;
    readonly taxAmount: PrecisionFloat | null;
    readonly totalAmount: PrecisionFloat | null;
  } | null;
};
export type TradeOfferViewQuery = {
  response: TradeOfferViewQuery$data;
  variables: TradeOfferViewQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "offerId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "offerId"
      }
    ],
    "concreteType": "TradeOffer",
    "kind": "LinkedField",
    "name": "tradeOffer",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "asset",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "symbol",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "logoUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "priceSell",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "priceBuy",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "legalUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AssetPortfoliosConnection",
            "kind": "LinkedField",
            "name": "portfolio",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "assetBalance",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fiatBalance",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasTaxOfflineWarning",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "fiatAmount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "assetAmount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalAmount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "taxAmount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "expiresAt",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TradeOfferViewQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TradeOfferViewQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "edd994a7e2e6ef104da20d3b84918aff",
    "id": null,
    "metadata": {},
    "name": "TradeOfferViewQuery",
    "operationKind": "query",
    "text": "query TradeOfferViewQuery(\n  $offerId: ID!\n) {\n  tradeOffer(id: $offerId) {\n    id\n    asset {\n      id\n      symbol\n      logoUrl\n      name\n      priceSell\n      priceBuy\n      legalUrl\n      portfolio {\n        assetBalance\n        fiatBalance\n      }\n      __typename\n    }\n    hasTaxOfflineWarning\n    fiatAmount\n    assetAmount\n    totalAmount\n    taxAmount\n    expiresAt\n  }\n}\n"
  }
};
})();

(node as any).hash = "f3e89ab1b0ff400961461cc0d54b2583";

export default node;
