'use client';

import { useState, useEffect } from 'react';
import Highcharts, { Options, SeriesOptionsType } from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import HighchartsAccessibility from 'highcharts/modules/accessibility';

type DonutDataSeriesEntry = {
  y: number;
};

export interface DonutChartProps {
  internal: {
    className: string;
  };
  external: {
    seriesData: DonutDataSeriesEntry[];
  };
}

const staticChartOptions: Options = {
  chart: {
    type: 'pie',
    styledMode: true,
  },
  title: {
    text: undefined,
  },
  series: [
    {
      type: 'pie',
      dataLabels: {
        enabled: false,
      },
      innerSize: '90%',
    },
  ],
  plotOptions: {
    series: {
      enableMouseTracking: false,
    },
    pie: {
      size: 120,
      borderRadius: 5,
    },
  },
  credits: {
    enabled: false,
  },
};

const DonutChart = ({
  className,
  seriesData,
}: DonutChartProps['internal'] & DonutChartProps['external']) => {
  if (typeof Highcharts === 'object') {
    HighchartsAccessibility(Highcharts);
  }

  const [chartOptions, setChartOptions] = useState<Options>(staticChartOptions);

  useEffect(() => {
    setChartOptions({
      ...staticChartOptions,
      series: [
        {
          ...staticChartOptions?.series?.[0],
          data: seriesData,
        } as SeriesOptionsType,
      ],
    });
  }, [seriesData]);

  return (
    <div className={className} data-testid="donut-chart-container">
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOptions}
        allowChartUpdate={true}
      />
    </div>
  );
};

export { DonutChart };
