const initialPortfolioTranslations = {
  viewAllTransactions: 'view_all_transactions',
  transactionDescription: 'transaction_description',
  emptyStateNoTransactions: 'empty_state_no_transactions',
  latestTransactions: 'dashboard_portfolio_latest_transactions',
  viewRelatedTransactions: 'view_related_transactions',
  latestTransactionsDescription: 'portfolio_latest_transaction_description',
  amount: 'amount',
};

export type WidgetTranslationsKeys = {
  viewAllTransactions: string;
  emptyStateNoTransactions: string;
  amount: string;
  transactionDescription: string;
  latestTransactionsDescription: string;
  viewRelatedTransactions: string;
  latestTransactions: string;
};

export const getTransactionsWidgetTranslations = (
  t: (translationKey: keyof WidgetTranslationsKeys) => string,
): WidgetTranslationsKeys => {
  return Object.entries(initialPortfolioTranslations).reduce(
    (acc, [key, value]) => {
      acc[key as keyof WidgetTranslationsKeys] = t(
        value as keyof WidgetTranslationsKeys,
      );

      return acc;
    },
    {} as WidgetTranslationsKeys,
  );
};
