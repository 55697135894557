'use client';

import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useState,
} from 'react';

export type DrawerStateProps = {
  id: string;
  open: boolean;
};

export type IDrawerContext = {
  openDrawer: (id: DrawerStateProps['id']) => void;
  closeDrawer: (id: DrawerStateProps['id']) => void;
  drawerState: DrawerStateProps;
};

const DrawerContext = createContext<IDrawerContext | null>(null);

export const DrawerContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [drawerState, setDrawerState] = useState<DrawerStateProps>({
    open: false,
    id: '',
  });

  const handleDrawerClose = (id: DrawerStateProps['id']) =>
    setDrawerState({ open: false, id });

  const handleOpenDrawer = (id: DrawerStateProps['id']) =>
    setDrawerState({ open: true, id });

  return (
    <DrawerContext.Provider
      value={{
        openDrawer: handleOpenDrawer,
        closeDrawer: handleDrawerClose,
        drawerState,
      }}
    >
      {children}
    </DrawerContext.Provider>
  );
};

export const useDrawer = () => {
  const context = useContext(DrawerContext);

  if (!context) {
    throw new Error('useDrawer must be used within a DrawerContext');
  }

  return context;
};
