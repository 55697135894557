'use client';

import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { css } from '@bts-web/utils-style-engine';
import { TradeScreenTranslations } from '../../../../../../../utils';
import { LegalDocumentsTermLabel } from './LegalDocumentsTermLabel';
import { AcceptOfferTerm } from '../AcceptOfferTerm.use-client';

interface AcceptOfferStockEtfEtcEligibleTermsProps {
  translations: TradeScreenTranslations;
  legalDocumentUrl: string;
  setTermsAgreed: Dispatch<SetStateAction<boolean>>;
}

const AcceptOfferStockEtfEtcNonEligibleTerms = ({
  translations,
  legalDocumentUrl,
  setTermsAgreed,
}: AcceptOfferStockEtfEtcEligibleTermsProps) => {
  const [isFirstTermAgreed, setIsFirstTermAgreed] = useState(false);

  const [isSecondTermAgreed, setIsSecondTermAgreed] = useState(false);

  useEffect(() => {
    const areBothTermsAgreed = isFirstTermAgreed && isSecondTermAgreed;

    setTermsAgreed(areBothTermsAgreed);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFirstTermAgreed, isSecondTermAgreed]);

  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        gap: 'small',
        alignItems: 'flex-start',
        overflowX: 'hidden',
        mt: 'large',
      })}
      data-testid="buy-terms-non-eligible"
      aria-label="buy terms non eligible"
    >
      <AcceptOfferTerm
        onToggleCallback={() => setIsFirstTermAgreed((state) => !state)}
        label={
          <LegalDocumentsTermLabel
            translations={translations}
            links={{
              derivativeContractLink: legalDocumentUrl,
              prospectusLink: legalDocumentUrl,
              keyInformationDocumentLink: legalDocumentUrl,
              investorInformationDocumentLink: legalDocumentUrl,
              costTransparencyDocumentsLink: legalDocumentUrl,
            }}
          />
        }
        data-testid="documents"
      />

      <AcceptOfferTerm
        onToggleCallback={() => setIsSecondTermAgreed((state) => !state)}
        label={translations.investingRiskToggle}
        data-testid="risk"
      />

      <p
        className={css({
          color: 'neutrals.text_primary',
          textAlign: 'left',
          fontSize: 'body.medium',
          fontWeight: 'body.medium',
          letterSpacing: 'body.medium',
          lineHeight: 'body.medium',
        })}
      >
        {translations.stocksToggleNotAppropriate}
      </p>
    </div>
  );
};

export { AcceptOfferStockEtfEtcNonEligibleTerms };
