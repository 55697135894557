import { FC, ReactNode, Suspense } from 'react';
import { css } from '@bts-web/utils-style-engine';
import {
  DesktopLeftNav,
  TranslatedValuesLeftNavType,
} from '../DesktopLeftNav/DesktopLeftNav';
import { MobileHeader } from '../MobileHeader/MobileHeader';
import { DesktopTopHeaderBar } from '../DesktopTopHeaderBar/DesktopTopHeaderBar';
import { DesktopTopHeaderBarSkeleton } from '../DesktopTopHeaderBar/DesktopTopHeaderBarSkeleton';
import { DesktopLeftNavSkeleton } from '../DesktopLeftNav/DesktopLeftNavSkeleton';
import { LayoutTitleElement, TitleUnionType } from './LayoutTitleElement';
import { HiddenDesktop } from '../Reusable/HiddenDesktop/HiddenDesktop';
import { HiddenMobile } from '../Reusable/HiddenMobile/HiddenMobile';
import { MobileNavWithTrade } from '../MobileNavWithTrade/MobileNavWithTrade';
import { DesktopDisclaimerFooter } from '../DesktopDisclaimerFooter/DesktopDisclaimerFooter';
import {
  initServerTranslation,
  updateServerLanguage,
} from '@bts-web/utils-lokalise';
import { TFunction } from 'i18next';
import { getDesktopHeaderBarTranslations } from '../DesktopTopHeaderBar/utils/getDesktopHeaderBarTranslations';

/**
 * `AppMainLayoutWrapper` is a functional component that provides the main layout for the application.
 *
 * @component
 * @param {object} props - The properties that define the component's behavior and display.
 * @param {(initialKey: string, otherParams?: unknown) => string} props.translationUtil - A utility function for translations.
 * @param {ReactNode} [props.children] - The child components to be rendered within the layout.
 * @param {TitleUnionType} props.title - Object that defines the title of the page, it supports breadcrumbs and string
 * @param {object} [props.settings] - Additional settings for the layout.
 * @param {boolean} [props.settings.mobileBottomNav] - Determines if the bottom navigation should be displayed on mobile view.
 * @param {boolean} [props.settings.hideMobileHeader] - Determines if the mobile header should be hidden.
 * @param {boolean} [props.settings.noMainElementPaddingBottom] - Determines if the main element should have no padding at the bottom, this is for usage in conjunction with AssetDetailTradeWrapper
 * @param {boolean} [props.settings.hideTopBarTradeButton] - Determines if the trade button in the top bar should be hidden, this is for trade pages where the trade process is in progress
 * @param {boolean} [props.settings.hideDesktopFooter] - Determines if the desktop footer should be hidden, used in the pages where the desktop view contains both a trade element and an asset detail element
 * @param {boolean} [props.settings.fullPageBackdropMode] - Makes the layout overlay the entire page, used for modals
 * @example
 * return (
 *   <AppMainLayoutWrapper
 *     translationUtil={translate}
 *     title="Home"
 *     settings={{
 *       mobileBottomNav: true,
 *       hideMobileHeader: false,
 *       noMainElementPaddingBottom: true,
 *       hideTopBarTradeButton: false,
 *       hideDesktopFooter: false,
 *     }}
 *   >
 *     <Home />
 *   </AppMainLayoutWrapper>
 * );
 *
 * @returns {ReactElement} The rendered `AppMainLayoutWrapper` component.
 */
const { t: translationUtil } = initServerTranslation();

export const AppMainLayoutWrapper: FC<{
  children?: ReactNode;
  title: TitleUnionType;
  settings?: {
    mobileBottomNav?: boolean;
    hideMobileHeader?: boolean;
    noMainElementPaddingBottom?: boolean;
    hideTopBarTradeButton?: boolean;
    hideDesktopFooter?: boolean;
    fullPageBackdropMode?: boolean;
  };
}> = async ({ children, title, settings }) => {
  await updateServerLanguage();

  const {
    mobileBottomNav,
    hideMobileHeader,
    noMainElementPaddingBottom,
    hideTopBarTradeButton,
    hideDesktopFooter,
    fullPageBackdropMode,
  } = settings ?? {};

  const translatedValues: TranslatedValuesLeftNavType & {
    buySellButtonText: string;
    buttonTransferTopNav: string;
    tradeText: string;
    readOnly: string;
    deposit: string;
    send: string;
    germanSubtitle: string;
    englishSubtitle: string;
    errorTitle: string;
    errorSubtitle: string;
    languageTitle: string;
  } = {
    discoverText: translationUtil('section_discover'),
    portfolioText: translationUtil('nav_bar_portfolio'),
    buttonTransferTopNav: `${translationUtil('button_transfer_top_nav')}`,
    buySellButtonText: `${translationUtil('button_trade_top_nav')}`,
    tradeText: translationUtil('nav_bar_trade'),
    readOnly: translationUtil('user_role_info_read_only'),
    deposit: translationUtil('deposit'),
    send: translationUtil('send'),
    germanSubtitle: translationUtil('select_language_de'),
    englishSubtitle: translationUtil('select_language_en'),
    errorTitle: translationUtil('internal_error_headline'),
    errorSubtitle: translationUtil('info_toast_asset_unavailable_subtitle'),
    languageTitle: translationUtil('language'),
  };

  return (
    <div
      className={css({
        minHeight: '100vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: { base: undefined, lg: 'neutrals.widget_primary' },
        paddingLeft: {
          base: undefined,
          lg: `calc(var(--spacing-extra_small_4) + 73px)`,
        },
        paddingTop: {
          base: undefined,
          lg: `calc(var(--spacing-extra_small_4) + 72px)`,
        },
        height: fullPageBackdropMode ? '100vh' : undefined,
        overflow: fullPageBackdropMode ? 'hidden' : undefined,
      })}
    >
      {!hideMobileHeader && (
        <HiddenDesktop>
          <MobileHeader
            translations={{
              englishSubtitle: translatedValues.englishSubtitle,
              germanSubtitle: translatedValues.germanSubtitle,
              errorSubtitle: translatedValues.errorSubtitle,
              errorTitle: translatedValues.errorTitle,
              title: translatedValues.languageTitle,
            }}
          />
        </HiddenDesktop>
      )}

      <HiddenMobile>
        <Suspense fallback={<DesktopTopHeaderBarSkeleton />}>
          <DesktopTopHeaderBar
            hideTopBarTradeButton={hideTopBarTradeButton ?? false}
            pageTitleElement={<LayoutTitleElement title={title} />}
            translatedValues={{
              ...getDesktopHeaderBarTranslations(translationUtil as TFunction),
              buySellButtonText: translatedValues.buySellButtonText,
              buttonTransferTopNav: translatedValues.buttonTransferTopNav,
              deposit: translatedValues.deposit,
              send: translatedValues.send,
              englishSubtitle: translatedValues.englishSubtitle,
              germanSubtitle: translatedValues.germanSubtitle,
              errorTitle: translatedValues.errorTitle,
              errorSubtitle: translatedValues.errorSubtitle,
            }}
          />
        </Suspense>
      </HiddenMobile>

      <HiddenMobile>
        <Suspense fallback={<DesktopLeftNavSkeleton />}>
          <DesktopLeftNav translatedValues={translatedValues} />
        </Suspense>
      </HiddenMobile>

      <main
        style={{
          paddingBottom: noMainElementPaddingBottom ? 0 : undefined,
        }}
        className={css({
          flex: 1,
          width: '1440px',
          maxWidth: '100%',
          mx: 'auto',
          bgColor: 'neutrals.card_fill_primary',
          pb: {
            base: mobileBottomNav ? 'var(--bottom-tabbar-spacing)' : 'medium',
            lg: 'medium',
          },
        })}
      >
        {children}
      </main>

      {mobileBottomNav ? (
        <HiddenDesktop>
          <MobileNavWithTrade
            labels={{
              discover: translatedValues.discoverText,
              portfolio: translatedValues.portfolioText,
              trade: translatedValues.tradeText,
            }}
          />
        </HiddenDesktop>
      ) : null}

      {!hideDesktopFooter && (
        <DesktopDisclaimerFooter
          translationUtil={translationUtil as TFunction}
        />
      )}
    </div>
  );
};
