'use client';

import { useEffect, useState } from 'react';
import Image, { ImageProps } from 'next/image';
import { defaultAssetImg, defaultAssetInlineEncoded } from '../../assets';

type AssetImageProps = Omit<ImageProps, 'src'> & {
  testId?: string;
  src: ImageProps['src'] | undefined;
};

const AssetImage = ({
  alt,
  width,
  height,
  src,
  priority,
  testId = 'asset-image',
  ...props
}: AssetImageProps) => {
  /*
- the useState has been inserted as storage for the URL of the file, to be used in the onError event
- the src ?? defaultAsset ternary operator has been inserted as there are instances in which this component is mounted but the src is undefined initially, but is soon-after defined (ex: apps\raiffeisen\components\layout\Trade\TradeFinishLayout\TradeFinishLayout.tsx component, in which the value might or might not come, or might not come at mount-time but on the next tick)
- the useEffect hook has been inserted to handle the above case, and update the value accordingly
- do not select to modify the <img src /> of the <Image /> element directly since it breaks the relationship in between the VirtualDOM and the DOM and caching mechanisms
- the placeholder element has been inserted for the circumstance in which the src URL file is valid, working, but slowly loading
*/
  const [imageSrc, setImageSrc] = useState<string>(() =>
    src ? String(src) : defaultAssetImg.src,
  );

  useEffect(() => {
    setImageSrc(src ? String(src) : defaultAssetImg.src);
  }, [src]);

  return (
    <Image
      {...props}
      width={width}
      height={height}
      alt={alt}
      title={alt}
      data-testid={testId}
      src={imageSrc || defaultAssetImg.src}
      unoptimized
      priority={priority}
      style={{
        objectFit: 'contain',
      }}
      onError={() => {
        if (imageSrc !== defaultAssetImg.src) {
          setImageSrc(defaultAssetImg.src);
        }
      }}
      placeholder={defaultAssetInlineEncoded}
    />
  );
};

export { AssetImage };
