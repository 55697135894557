import { PortfolioViewQuery$data } from '@bts-web/data-layer/server';

export type NonNullablePortfolioResponse = NonNullable<
  NonNullable<PortfolioViewQuery$data>['portfolio']
>;

export enum AssetTypeName {
  COIN = 'coin',
  METAL = 'metal',
  ETF = 'etf',
  ETC = 'etc',
  STOCK = 'stock',
}
