import { FC, ReactNode, Suspense } from 'react';
import Link from 'next/link';
import dynamic from 'next/dynamic';
import { css } from '@bts-web/utils-style-engine';
import { getAppConfig } from '@bts-web/core-app-config';
import { Icon } from '@bts-web/design-system/component/icon';
import { BuySellButton } from './subcomponents/BuySellButton';
import { BitPandaLogoIcon } from '../../../common/svgIcons/BitPandaLogoIcon/BitPandaLogoIcon';
import { ReadOnlyTag } from '../../../common/components/ReadOnlyTag/ReadOnlyTag';
import { TopBarMenuButton } from './subcomponents/TopBarMenuButton/TopBarMenuButton';
import { type IMenuProps, Menu } from '../../../common/components/Menu/Menu';
import { ITopDesktopHeaderBarTranslatedValues } from './types';
import { Drawer } from '../../Drawer/Drawer';
import { ButtonBase } from '../../../common/components/ButtonBase/ButtonBase';
import { TransfersSkeleton } from '../../../crypto-transfers/components/TransfersParent/TransfersSkeleton';
import { UserTopBarMenu } from './subcomponents/UserTopBarMenu/UserTopBarMenu';
import { TopbarLanguageMenuWrapper } from './subcomponents/TopbarLanguageMenuWrapper/TopbarLanguageMenuWrapper';
import { i18n, Language } from '@bts-web/utils-lokalise';

export const appHeaderWrapperStyles = {
  display: 'flex',
  bgColor: 'neutrals.card_fill_primary',
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  zIndex: 2,
  mb: 'extra_small_2',
  minHeight: `71px`,
  maxHeight: '71px',
};

const dropdownMenuProps: IMenuProps['dropdownMenuProps'] = {
  style: {
    width: '340px',
    maxWidth: `calc(100vw - 40px)`,
    backgroundColor: 'transparent',
    borderRadius: '12px',
  },
  align: 'end',
  sideOffset: 5,
  sticky: 'always',
  updatePositionStrategy: 'always',
};

const TransfersLoaderDynamic = dynamic(
  () =>
    import(
      '../../../crypto-transfers/components/TransfersLoader/TransfersLoader'
    ).then((comp) => comp.TransfersLoader),
  {
    loading: () => <TransfersSkeleton />,
  },
);

export const DesktopTopHeaderBar: FC<{
  pageTitleElement: ReactNode;
  hideTopBarTradeButton: boolean;
  translatedValues: ITopDesktopHeaderBarTranslatedValues & {
    buySellButtonText: string;
    buttonTransferTopNav: string;
    deposit: string;
    send: string;
    germanSubtitle: string;
    englishSubtitle: string;
    errorTitle: string;
    errorSubtitle: string;
  };
}> = ({ pageTitleElement, hideTopBarTradeButton, translatedValues }) => {
  const { feature_hasCryptoTransfersEnabled } = getAppConfig();

  const defaultSelectedLanguage = i18n.resolvedLanguage as Language;

  return (
    <header
      className={`compensateMarginRightWhenScrollingIsBlocked ${css(appHeaderWrapperStyles)}`}
      aria-label="desktop header bar"
    >
      <div
        className={css({
          p: '20px',
          pr: '20px',
          borderRightWidth: '1px',
          borderColor: 'neutrals.fill_quaternary',
          maxWidth: '72px',
        })}
      >
        <Link
          aria-label="bitpanda logo"
          href={`/`}
          className={css({
            width: '32px',
            height: '32px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          })}
        >
          <BitPandaLogoIcon size={22} />
        </Link>
      </div>

      <div
        className={css({
          display: 'flex',
          alignItems: 'center',
          borderBottom: '1px solid',
          borderColor: 'neutrals.stroke_secondary',
          minHeight: '100%',
          flex: 1,
          py: 'extra_small_2',
          px: 'medium',
          gap: 'extra_small_2',
        })}
      >
        <div>{pageTitleElement}</div>

        <ReadOnlyTag />

        <div
          className={css({
            px: 'medium',
            borderRightWidth: '1px',
            borderColor: 'neutrals.fill_quaternary',
            display: 'flex',
            gap: '8px',
            ml: 'auto',
            mr: 'medium',
          })}
        >
          {feature_hasCryptoTransfersEnabled && (
            <Drawer
              id="crypto-transfer-dawer"
              disableOverlayClose
              contentNode={<TransfersLoaderDynamic />}
              buttonTriggerNode={
                <ButtonBase visual="secondary" size="small">
                  {translatedValues.buttonTransferTopNav}
                </ButtonBase>
              }
            />
          )}

          {!hideTopBarTradeButton && (
            <Suspense fallback={null}>
              <BuySellButton>
                {translatedValues.buySellButtonText}
              </BuySellButton>
            </Suspense>
          )}
        </div>

        <div
          className={css({
            display: 'flex',
            gap: 'extra_small_2',
            alignItems: 'center',
          })}
        >
          <TopbarLanguageMenuWrapper
            translations={translatedValues}
            defaultLanguage={defaultSelectedLanguage}
          />
          <Menu
            components={{
              buttonElement: (
                <TopBarMenuButton>
                  <div
                    className={css({
                      backgroundColor: 'neutrals.fill_quaternary',
                      color: 'neutrals.fill_secondary',
                      borderRadius: '100%',
                      p: 'extra_small_2',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    })}
                  >
                    <Icon icon="person" size="24" theme="regular" />
                  </div>
                </TopBarMenuButton>
              ),
              menuContent: (
                <UserTopBarMenu
                  translatedValues={translatedValues}
                  isWithinDropdown
                />
              ),
            }}
            dropdownMenuProps={dropdownMenuProps}
          />
        </div>
      </div>
    </header>
  );
};
