import { type ReactNode } from 'react';
import { stack, css } from '@bts-web/utils-style-engine';
import { type TransactionsViewQuery } from '@bts-web/data-layer/server';
import { type WidgetTranslationsKeys } from './utils/getTransactionsWidgetTranslations';
import { itemslistHeaderStyles } from '../styles';
import { Icon } from '@bts-web/design-system/component/icon';
import { getTransactions } from '../gqlActions';

const transactionsLinkStyles = css({
  gap: 'extra_small_2',
  px: 'medium',
  fontSize: 'small',
  display: 'flex',
  alignItems: 'center',
});

const descriptionStyles = css({
  fontSize: 'body.medium',
  fontWeight: 'body.medium',
  letterSpacing: 'body.medium',
  lineHeight: 'body.medium',
  color: 'neutrals.text_primary',
  paddingX: 'medium',
});

const sectionTitleStyles = css({
  fontSize: 'headline.small_medium',
  fontWeight: 'headline.small_medium',
  letterSpacing: 'headline.small_medium',
  lineHeight: 'headline.small_medium',
  paddingX: 'medium',
});

const DescriptionComponent = ({
  latestTransactionsDescription,
}: {
  latestTransactionsDescription: string;
}) => (
  <span
    data-testid="latest-transactions-description"
    className={descriptionStyles}
  >
    {latestTransactionsDescription}
  </span>
);

type TransactionsWidgetVarinat = 'Portfolio' | 'AssetDetails';

export const TransactionsWidgetController = async ({
  children,
  variant,
  showOnlyRedirectLink = false,
  fetchNumberOfItems,
  assetId,
  components,
  translations,
}: {
  variant: TransactionsWidgetVarinat;
  assetId?: string;
  showOnlyRedirectLink?: boolean;
  fetchNumberOfItems: number;
  components: {
    LinkComponent: ({
      size,
      href,
      children,
    }: {
      size: 'extra_small' | 'small' | 'medium';
      href: string;
      children: ReactNode;
    }) => ReactNode;
  };
  translations: WidgetTranslationsKeys;
  children: (params: {
    responseData: NonNullable<
      TransactionsViewQuery['response']['transactions']
    >['edges'];
  }) => ReactNode;
}) => {
  const { LinkComponent } = components;

  const latestTransactions = await getTransactions({
    input: {
      ...(assetId && { assetId }),
      first: fetchNumberOfItems,
    },
  });

  const transactions =
    (
      latestTransactions?.data?.transactions as NonNullable<
        TransactionsViewQuery['response']['transactions']
      >
    )?.edges ?? [];

  // do not display related transactions if the user do not own any asset in the asset-detail page
  if (assetId && !transactions?.length) {
    return null;
  }

  const renderEmptyState = (
    <span data-testid="empty-transactions" className={descriptionStyles}>
      {translations.emptyStateNoTransactions}
    </span>
  );

  const transactionsLink = (
    <LinkComponent
      size="small"
      href={assetId ? `/transactions?assetId=${assetId}` : '/transactions'}
    >
      <span className={transactionsLinkStyles}>
        {assetId
          ? translations.viewRelatedTransactions
          : translations.viewAllTransactions}
        <Icon size="16" icon="arrow-right" />
      </span>
    </LinkComponent>
  );

  if (showOnlyRedirectLink) {
    return transactionsLink;
  }

  return (
    <div
      className={stack({
        gap: 'small',
        paddingTop: 'medium',
      })}
      aria-label="transactions widget"
    >
      <h2
        className={sectionTitleStyles}
        data-testid="portfolio-latest-transactions"
        aria-label="portfolio latest transactions"
      >
        {variant === 'AssetDetails'
          ? translations.viewRelatedTransactions
          : translations.latestTransactions}
      </h2>

      {transactions?.length === 0 && renderEmptyState}

      {transactions && transactions?.length > 0 && (
        <>
          <div className={itemslistHeaderStyles}>
            <span>{translations.transactionDescription}</span>
            <span>{translations.amount}</span>
          </div>

          <div>
            {children({ responseData: transactions })}
            <div className={css({ py: 'medium' })}>{transactionsLink}</div>
          </div>
        </>
      )}

      {transactions?.length === 0 && (
        <DescriptionComponent
          latestTransactionsDescription={
            translations.latestTransactionsDescription
          }
        />
      )}
    </div>
  );
};
