/**
 * @generated SignedSource<<7d0a7c9f4481048df064aac58c342a79>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type PortfolioPeriod = "DAY" | "MAX" | "MONTH" | "SIX_MONTHS" | "WEEK" | "YEAR";
export type PortfolioAssetGroupsQuery$variables = {
  period: PortfolioPeriod;
};
export type PortfolioAssetGroupsQuery$data = {
  readonly portfolio: {
    readonly assetGroups: {
      readonly edges: ReadonlyArray<{
        readonly name: string;
        readonly node: ReadonlyArray<{
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly id: string;
            } | null;
          } | null> | null;
          readonly totalCount: number | null;
        }> | null;
      }> | null;
    } | null;
  } | null;
};
export type PortfolioAssetGroupsQuery = {
  response: PortfolioAssetGroupsQuery$data;
  variables: PortfolioAssetGroupsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "period"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "period",
        "variableName": "period"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PortfolioAssetGroupsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Portfolio",
        "kind": "LinkedField",
        "name": "portfolio",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PortfolioAssetGroupsConnection",
            "kind": "LinkedField",
            "name": "assetGroups",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PortfolioAssetGroupEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PortfolioAssetsConnection",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PortfolioAssetsEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PortfolioAssetGroupsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Portfolio",
        "kind": "LinkedField",
        "name": "portfolio",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PortfolioAssetGroupsConnection",
            "kind": "LinkedField",
            "name": "assetGroups",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PortfolioAssetGroupEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PortfolioAssetsConnection",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PortfolioAssetsEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "__typename",
                                "storageKey": null
                              },
                              (v4/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b421d3e8ad5b7048fd2e796177dd0443",
    "id": null,
    "metadata": {},
    "name": "PortfolioAssetGroupsQuery",
    "operationKind": "query",
    "text": "query PortfolioAssetGroupsQuery(\n  $period: PortfolioPeriod!\n) {\n  portfolio(input: {period: $period}) {\n    assetGroups {\n      edges {\n        name\n        node {\n          totalCount\n          edges {\n            node {\n              __typename\n              id\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2ebf81ad9bf0fc617bfa3573c467dea5";

export default node;
