export type AssetDetailsTranslations = AssetDetailsGeneralTranslations &
  AssetDetailsAreaChartTranslations &
  AssetDetailsETFTranslations;

export type AssetsDiscoverTranslations = {
  marketCap: string;
  sortByPriceChange: string;
  sortByAssetPrice: string;
  sortByLast24Hours: string;
  sortByLastWeek: string;
  sortByLastMonth: string;
  sortBySixMonths: string;
  sortByOneYear: string;
  sortByFiveYears: string;
  currencyFilterAllAssets: string;
  dashboardPortfolioCrypto: string;
  dashboardPortfolioStocks: string;
  etf: string;
  etc: string;
  dashboardPortfolioMetals: string;
  search_assets: string;
  search: string;
  youOwn: string;
  assetName: string;
  price: string;
};

export type AssetDetailsGeneralTranslations = {
  CryptoAsset: string;
  MetalAsset: string;
  StockAsset: string;
  EtfAsset: string;
  EtcAsset: string;
  FiatEarnAsset: string;
  marketCap: string;
  marketValue: string;
  youOwn: string;
  dailyHigh: string;
  assetFundSize: string;
  yearHigh: string;
  dailyLow: string;
  volatility: string;
  yearLow: string;
  assetDetailOverview: string;
  viewRelatedTransactions: string;
  tradeButton: string;
  readLess: string;
  readMore: string;
  buy: string;
  sell: string;
};

export type AssetDetailsAreaChartTranslations = {
  last24h: string;
  lastWeek: string;
  lastMonth: string;
  lastYear: string;
  maxYears: string;
};

export type AssetDetailsETFTranslations = {
  underlyingAsset: string;
  assetName: string;
  assetProvider: string;
  perAnnum: string;
  assetTotalExpenseRatio: string;
  assetDistributionPolicy: string;
  assetReplicationMethod: string;
  assetIsin: string;
  legalUrl: string;
};

export type StockAssetDetailsTranslations = {
  assetDetailBuy: string;
  assetDetailHold: string;
  assetDetailSell: string;
  assetDividendYield: string;
  assetDividendPerShare: string;
  assetRevenue: string;
  assetPeRatio: string;
  assetNetIncome: string;
  netIncome: string;
  assetDetailAnalystRatingsDescription: string;
  assetDetailAnalysRatingsTitle: string;
  assetDetailAnalystRatingsFactset: string;
  buy: string;
  sell: string;
  hold: string;
  legalUrl: string;
};

export enum AssetType {
  CRYPTO = 'CryptoAsset',
  STOCK = 'StockAsset',
  METAL = 'MetalAsset',
  ETF = 'EtfAsset',
  ETC = 'EtcAsset',
}
