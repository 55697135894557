import { Icon } from '@bts-web/design-system/component/icon';
import { css } from '@bts-web/utils-style-engine';
import { FC } from 'react';

const LanguageChangeMenuItem: FC<{
  disabled?: boolean;
  onChange: () => void;
  title: string;
  subtitle: string;
  active?: boolean;
}> = ({ onChange, subtitle, title, active, disabled }) => {
  return (
    <button
      className={css({
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        p: 'medium',
        gap: 'medium',
        width: '100%',
        cursor: 'pointer',
        minWidth: '200px',
        color: 'neutrals.text_primary',
        backgroundColor: 'neutrals.card_fill_primary',
        '&:hover:not(:disabled)': {
          backgroundColor: 'neutrals.fill_focused',
        },
        '&:disabled': {
          userSelect: 'none',
          cursor: 'not-allowed',
          color: 'neutrals.text_disabled',
          backgroundColor: 'neutrals.fill_focused',
        },
      })}
      disabled={disabled}
      aria-selected={active}
      aria-label={`language option for ${title}`}
      role="option"
      onClick={onChange}
    >
      <div
        className={css({
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          textAlign: 'left',
        })}
      >
        <div
          className={css({
            fontSize: 'title.large',
            color: 'neutrals.text_primary',
          })}
        >
          {title}
        </div>
        <div
          className={css({
            fontSize: 'body.medium',
            color: 'neutrals.text_secondary',
          })}
        >
          {subtitle}
        </div>
      </div>
      <div
        className={css({
          minWidth: '24px',
          color: 'neutrals.text_primary',
        })}
      >
        {active && <Icon icon="checkmark" size="24" color="inherit" />}
      </div>
    </button>
  );
};

export { LanguageChangeMenuItem };
