/**
 * @generated SignedSource<<86d99dc667f2e40c0296c63daf2bffd9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type InitiatedBy = "BITPANDA_CORPORATE_ACTION" | "BITPANDA_MANUAL_TRADE" | "BITPANDA_SAVINGS" | "OTHER" | "USER";
export type TransactionCategory = "CORPORATE_ACTION" | "CRYPTO_ACTION" | "CRYPTO_TRANSFER" | "NON_USER_INITIATED" | "TRADE";
export type TransactionDirection = "INCOMING" | "OUTGOING";
export type TransactionStatus = "CREATED" | "EXPIRED" | "FAILED" | "FINISHED" | "IN_PROGRESS";
export type TransactionType = "BUY" | "SELL";
export type TransactionsListInput = {
  after?: string | null;
  assetId?: string | null;
  before?: string | null;
  category?: TransactionCategory | null;
  first?: number | null;
  last?: number | null;
  type?: TransactionType | null;
};
export type TransactionsViewQuery$variables = {
  input?: TransactionsListInput | null;
};
export type TransactionsViewQuery$data = {
  readonly transactions: {
    readonly edges: ReadonlyArray<{
      readonly cursor: string;
      readonly node: {
        readonly __typename: string;
        readonly asset: {
          readonly __typename: string;
          readonly name: string | null;
          readonly symbol: string | null;
        } | null;
        readonly assetAmount: PrecisionFloat | null;
        readonly assetPrice?: PrecisionFloat | null;
        readonly category: TransactionCategory;
        readonly direction: TransactionDirection | null;
        readonly fiatAmount: PrecisionFloat | null;
        readonly id: string;
        readonly initiatedBy: InitiatedBy | null;
        readonly status: TransactionStatus | null;
        readonly time: any | null;
      } | null;
    } | null> | null;
    readonly pageInfo: {
      readonly endCursor: string | null;
      readonly hasNextPage: boolean;
      readonly hasPreviousPage: boolean;
    };
    readonly totalCount: number | null;
  } | null;
};
export type TransactionsViewQuery = {
  response: TransactionsViewQuery$data;
  variables: TransactionsViewQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasPreviousPage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "time",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "category",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "initiatedBy",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "direction",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fiatAmount",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "symbol",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "assetAmount",
  "storageKey": null
},
v16 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "assetPrice",
    "storageKey": null
  }
],
v17 = {
  "kind": "InlineFragment",
  "selections": (v16/*: any*/),
  "type": "BuyTransaction",
  "abstractKey": null
},
v18 = {
  "kind": "InlineFragment",
  "selections": (v16/*: any*/),
  "type": "SellTransaction",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TransactionsViewQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "TransactionsConnection",
        "kind": "LinkedField",
        "name": "transactions",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "TransactionEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "asset",
                    "plural": false,
                    "selections": [
                      (v13/*: any*/),
                      (v14/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v15/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TransactionsViewQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "TransactionsConnection",
        "kind": "LinkedField",
        "name": "transactions",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "TransactionEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "asset",
                    "plural": false,
                    "selections": [
                      (v13/*: any*/),
                      (v14/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v15/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "83665c14ffdf41f7b04ed2ec58fb27f4",
    "id": null,
    "metadata": {},
    "name": "TransactionsViewQuery",
    "operationKind": "query",
    "text": "query TransactionsViewQuery(\n  $input: TransactionsListInput\n) {\n  transactions(input: $input) {\n    pageInfo {\n      hasNextPage\n      hasPreviousPage\n      endCursor\n    }\n    totalCount\n    edges {\n      cursor\n      node {\n        __typename\n        id\n        time\n        category\n        initiatedBy\n        direction\n        status\n        fiatAmount\n        asset {\n          symbol\n          name\n          __typename\n          id\n        }\n        assetAmount\n        ... on BuyTransaction {\n          assetPrice\n        }\n        ... on SellTransaction {\n          assetPrice\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a3b3f7c7268ec54acba609e3ebdc5d97";

export default node;
