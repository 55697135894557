import { css, flex } from '@bts-web/utils-style-engine';
import { SkeletonElement } from '../../../common';

const Divider = () => (
  <div
    className={css({
      borderBottom: '1px solid',
      borderColor: 'neutrals.divider',
      padding: 0,
    })}
  />
);

export const BuySkeleton = () => (
  <div
    data-testid="trade-loading-skeleton"
    className={flex({
      width: '100%',
      flexDirection: 'column',
      flexShrink: 0,
    })}
  >
    <div
      className={css({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 48,
      })}
    >
      <SkeletonElement width="80px" height="18px" />
    </div>
    <Divider />
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        gap: 'extra_small',
        p: 'medium',
      })}
    >
      <div
        className={flex({
          justifyContent: 'space-between',
          alignItems: 'center',
        })}
      >
        <div className={flex({ gap: 'extra_small_2' })}>
          <SkeletonElement width="24px" height="24px" borderRadius="50%" />
          <SkeletonElement width="90px" height="24px" />
        </div>
        <SkeletonElement width="110px" height="18px" />
      </div>
      <SkeletonElement width="190px" height="46px" />
    </div>
  </div>
);
