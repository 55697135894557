'use client';

import { Icon } from '@bts-web/design-system/component/icon';
import { css } from '@bts-web/utils-style-engine';
import { i18n, type Language } from '@bts-web/utils-lokalise';
import { useChangeLanguage } from '../../../language/index';
import { Drawer } from '../../Drawer/Drawer';
import { DrawerContent } from '../../../common/components/DrawerContent/DrawerContent';
import { LanguageChangeMenu } from '../../../language/LanguageChangeMenu/LanguageChangeMenu.use-client';
import { useEffect, useState } from 'react';
import { useDrawer } from '../../Drawer/DrawerContextProvider';

export interface MobileLanguageDrawerProps {
  translations: {
    errorTitle: string;
    errorSubtitle: string;
    germanSubtitle: string;
    englishSubtitle: string;
    title: string;
  };
}

const languageDrawerId = 'mobile-language-drawer';

const MobileLanguageDrawer = ({ translations }: MobileLanguageDrawerProps) => {
  const { closeDrawer } = useDrawer();

  const { onChangeLanguage, isUserUpdateLoading } = useChangeLanguage({
    translations: {
      errorSubtitle: translations.errorSubtitle,
      errorTitle: translations.errorTitle,
    },
  });

  const defaultSelectedLanguage = i18n.resolvedLanguage as Language;

  const [currentLanguage, setCurrentLanguage] = useState<Language>(
    defaultSelectedLanguage,
  );

  const onLanguageValueChange = (languageValue: string) => {
    setCurrentLanguage(languageValue as Language);

    closeDrawer(languageDrawerId);
  };

  useEffect(() => {
    if (currentLanguage !== defaultSelectedLanguage) {
      onChangeLanguage({
        languageValue: currentLanguage,
        onError: () => {
          setCurrentLanguage(defaultSelectedLanguage);
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLanguage]);

  return (
    <Drawer
      position="bottom"
      id={languageDrawerId}
      buttonTriggerNode={
        <button
          data-testid="mobile-language-button"
          className={css({
            cursor: 'pointer',
          })}
          disabled={isUserUpdateLoading}
        >
          {isUserUpdateLoading ? (
            <Icon
              aria-label="loading icon"
              data-element="loading-icon"
              theme="filled"
              size="20"
              icon="spinner-ios"
            />
          ) : (
            <Icon icon="globe" theme="regular" />
          )}
        </button>
      }
      contentNode={
        <div className={css({ minHeight: 408 })}>
          <DrawerContent
            title={translations.title}
            ariaLabel="language drawer content"
          >
            <LanguageChangeMenu
              translations={translations}
              onLanguageValueChange={onLanguageValueChange}
              currentValue={currentLanguage}
            />
          </DrawerContent>
        </div>
      }
    />
  );
};

export { MobileLanguageDrawer };
