'use client';

import { css } from '@bts-web/utils-style-engine';
import { AcceptOfferTerm } from '../AcceptOfferTerm.use-client';

interface AcceptOfferMetalsTermsProps {
  translations: {
    acceptMetalTerms: string;
    poweredByBpfs: string;
  };
  setTermsAgreed: () => void;
}

const AcceptOfferMetalsTerms = ({
  translations,
  setTermsAgreed,
}: AcceptOfferMetalsTermsProps) => {
  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        gap: 'small',
        alignItems: 'flex-start',
      })}
      data-testid="buy-terms-metals"
      aria-label="buy terms metals"
    >
      <AcceptOfferTerm
        onToggleCallback={setTermsAgreed}
        label={translations.acceptMetalTerms}
      />
    </div>
  );
};

export { AcceptOfferMetalsTerms };
