'use client';

import { useEffect } from 'react';

export const useClickAwayListener = (
  ref: React.RefObject<HTMLElement>,
  onClickAway: (event: MouseEvent | TouchEvent) => void,
) => {
  useEffect(() => {
    const handleClickAway = (event: MouseEvent | TouchEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onClickAway(event);
      }
    };

    document.addEventListener('mousedown', handleClickAway);

    document.addEventListener('touchstart', handleClickAway);

    return () => {
      document.removeEventListener('mousedown', handleClickAway);

      document.removeEventListener('touchstart', handleClickAway);
    };
  }, [ref, onClickAway]);
};
