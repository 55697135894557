'use client';

import { useRouter } from 'next/navigation';
import {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
  ReactNode,
} from 'react';
import { css } from '@bts-web/utils-style-engine';
import { isDevelopmentEnv } from '@bts-web/utils-functions';
import { Icon } from '@bts-web/design-system/component/icon';
import {
  PrecisionFloat,
  getPrecisionFloatValue,
} from '@bts-web/utils-formatting';
import { TradeInputWrapper } from '../../Reusable/TradeInputWrapper/TradeInputWrapper';
import { isBpfsAsset } from '../../utils/various';
import {
  AssetType,
  submitButtonWrapperAndButtonProperStyles,
} from '../../utils';
import { OfferLoaderElement } from '../../Reusable/OfferLoaderElement/OfferLoaderElement';
import { PoweredByText } from '../../Reusable/PoweredByText/PoweredByText';
import { TradeTaxSummary } from '../../Reusable/TradeTaxSummary/TradeTaxSummary';
import { AcceptBuyTradeDisclaimerWrapper } from './subcomponents/AcceptBuyTradeDisclaimerWrapper/AcceptBuyTradeDisclaimerWrapper';
import { finishTradeRedirectBuilder } from '../../FinishTrade/actions/finishTradeRedirectBuilder.action';
import { TradeAssetInfoDisplay } from '../../Reusable/TradeAssetInfoDisplay/TradeAssetInfoDisplay';
import { TradeScreenTranslations } from '../../utils/getTradeTranslations';
import {
  AcceptTradeControllerVariants,
  TradeOfferDataNonNullable,
} from '../types';
import { ButtonBase } from '../../../common';
import { acceptTradeOffer, fetchOfferData } from '../../../common/gqlActions';
import { BelowInputInfoTable } from '../../Reusable/BelowInputInfoTable/BelowInputInfoTable';
import { getConvertedSummary } from '../../TradeParent/utils/getConvertedSummary';
import { getAppConfig } from '@bts-web/core-app-config';
import { removeInputValueFromLocalStorage } from '../../TradeParent/utils/localStorageInputValueUtils';
import { useAppNotification } from '../../../notifications/NotificationContextProvider';
import { getMaximumTradeAmount } from '../../TradeParent/utils';
import { AmountFor } from '@bts-web/data-layer/server';

export type AcceptTradeSubscriptionWrapperProps = {
  initialOfferData: TradeOfferDataNonNullable;
  offerId: string;
  translations: TradeScreenTranslations;
  children?: ReactNode;
  variant: AcceptTradeControllerVariants;
  currentLocale: string;
  currency: string;
};

const onTradeOfferRefetchedEvent = new Event('onTradeOfferRefetched');

export const AcceptTradeSubscriptionWrapper: FC<
  AcceptTradeSubscriptionWrapperProps
> = ({
  initialOfferData,
  offerId,
  translations,
  children,
  variant,
  currentLocale,
  currency,
}) => {
  const { setAppNotification } = useAppNotification();

  const router = useRouter();

  const [tradeOfferData, setTradeOfferData] =
    useState<TradeOfferDataNonNullable>(initialOfferData);

  const [nextExpiresAt, setNextExpiresAt] = useState(
    initialOfferData.expiresAt,
  );

  const [isTradeOfferViewQueryRefetching, setIsTradeOfferViewQueryRefetching] =
    useState(false);

  const [isOfferAccepted, setOfferAccepted] = useState(false);

  const offerAvailabilityInMs = useMemo(() => {
    if (!nextExpiresAt) {
      return undefined;
    }

    const nextExpiresAtInMs = new Date(nextExpiresAt).getTime();

    const currentTimeInMs = Date.now();

    const newValue = nextExpiresAtInMs - currentTimeInMs;

    return newValue > 0 ? newValue : 5000;
  }, [nextExpiresAt]);

  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);

  const refetchTradeOfferViewQuery = useCallback(async () => {
    if (
      isTradeOfferViewQueryRefetching ||
      !offerAvailabilityInMs ||
      isOfferAccepted
    ) {
      return;
    }

    setIsTradeOfferViewQueryRefetching(true);

    let tradeOffer = null;

    try {
      const response = await fetchOfferData(offerId);

      tradeOffer = response.data.tradeOffer;

      if (!tradeOffer) {
        console.warn('%cNo trade offer data found', 'color:red');

        if (!isDevelopmentEnv()) {
          router.push('/error/server');
        }
      } else {
        setTradeOfferData(tradeOffer);

        setIsTradeOfferViewQueryRefetching(false);

        setNextExpiresAt(tradeOffer.expiresAt);

        setAppNotification({
          title: translations.offerExpiredTitle,
          subtitle: translations.offerExpiredSubtitle,
          withClose: true,
          visual: 'info',
        });

        // dispatch custom event when a new offer is created
        // used for resetting the user inactivity timeout
        document.dispatchEvent(onTradeOfferRefetchedEvent);
      }
    } catch (e) {
      if (!isDevelopmentEnv()) {
        router.push('/error/server');
      }
    }
  }, [offerId, offerAvailabilityInMs, isOfferAccepted]); //eslint-disable-line

  useEffect(() => {
    // Schedule a refetch of the query when the offer expires
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
    }

    timeoutIdRef.current = setTimeout(
      refetchTradeOfferViewQuery,
      offerAvailabilityInMs,
    );

    // Clear the timeout when the component is unmounted or when the offer expires
    return () => {
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }
    };
  }, [offerAvailabilityInMs, refetchTradeOfferViewQuery]);

  /* *************************************** */
  /* *** Computed data here **************** */
  /* *************************************** */

  const { additionalAcceptInputTradeInfo } = getAppConfig();

  const { asset, totalAmount, taxAmount, fiatAmount, hasTaxOfflineWarning } =
    tradeOfferData;

  const {
    logoUrl,
    name,
    priceBuy,
    priceSell,
    symbol: initialSymbol,
    __typename,
  } = asset;

  const price = variant === 'buy' ? priceBuy : priceSell;

  const symbol =
    __typename === 'MetalAsset' ? translations.grams : initialSymbol;

  const defaultDisplayType: AmountFor = 'FIAT';

  const totalSum = getPrecisionFloatValue(totalAmount);

  const isBpf = isBpfsAsset(__typename as AssetType);

  const poweredByText = {
    value: isBpf ? translations.poweredByBpfs : translations.poweredByBitpanda,
    fontSize: isBpf ? 'caption.medium_medium' : 'caption.small',
  };

  const loading = isTradeOfferViewQueryRefetching;

  const shouldShowTaxSummary =
    taxAmount && Number(taxAmount?.value) > 0 && fiatAmount?.value;

  const triggerAcceptTradeOffer = async () => {
    setIsTradeOfferViewQueryRefetching(true);

    setOfferAccepted(true);

    const responseStatus = await acceptTradeOffer(offerId, variant);

    removeInputValueFromLocalStorage();

    finishTradeRedirectBuilder(
      variant,
      offerId,
      responseStatus,
      !!hasTaxOfflineWarning,
    );
  };

  const maximumTradeAmount = getMaximumTradeAmount({
    fiatBalance: asset.portfolio?.fiatBalance,
    assetBalance: asset.portfolio?.assetBalance,
    type: variant,
  });

  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        px: 'medium',
        height: '100%',
      })}
    >
      <TradeAssetInfoDisplay
        assetData={{
          logoUrl: logoUrl,
          name: name,
        }}
        currentLocale={currentLocale}
        price={price}
        symbol={symbol}
        loaderElement={
          <OfferLoaderElement duration={offerAvailabilityInMs ?? 0} size={12} />
        }
      />

      {children}

      <TradeInputWrapper
        inputContextData={{
          symbol: asset.symbol ?? '',
          currency,
          currentLocale: currentLocale,
          displayType: defaultDisplayType,
          value: totalSum,
          placeholder: undefined,
        }}
      />

      <div
        className={css({
          pt: 'extra_small',
          pb: 'medium',
          borderBottom: '1px solid',
          borderColor: 'neutrals.divider',
        })}
      >
        <BelowInputInfoTable
          items={[
            {
              title: translations['units'],
              value: getConvertedSummary({
                assetPrice: price,
                currency: currency,
                displayType: defaultDisplayType,
                locale: currentLocale,
                symbol: symbol,
                translations: {
                  inclSpread: translations.inclSpread,
                },
                value: totalSum,
                useRawSource: {
                  rawSourceDisplayType: 'ASSET',
                  rawSourceValue: tradeOfferData?.assetAmount as PrecisionFloat,
                },
                maximumTradeAmount,
                showInclSpread: true,
              }),
            },
            ...(additionalAcceptInputTradeInfo
              ? additionalAcceptInputTradeInfo.map((item) => ({
                  title:
                    translations[
                      item.titleKey as keyof TradeScreenTranslations
                    ],
                  value:
                    translations[
                      item.valueKey as keyof TradeScreenTranslations
                    ],
                }))
              : []),
          ]}
        />
      </div>

      <div
        className={css({
          display: 'flex',
          flex: 'auto',
          flexDirection: 'column',
          justifyContent: 'center',
          pb: 'medium',
          gap: 'extra_small',
          marginTop: 'auto',
        })}
      >
        {shouldShowTaxSummary ? (
          <TradeTaxSummary
            currency={currency}
            currentLocale={currentLocale}
            fiatAmount={fiatAmount}
            isTaxServiceOffline={!!hasTaxOfflineWarning}
            taxAmount={taxAmount}
            translations={translations}
          />
        ) : (
          // ensures that this is bound to the bottom of the page
          <div className={css({ mt: 'auto' })} />
        )}

        <AcceptBuyTradeDisclaimerWrapper
          translations={translations}
          variant={variant}
          legalUrl={asset.legalUrl ?? ''}
          assetType={asset.__typename as AssetType}
        >
          {({ areTermsAgreedTo, isDisclaimerLoading }) => {
            const isLoading = isDisclaimerLoading || loading;

            const isDisabled = !areTermsAgreedTo || isLoading;

            return (
              <>
                <PoweredByText {...poweredByText} />

                <div className={submitButtonWrapperAndButtonProperStyles}>
                  <ButtonBase
                    visual="accent"
                    size="large"
                    disabled={isDisabled}
                    onClick={triggerAcceptTradeOffer}
                    fullWidth
                  >
                    {variant === 'buy'
                      ? translations.buyNow
                      : translations.sellNow}
                    {isLoading && (
                      <Icon
                        data-element="loading-icon"
                        theme="filled"
                        size={'20'}
                        icon="spinner-ios"
                        aria-label="loading icon"
                      />
                    )}
                  </ButtonBase>
                </div>
              </>
            );
          }}
        </AcceptBuyTradeDisclaimerWrapper>
      </div>
    </div>
  );
};
