import { css, stack } from '@bts-web/utils-style-engine';

const containerStyles = stack({
  backgroundColor: 'brand.fill_secondary',
  height: '100vh',
  gap: 'medium',
  px: 'medium',
  py: 'extra_large',
});

const skeletonTitleStyles = stack({
  gap: 'extra_small_2',

  '& > div': {
    height: '32px',
  },

  '& > div:first-child': {
    maxWidth: '172px',
  },

  '& > div:last-child': {
    maxWidth: '221px',
  },
});

const skeletonDescriptionStyles = stack({
  gap: 'extra_small_2',

  '& > div': {
    height: '16px',
  },

  '& > div:first-child': {
    maxWidth: '139px',
  },

  '& > div:last-child': {
    maxWidth: '208px',
  },
});

const skeletonTextItemStyles = css({
  backgroundColor: 'neutrals.fill_primary',
  borderRadius: '20px',
});

const LoadingSkeletonScreen = () => (
  <div className={containerStyles} data-testid="loading-skeleton-container">
    <div className={skeletonTitleStyles} data-testid="loading-skeleton-title">
      <div className={skeletonTextItemStyles} />
      <div className={skeletonTextItemStyles} />
    </div>
    <div
      className={skeletonDescriptionStyles}
      data-testid="loading-skeleton-description"
    >
      <div className={skeletonTextItemStyles} />
      <div className={skeletonTextItemStyles} />
    </div>
  </div>
);

export { LoadingSkeletonScreen };
