'use client';

import { useState, useEffect } from 'react';

export const useIsBelowScreenWidth = (width: number): boolean => {
  const [isBelow, setIsBelow] = useState<boolean>(true);

  useEffect(() => {
    const checkScreenWidth = () => {
      setIsBelow(window.innerWidth < width);
    };

    // Initial check
    checkScreenWidth();

    // Add event listener
    window.addEventListener('resize', checkScreenWidth);

    // Cleanup event listener
    return () => {
      window.removeEventListener('resize', checkScreenWidth);
    };
  }, [width]);

  return isBelow;
};

export default useIsBelowScreenWidth;
