import { FC } from 'react';
import { css } from '@bts-web/utils-style-engine';
import { submitButtonWrapper } from '../../../utils';
import { PoweredByText } from '../../../Reusable/PoweredByText/PoweredByText';
import { TradeFormSubmitButton } from '../../MainTradeFormSubmitButton/MainTradeFormSubmitButton';
import { TradeFormProps } from '../types';

interface SubmitSectionProps {
  staticValues: TradeFormProps['staticValues'];
  loading: boolean;
  disabled: boolean;
}

export const SubmitSection: FC<SubmitSectionProps> = ({
  staticValues,
  loading,
  disabled,
}) => {
  return (
    <div className={css(submitButtonWrapper)}>
      <PoweredByText {...staticValues.translatedValues.poweredByText} />

      <TradeFormSubmitButton
        loading={loading}
        disabled={disabled}
        submitText={staticValues.translatedValues.confirmButtonText}
      />
    </div>
  );
};
