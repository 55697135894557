import { PropsWithChildren } from 'react';
import { TradeContext } from '../../TradeContext';

interface TradeContextProviderMock extends PropsWithChildren {
  setOnClickTradeActionsCallback?: (handlers: {
    buy: () => void;
    sell: () => void;
  }) => void;
}

const TradeContextProviderMock = ({
  children,
  setOnClickTradeActionsCallback,
}: TradeContextProviderMock) => {
  return (
    <TradeContext.Provider
      value={{
        isSellButtonVisible: true,
        setOnClickTradeActions: ({ buy, sell }) =>
          setOnClickTradeActionsCallback &&
          setOnClickTradeActionsCallback({ buy, sell }),
        setSellButtonDisablerKeys: jest.fn(),
      }}
    >
      {children}
    </TradeContext.Provider>
  );
};

export { TradeContextProviderMock };
