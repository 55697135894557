import { FC } from 'react';
import { css } from '@bts-web/utils-style-engine';

export type ProgressIndicatorWithStepsProps = {
  currentStep: number;
  totalSteps: number;
};

const ProgressIndicatorWithSteps: FC<ProgressIndicatorWithStepsProps> = ({
  currentStep,
  totalSteps,
}) => {
  return (
    <div
      className={css({
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        gap: 'extra_small_3',
      })}
      aria-label="Progress indicator"
    >
      {[...Array(totalSteps)].map((_, index) => {
        const isCompleted = index <= currentStep;

        return (
          <div
            key={index}
            data-testid="progress-step"
            aria-label={`progress step ${index} ${isCompleted ? 'completed' : 'not completed'}`}
            className={css({
              height: '4px',
              flex: `1`,
              backgroundColor: isCompleted ? '#000' : '#eee',
              borderRadius: '4px',
            })}
          />
        );
      })}
    </div>
  );
};

export { ProgressIndicatorWithSteps };
