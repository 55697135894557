import { FC } from 'react';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { css } from '@bts-web/utils-style-engine';

export interface CheckboxToggleProps {
  size?: 'small' | 'medium' | 'large';
  checked: boolean;
  onChange: () => void;
  disabled?: boolean;
}

export interface CheckboxToggleProps {
  size?: 'small' | 'medium' | 'large';
  checked: boolean;
  onChange: () => void;
  disabled?: boolean;
}

export const CheckboxToggle: FC<CheckboxToggleProps> = ({
  size = 'medium',
  checked,
  onChange,
  disabled,
}) => {
  return (
    <label
      aria-label="checkbox toggle"
      data-state={checked ? 'active' : 'inactive'}
      className={css({
        position: 'relative',
        display: 'inline-block',
        width: '51px',
        height: '31px',
        minWidth: '51px',
        minHeight: '31px',
        ...(checked
          ? {
              '& span': {
                backgroundColor: 'positive.fill_primary',
              },
              '& span:before': {
                transform: 'translateX(20px)',
              },
            }
          : disabled
            ? {
                '& span': {
                  backgroundColor: 'neutrals.fill_tertiary',
                },
              }
            : {
                '& span': {
                  backgroundColor: 'neutrals.fill_secondary',
                },
              }),
      })}
    >
      <input
        type="checkbox"
        onChange={() => {
          onChange();
        }}
        className={css({
          opacity: 0,
          width: 0,
          height: 0,
        })}
        checked={checked}
      />
      <span
        className={css({
          position: 'absolute',
          cursor: 'pointer',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          transition: '.4s',
          borderRadius: '34px',
          '&::before': {
            position: 'absolute',
            content: '""',
            height: '26px',
            width: '26px',
            left: '2px',
            bottom: '2px',
            backgroundColor: 'neutrals.card_fill_primary',
            transition: '.4s',
            borderRadius: '50%',
          },
        })}
      ></span>
    </label>
  );
};
